import React, {useEffect, useRef, useState} from "react";
import {Button, Tooltip} from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Alert, Color} from "@material-ui/lab";
import {UIDialog} from "../../../../ui/feedback/UIDialog";
import {AsyncAutocompleteSelectComponent, DropDownValue} from "../components/AsyncAutocompleteSelect";
import {useOktaAuth} from '../../../../okta-react';
import {strings} from '../../i18n/strings';
import {Compellable} from "../indexes/Indexes";
import {createCountryDaysOff, editCountryDaysOff} from "../repository/CountryDaysOffRepository";
import {EditMode, RecordData} from "../../presenters/pages/CountryDaysOffPage";
import DatePicker, {DateObject} from "react-multi-date-picker";
import Toolbar from "react-multi-date-picker/plugins/toolbar";
import gregorian from "react-date-object/calendars/gregorian";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            width: '550px'
        },
        tooltip: {
            marginLeft: '15px'
        },
        sliderRoot: {
            display: 'flex',
            alignItems: 'center',
            width: '550px',
            margin: '10px 0 10px 0'
        },
        sliderTooltip: {
            marginLeft: 'auto'
        },
        displayFlex: {
            display: 'flex',
            alignItems: 'center',
            width: '550px'
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 300,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    })
});

const wordingCountryDaysOffPage = strings.page.countryDayOff;

export type EditFormModalOptions = {
    title: string;
    open: boolean;
    applyLabel: string;
    cancelLabel: string;
    applyAndResetLabel?: string;
    onClose: () => void;
    onSuccess: (shouldReset: boolean) => Promise<void>;
};

type EditFormProps = {
    constraints: Compellable;
    record?: RecordData | undefined;
    modalOptions: EditFormModalOptions;
};

type ValidationFeedback = {
    [key in keyof Compellable]: boolean;
};

export const EditForm = function ({
                                      constraints,
                                      record,
                                      modalOptions
                                  }: EditFormProps) {

        const [isRecordChanged, setIsRecordChanged] = useState(false);

        const initImmediateValidationFeedback = (resetOnSubmitAndContinue: boolean) => {
            const result: any = {};
            tableFields.map((field) => (result[field] = !!record || (!constraints[field].required || !constraints[field].editable || constraints[field].type === 'boolean' || resetOnSubmitAndContinue)));
            return result as ValidationFeedback;
        };

        const initFieldChangeState = () => {
            const isPristine = true;
            const result: any = {};
            tableFields.map((field) => (result[field] = record?.record ? !isPristine : isPristine));
            return result as ValidationFeedback;
        };

        const tableFields = Object.keys(constraints).filter((key) => key !== 'id');
        const [isFieldPristine, setIsFieldPristine] = useState(initFieldChangeState());
        const classes = useStyles();
        const [isOk, setIsOk] = useState(initImmediateValidationFeedback(false));
        const $form = useRef<HTMLFormElement>(null);
        const [internalNotification, setInternalNotification] = useState<
            { severity: Color; message: string } | undefined
        >();
        const [countryCode, setCountryCode] = useState<DropDownValue | null>();
        const [daysOff, setDaysOff] = useState<DateObject | DateObject[] | null>(null);
        const {authState} = useOktaAuth();

        const loadDaysOff = (recordValue?: RecordData) => {
            var allDaysOff: Array<DateObject> = [];

            if (recordValue) {
                let splittedDate = String(recordValue?.record?.dayOff);
                let year = new Date().getFullYear();
                let month = Number(splittedDate.trim().split("/")[1]);
                let day = Number(splittedDate.trim().split("/")[0]);

                allDaysOff.push(new DateObject().setCalendar(gregorian).setFormat("DD/MM").setYear(year).setMonth(month).setDay(day));
            }
            setDaysOff(allDaysOff);
        };

        useEffect(() => {
            loadDaysOff(record);
        }, [record]);

        const resetStates = (resetOnSubmitAndContinue: boolean) => {
            if (resetOnSubmitAndContinue === false) {
                setIsOk(initImmediateValidationFeedback(!resetOnSubmitAndContinue));
                setIsFieldPristine(initFieldChangeState());
            }
            setInternalNotification(undefined);
        };

        const handleModalClose = () => {
            modalOptions.onClose();
            resetStates(false);
        }

        /**
         * Row edition
         */
        const handleEditFormSubmit = async (form: any) => {
            const feedbackWordings = record ?
                (record.type === EditMode.Edit ?
                    wordingCountryDaysOffPage.datatable.action.edit
                    : wordingCountryDaysOffPage.datatable.action.duplicate)
                : wordingCountryDaysOffPage.datatable.action.add;
            await (record
                ? (record.type === EditMode.Edit ? editCountryDaysOff(authState!, record.record.id, {countryDaysOff: [form]}) : createCountryDaysOff(authState!, {countryDaysOff: [form]}))
                : createCountryDaysOff(authState!, {countryDaysOff: [form]}));

            return feedbackWordings.confirmationFeedbackMessage;
        };

        const handleFormSubmit = (shouldReset: boolean) => {
            const form: any = {};
            let inputs = $form.current!.getElementsByTagName("input");

            for (let i = 0; i < inputs.length; i++) {
                form[inputs[i].id.replace("edit-", "")] = inputs[i].value;
            }
            delete form["disable-close-on-select"];

            if (record?.type === EditMode.Edit) {
                form["id"] = record?.record.id;
            } else {
                form["id"] = null;
            }

            setInternalNotification(undefined);

            (async () => {
                try {
                    const message = await handleEditFormSubmit(form);
                    await setInternalNotification({severity: 'success', message});
                    modalOptions.onSuccess(shouldReset);
                    setInternalNotification(undefined);
                } catch (error: any) {
                    const errorMessage: string = error.message;
                    setInternalNotification({severity: 'error', message: errorMessage});
                }
            })();
        };

        const handleImmediateValidation = (
            field: keyof Compellable,
            e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>
        ) => {
            const isRequired = constraints[field].required;
            const isEditable = constraints[field].editable;
            const type = constraints[field].type;
            const value = e.target.value.trim();

            switch (field) {
                case "countryCode":
                    setIsOk((prev) => ({
                        ...prev,
                        [field]: fieldValidation(type, isRequired, isEditable, countryCode),
                    }));
                    break;
                default:
                    setIsOk((prev) => ({
                        ...prev,
                        [field]: (isRequired && isEditable) ? '' !== value && null !== value && undefined !== value : true,
                    }));
            }

            setIsFieldPristine((prev: any) => ({
                ...prev,
                [field]: false,
            }));
            setIsRecordChanged(true);
            setInternalNotification(undefined);
        };
        const handleImmediateValidationDatePicker = (
            field: keyof Compellable,
            dropdownValue?: DropDownValue | null
        ) => {
            const isRequired = constraints[field].required;
            const isEditable = constraints[field].editable;
            const type = constraints[field].type;

            let value = Array.isArray(daysOff) ? daysOff.join(',') : daysOff;
            setIsOk((prev) => ({
                ...prev,
                [field]: fieldValidation(type, isRequired, isEditable, value),
            }));

            setIsFieldPristine((prev: any) => ({
                ...prev,
                [field]: false,
            }));
            setIsRecordChanged(true);
            setInternalNotification(undefined);
            setDaysOff(daysOff)

        };
        const handleImmediateValidationDatePickerSingle = (
            field: keyof Compellable,
            dropdownValue?: DropDownValue | null
        ) => {
            const isRequired = constraints[field].required;
            const isEditable = constraints[field].editable;
            const type = constraints[field].type;

            setIsOk((prev) => ({
                ...prev,
                [field]: fieldValidation(type, isRequired, isEditable, daysOff),
            }));

            setIsFieldPristine((prev: any) => ({
                ...prev,
                [field]: false,
            }));
            setIsRecordChanged(true);
            setInternalNotification(undefined);
            setDaysOff(daysOff)
        };

        const fieldValidation = (type: String, isRequired: boolean, isEditable: boolean, value: any) => {
            switch (type) {
                case("boolean"):
                    return true;
                case("number"):
                    if (isNaN(parseInt(value))) {
                        return false;
                    }
                    return (isRequired && isEditable) ? '' !== value : true;

                default:
                    return (isRequired && isEditable) ? '' !== value : true;
            }
        };

        const cancelButtonOnClick = () => {
            modalOptions.onClose();
            setInternalNotification(undefined);
            resetStates(false);
            setCountryCode(null);
        }

        const getDefaultValue = (field: String | number) => {
            if (record) {
                let defaultValue = {
                    key: String(record.record[String(field)]),
                    value: String(record.record[String(field)])
                }
                return defaultValue;
            }
            return null;
        }

        const buildModalActions = () => {
            let isPristine = tableFields.some((field) => {
                return isFieldPristine[field] === true;
            })
            const isButtonDisabled = (isPristine || !isRecordChanged) || tableFields.some((field) => !isOk[field]);
            return [
                <Button key="modal-cancel" onClick={cancelButtonOnClick}>
                    {modalOptions.cancelLabel}
                </Button>,
                <Button
                    key="modal-validate"
                    onClick={handleFormSubmit.bind(null, false)}
                    variant="contained"
                    color="primary"
                    disabled={isButtonDisabled}
                    disableElevation
                >
                    {modalOptions.applyLabel}
                </Button>,
            ];
        };

        return (

            <UIDialog
                open={modalOptions.open}
                onClose={handleModalClose}
                title={modalOptions.title}
                actions={buildModalActions()}
            >
                {internalNotification && (
                    <Alert severity={internalNotification.severity}>{internalNotification.message}</Alert>
                )}
                <form ref={$form} autoComplete={`off`}>
                    {tableFields.map((field: keyof Compellable) => {
                            const isRequired = constraints[field].required;
                            const information = constraints[field].information;
                            const isEditable = constraints[field].editable;
                            const reference = constraints[field].reference;
                            const visible = constraints[field].visible
                            const displayName = constraints[field].displayName;
                            const type = constraints[field].type;

                            if (reference && visible && reference !== "") {
                                return (
                                    <div className={classes.displayFlex}>
                                        <AsyncAutocompleteSelectComponent
                                            id={`edit-${field}`}
                                            key={field}
                                            autocompleteContainerStyle={{
                                                'flexGrow': 1
                                            }}
                                            reference={reference}
                                            required={isRequired}
                                            information={information}
                                            onSelect={handleImmediateValidation.bind(null, field)}
                                            label={displayName}
                                            defaultValue={getDefaultValue(field)}
                                        />

                                        {information && (
                                            <Tooltip title={information} className={classes.tooltip}>
                                                <HelpIcon/>
                                            </Tooltip>
                                        )}
                                    </div>
                                );
                            } else if (isEditable && (type === "date") && (record?.type === EditMode.Edit)) {
                                return (
                                    <div className={classes.root} >
                                        <DatePicker
                                            placeholder={displayName}
                                            id={`edit-${field}`}
                                            sort={true}
                                            className="bg-dark"
                                            value={Array.isArray(daysOff) ? daysOff[0] : daysOff}
                                            onChange={(day: DateObject) => {
                                                setDaysOff([day]);
                                                handleImmediateValidationDatePickerSingle(field)
                                            }}
                                            containerStyle={{width: "100%"}}
                                            disableYearPicker={true}
                                            hideYear={true}
                                            format="DD/MM"
                                            style={{
                                                width: "100%",
                                                textAlign: "left",
                                                fontSize: "medium",
                                                padding: "6px 6px 6px 12px",
                                                boxSizing: "border-box",
                                                height: "40px",
                                                marginTop: "20px",
                                                direction: "rtl",
                                                color: "rgba(0, 0, 0, 0.87)"
                                            }}
                                            plugins={[
                                                <Toolbar position="bottom"
                                                         onClick={(e: any) => {
                                                             if (e.target.textContent === 'Close') {
                                                                 handleImmediateValidationDatePickerSingle(field)
                                                             }
                                                         }}/>

                                            ]}
                                        />
                                        {information && (
                                            <Tooltip title={information} className={classes.tooltip} style={{
                                                height: "26px",
                                                marginTop: "20px"
                                            }}>
                                                <HelpIcon/>
                                            </Tooltip>
                                        )}
                                    </div>
                                );
                            } else {
                                return (
                                    <div className={classes.root}>
                                        <DatePicker
                                            placeholder={displayName}
                                            id={`edit-${field}`}
                                            sort={true}
                                            className="bg-dark"
                                            value={daysOff}
                                            onChange={(day: DateObject[]) => {
                                                setDaysOff(day);
                                                handleImmediateValidationDatePickerSingle(field)
                                            }}
                                            containerStyle={{width: "100%"}}
                                            multiple={true}
                                            disableYearPicker={true}
                                            hideYear={true}
                                            format="DD/MM"
                                            style={{
                                                width: "100%",
                                                textAlign: "left",
                                                fontSize: "medium",
                                                padding: "6px 6px 6px 12px",
                                                boxSizing: "border-box",
                                                height: "40px",
                                                marginTop: "20px",
                                                direction: "rtl",
                                                color: "rgba(0, 0, 0, 0.87)"
                                            }}
                                        />
                                        {information && (
                                            <Tooltip title={information} className={classes.tooltip} style={{
                                                height: "26px",
                                                marginTop: "20px"
                                            }}>
                                                <HelpIcon/>
                                            </Tooltip>
                                        )}
                                    </div>
                                );
                            }
                        }
                    )}
                </form>
            </UIDialog>
        );
    }
;