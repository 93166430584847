import { ApiDatasource } from '../../adapters/io/datasources/ApiDatasource';
import { unwrap } from '../../adapters/io/network/HttpResponse';
import { formatText } from '../../adapters/io/network/utils';
import { ApiDatasourceSpec } from '../../core/datasource';
import { AuthState } from '@okta/okta-auth-js';
import { buildHeader } from './utils/Utils';
import { Order } from '../../../../graphics/tables/operations/sort';
import {HierarchyRegionTableStructure} from "../indexes/HierarchyRegionIndexes";

export const getHierarchyRegionRecords = <T>(
    authState: AuthState,
    page?: number,
    limit?: number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<HierarchyRegionTableStructure.ResponseOf<T>> => {

    const params = new Map();

    if (page) {
        params.set('page', page);
        params.set('limit', limit);
    }

    return datasource.remote
        .getData<HierarchyRegionTableStructure.ResponseOf<T>>(uriFormatter(EndpointHierarchyRegion.endPoint.concat(EndpointHierarchyRegion.getHierarchyRegionRecords), {}), buildHeader(authState), params)
        .then(unwrap);
};

export const EndpointHierarchyRegion = {
    endPoint: '/end-point',
    getHierarchyRegionRecords: '/hierarchy-region'
};