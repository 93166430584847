import { Order } from "../../../../graphics/tables/operations/sort";
import { ApiDatasource } from "../../adapters/io/datasources/ApiDatasource";
import { unwrap } from "../../adapters/io/network/HttpResponse";
import { formatText } from "../../adapters/io/network/utils";
import { ApiDatasourceSpec } from "../../core/datasource";
import { AuthState } from '@okta/okta-auth-js';
import { buildHeader } from './utils/Utils';
import {CCZoneTableStructure} from "../indexes/CCZoneIndexes";
import {TransporterDaysOffByCountryTableStructure} from "../indexes/TransporterDaysOffByCountryIndexes";
import {EndpointCCZone} from "./CCZoneRepository";

export const searchTransporterDaysOffByCountry = <T>(
    authState: AuthState,
    search: TransporterDaysOffByCountryTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<TransporterDaysOffByCountryTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<TransporterDaysOffByCountryTableStructure.ResponseOf<T>, TransporterDaysOffByCountryTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointTransporterDaysOffByCountry.endPoint.concat(EndpointTransporterDaysOffByCountry.searchTransporterDaysOffByCountry), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const getTransporterDaysOffByCountryColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<TransporterDaysOffByCountryTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<TransporterDaysOffByCountryTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointTransporterDaysOffByCountry.endPoint.concat(EndpointTransporterDaysOffByCountry.getTransporterDaysOffByCountryColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const createTransporterDaysOffByCountry = <RecordType>(
    authState: AuthState,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .postData<void, RecordType>(uriFormatter(EndpointTransporterDaysOffByCountry.endPoint.concat(EndpointTransporterDaysOffByCountry.createTransporterDaysOffByCountry), {}), record, buildHeader(authState), new Map())
        .then(() => {});
};

export const editTransporterDaysOffByCountry = <RecordType>(
    authState: AuthState,
    countryDayOffId: string,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .putData<void, RecordType>(uriFormatter(EndpointTransporterDaysOffByCountry.endPoint.concat(EndpointTransporterDaysOffByCountry.editTransporterDaysOffByCountry), {}) + `?id=${countryDayOffId}`, record, buildHeader(authState), new Map())
        .then(() => {});
};

export const deleteTransporterDaysOffByCountry = (
    authState: AuthState,
    id: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
) => datasource.remote.deleteData(uriFormatter(EndpointTransporterDaysOffByCountry.endPoint.concat(EndpointTransporterDaysOffByCountry.deleteTransporterDaysOffByCountry), {}) + `?id=${id}`, buildHeader(authState), new Map());


export const EndpointTransporterDaysOffByCountry = {
    endPoint: '/end-point/transporter-by-country',
    getTransporterDaysOffByCountryColumnInformation: '/column-information',
    searchTransporterDaysOffByCountry: '/search',
    createTransporterDaysOffByCountry: '',
    editTransporterDaysOffByCountry: '',
    deleteTransporterDaysOffByCountry: ''
};