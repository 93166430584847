import React, { useContext, useState, useEffect } from 'react';
import { strings } from '../../i18n/strings';
import { AppContext, AppContextSpec, useAsyncEffect} from '../../App';
import { ThresholdTableStructure } from '../../domain/indexes/ThresholdIndexes';
import {
    getSubActivityThresholdColumnInformation,
    createSubActivityThreshold,
    deleteThreshold,
    editSubActivityThreshold,
    searchThresholds
} from '../../domain/repository/ThresholdRepository';
import { DynamicDatatable, EditOptionType} from 'graphics/tables/DynamicDatatable';
import { HeadCell } from 'graphics/tables/data/HeadCell';
import { TableRowData } from 'graphics/tables/data/TableRowData';
import { EditForm as SubActivityThresholdEditForm, EditFormModalOptions} from "../../domain/forms/SubActivityThresholdEditForm";
import { Order } from 'graphics/tables/operations/sort';
import { useOktaAuth } from '../../../../okta-react';

// Datatable configuration
enum EditMode {
    Add,
    Edit,
    Duplicate
}
const DEFAULT_PAGINATION_LIMIT : number = (process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT) ? parseInt(process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT) : 20;

// Interfaces & types
type Record = ThresholdTableStructure.RecordOf<ThresholdTableStructure.Threshold>;
interface RecordRepresentation extends Record, TableRowData { };
interface RecordData { record: RecordRepresentation, type: EditMode }

const tableFields: Array<string> = [
    'id',
    'channelCode',
    'storeCode',
    'activityAndSubActivity',
    'gnc',
    'skuCode',
    'thresholdLevel',
    'thresholdValue'
];

// Wordings
const wordingsThresholdPage = strings.page.threshold;
const wordingsDatatable = strings.datatable;
const wordingThresholdAuditPage = strings.page.threshold.thresholdAudit;

export const SubActivityThresholdTable = () => {

    const appContext = useContext<AppContextSpec>(AppContext);
    const { authState } = useOktaAuth();

    const [rowData, setRowData] = useState<RecordRepresentation[]>([]);
    const [tableData, setTableData] = useState<ThresholdTableStructure.ResponseOf<ThresholdTableStructure.Threshold>>();
    const [headCellsConfig, setHeadCellsConfig] = useState<HeadCell<RecordRepresentation>[]>([]);
    const [tableDataDefinition, setTableDataDefinition] = useState<ThresholdTableStructure.ResponseOfColumn<ThresholdTableStructure.Threshold>>();

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(DEFAULT_PAGINATION_LIMIT);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>(tableFields[0]);

    const [editModalOpened, setEditModalOpened] = useState(false);
    const [recordToEdit, setRecordToEdit] = useState<RecordData>();

    const currentPage = wordingThresholdAuditPage.title;

    const headCells: HeadCell<RecordRepresentation>[] = tableFields.map((field) => ({
        id: field,
        label: field,
        displayName: field,
        type: "",
        required: true,
        information: "",
        position: 1,
        visible: true,
        editable: true,
        massEditable: false,
        reference: "",
        referenceColumnLabel: "",
        disablePadding: false,
        searchValue: "",
    }));

    //Get HeadCells to display tab names
    useEffect(() => {
        (async () => {
            try {
                const headCellConfigValues: ThresholdTableStructure.ResponseOfColumn<ThresholdTableStructure.Threshold> = await getSubActivityThresholdColumnInformation<ThresholdTableStructure.Threshold>(authState!);
                headCells.forEach((cell) => {
                    const config = headCellConfigValues[cell.id];
                    cell.id = config.name;
                    cell.label = config.name;
                    cell.type = config.type;
                    cell.required = config.required;
                    cell.information = config.information;
                    cell.position = config.position;
                    cell.visible = config.visible;
                    cell.editable = config.editable;
                    cell.massEditable = config.massEditable;
                    cell.displayName = (config.displayName.length > 0) ? String(config.displayName) : String(config.name);
                });
            setHeadCellsConfig(headCells);
            setTableDataDefinition(headCellConfigValues);
        } catch (error: any) {
            console.log("ERROR: ", error);
        }
        })();
        // eslint-disable-next-line
    }, []);

    const getAndRefreshRecords = (newList ?: HeadCell<RecordRepresentation>[]) => {

        appContext.displayLoader(true);

        let headCellsConfigForGetAndRefresh = (newList) ? newList : headCellsConfig;

        let formattedSearchValues: ThresholdTableStructure.FormattedSearchValue[] = headCellsConfigForGetAndRefresh
            .filter(cell => cell.label && cell.searchValue)
            .map(cell  => {
                return {
                    key: cell.label,
                    value : cell.searchValue
                };
            });

            // Search by default threshold by sku
            let defaultSkuSearchValue : ThresholdTableStructure.FormattedSearchValue = {key: "thresholdLevel", value: "SA"};
            formattedSearchValues.push(defaultSkuSearchValue);

            const updateState = (thresholdValues: ThresholdTableStructure.ResponseOf<ThresholdTableStructure.Threshold>) => {
                setTableData(thresholdValues);
                setRowData(thresholdValues.values);

                if (page !== thresholdValues.pagination.page) {
                    setPage(thresholdValues.pagination.page);
                }
            }

            (async () => {
                try {
                    const thresholdValues: ThresholdTableStructure.ResponseOf<ThresholdTableStructure.Threshold> =
                        await searchThresholds<Record>(authState!, formattedSearchValues, page, limit, order, orderBy);

                    if (page !== thresholdValues.pagination.page) {
                        setPage(thresholdValues.pagination.page);
                    }

                    updateState(thresholdValues);
                } catch (error: any) {
                    console.log("ERROR: ", error);
                }
            })();

        appContext.displayLoader(false)
    }

    useAsyncEffect(async () => {
        await getAndRefreshRecords();
        }, [page, limit, order, orderBy]
    );

    const handlePaginationChange = (page: number, limit: number) => {
        setPage(page);
        setLimit(limit);
    };

    const handleSortChange = async (order: Order, orderBy: string) => {
        setOrder(order);
        setOrderBy(orderBy);
    };

    const handleMultiCellsSearch = ( newList: HeadCell<RecordRepresentation>[]) => {
        setPage(1);
        headCellsConfig.forEach((cell : HeadCell<RecordRepresentation>) => {
            cell.searchValue = newList.filter(newListCell => newListCell.id === cell.id)[0].searchValue;
        });

        getAndRefreshRecords(headCellsConfig);
    }

    /**
     * Row edition
     */
    const handleEditFormSubmit = async (form: Record) => {
        const feedbackWordings = recordToEdit ? (recordToEdit.type === EditMode.Edit ? wordingsThresholdPage.datatable.action.edit : wordingsThresholdPage.datatable.action.duplicate) : wordingsThresholdPage.datatable.action.add;
        await (recordToEdit
            ? (recordToEdit.type === EditMode.Edit ? editSubActivityThreshold(authState!, recordToEdit.record.id, form) : createSubActivityThreshold(authState!, form))
            : createSubActivityThreshold(authState!, form));

        return feedbackWordings.confirmationFeedbackMessage;
    };

    const fillAndOpenEditForm = (record?: RecordData) => {
        setRecordToEdit(record);
        setEditModalOpened(true);
    };

    const computeAddOption = () => {
        return {
            buttonLabel: wordingsThresholdPage.datatable.action.add.buttonLabel,
            onClick: fillAndOpenEditForm,
        }
    };

    /**
     * Computed options
     */
    const handleEditOptionClick = (option: EditOptionType, record: RecordRepresentation) => {
        switch (option) {
            case EditOptionType.Edit:
                fillAndOpenEditForm({ record: record, type: EditMode.Edit });
                break;
            case EditOptionType.Duplicate:
                fillAndOpenEditForm({ record: record, type: EditMode.Duplicate });
                break;
            case EditOptionType.Delete:
                appContext.prompt(
                    wordingsThresholdPage.datatable.action.remove.confirmationPromptMessage,
                    (isConfirmed) => {
                        if (isConfirmed) {
                            appContext.displayLoader(true);
                            deleteThreshold(authState!, record.id)
                                .then(() => {
                                    setRowData((prev) => prev.filter((row) => row.id !== record.id));
                                    appContext.notify({ message: wordingsThresholdPage.datatable.action.remove.confirmedFeedbackMessage });
                                })
                                .catch(({ message }) => message && appContext.notifyError(message))
                                .finally(() => appContext.displayLoader(false));
                        }
                    },
                    { confirmButtonColor: 'secondary' }
                );
                break;
        }
    };

    const computeEditOptions = () => {
        return [
            { id: EditOptionType.Edit, name: wordingsThresholdPage.datatable.action.edit.menuItemLabel, onClick: handleEditOptionClick },
            {
                id: EditOptionType.Duplicate,
                name: wordingsThresholdPage.datatable.action.duplicate.menuItemLabel,
                onClick: handleEditOptionClick,
            },
            { id: EditOptionType.Delete, name: wordingsThresholdPage.datatable.action.remove.menuItemLabel, onClick: handleEditOptionClick },
        ];
    };

    /**
     * Modal row add
     */
    const handleEditModalClose = () => setEditModalOpened(false);

    const handleEditModalOk = (shouldReset: boolean) => {
        const feedbackWordings = recordToEdit ? (recordToEdit.type === EditMode.Edit ? wordingsThresholdPage.datatable.action.edit : wordingsThresholdPage.datatable.action.duplicate) : wordingsThresholdPage.datatable.action.add;
        !shouldReset && setEditModalOpened(false);

        (async () => {
            try {
                getAndRefreshRecords();
                appContext.notifySuccess(feedbackWordings.confirmationFeedbackMessage);
            } catch (error: any) {
                appContext.notifyError("ERROR : Something wrong happen while trying to edit / create Threshold")
            }
        })();
    };

    const computeEditModalOptions = (): EditFormModalOptions => {

        const modalWordings = recordToEdit
            ? (recordToEdit?.type === EditMode.Duplicate ? wordingsThresholdPage.datatable.action.duplicate.modal : wordingsThresholdPage.datatable.action.edit.modal)
            : wordingsThresholdPage.datatable.action.add.modal;

        return {
            title: modalWordings.title,
            applyLabel: modalWordings.applyButtonLabel,
            cancelLabel: modalWordings.cancelButtonLabel,
            applyAndResetLabel: recordToEdit ? (recordToEdit.type === EditMode.Duplicate ? wordingsThresholdPage.datatable.action.duplicate.modal.applyAndResetLabel : undefined) : wordingsThresholdPage.datatable.action.add.modal.applyAndResetLabel,
            open: editModalOpened,
            onClose: handleEditModalClose,
            onSuccess: async (shouldReset: boolean) => {
                await handleEditModalOk(shouldReset);
            }
        };
    };

    return (
        <div>
            <DynamicDatatable
                    stickyHeader={true}
                    headCells={headCellsConfig}
                    data={rowData}
                    initialOrderBy={tableFields[0]}
                    showPadding={false}
                    editOptions={computeEditOptions()}
                    allowAdding={computeAddOption()}
                    paginationOptions={(() => ({
                        page,
                        limit,
                        order,
                        orderBy,
                        total: tableData?.pagination?.total || 0,
                        rowsPerPageValues: [
                            { nb: DEFAULT_PAGINATION_LIMIT / 2, isDefault: true },
                            { nb: DEFAULT_PAGINATION_LIMIT, isDefault: true },
                            { nb: DEFAULT_PAGINATION_LIMIT * 2, isDefault: false },
                            { nb: DEFAULT_PAGINATION_LIMIT * 4, isDefault: false },
                        ],
                        meetNbRowsPerPage: false,
                        rowsPerPageLabel: wordingsDatatable.pagination.rowsPerPage,
                        onPaginationChange: handlePaginationChange,
                        onHandleSortChange: handleSortChange,
                        onMultiCellsSearch: handleMultiCellsSearch
                    }))()}
                    showHistory={[{
                        showHistoryButton: true,
                        urlToViewHistory: '/threshold-audit',
                        linkToParentPage: false,
                        icon: 'HistoryIcon'
                    }]}
                    pageTitles={[currentPage]}
                />
            {tableDataDefinition && (
                <SubActivityThresholdEditForm
                    key={recordToEdit ? recordToEdit.type : EditMode.Add}
                    record={recordToEdit ? recordToEdit.record : undefined}
                    constraints={tableDataDefinition}
                    onSubmit={handleEditFormSubmit}
                    modalOptions={computeEditModalOptions()}
                />
            )}
        </div>
    );
};


