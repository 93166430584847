import { ApiDatasource } from '../../adapters/io/datasources/ApiDatasource';
import { unwrap } from '../../adapters/io/network/HttpResponse';
import { formatText } from '../../adapters/io/network/utils';
import { ApiDatasourceSpec } from '../../core/datasource';
import { ThresholdTableStructure } from "../indexes/ThresholdIndexes";
import { AuthState } from '@okta/okta-auth-js';
import {buildHeader, buildTextHeader} from './utils/Utils';
import {SkuAvailabilityTableStructure} from "../indexes/SkuAvailabilityIndexes";
import {StockPoolStockSnapshotStructure} from "../indexes/StockPoolStockSnapshotIndexes";
import {StockOutsideStockpoolSnapshotStructure} from "../indexes/StockOutsideStockpoolSnapshotIndexes";
import {StockPoolReservationValuesSnapshotStructure} from "../indexes/StockPoolReservationValuesSnapshot";

export const searchSku = <T>(
    authState: AuthState,
    search: ThresholdTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<ThresholdTableStructure.ResponseOf<T>> => {

    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);

    return datasource.remote
        .postData<ThresholdTableStructure.ResponseOf<T>, ThresholdTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointSku.endPoint.concat(EndpointSku.searchSku), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const getSkuAvailability = <T>(
    authState: AuthState,
    channelCode: string,
    storeCode: string,
    sku: string,
    page?: number,
    limit?: number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<SkuAvailabilityTableStructure.ResponseOf<T>> => {

    const params = new Map();

    if (channelCode) {
        params.set('channelCode', channelCode);
    }
    if (storeCode) {
        params.set('storeCode', storeCode);
    }
    if (sku) {
        params.set('sku', sku);
    }

    if (page !== undefined) params.set('page', page);
    if (limit !== undefined) params.set('limit', limit);

    return datasource.remote
        .getData<SkuAvailabilityTableStructure.ResponseOf<T>>(
            uriFormatter(EndpointSku.endPoint.concat(EndpointSku.getSkuAvailability), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const getSkuAvailabilityColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<SkuAvailabilityTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<SkuAvailabilityTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointSku.endPoint.concat(EndpointSku.columnDefinition), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const getStockLastUpdateTime = (
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<string> => {
    const params = new Map();

    return datasource.remote
        .getDataText<string>(
            uriFormatter(EndpointSku.endPoint.concat(EndpointSku.lastUpdateTime), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const getStockPoolStockSnapshot = <T>(
    authState: AuthState,
    channelCode: string,
    storeCode: string,
    sku: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StockPoolStockSnapshotStructure.ResponseOf<T>> => {

    const params = new Map();

    if (channelCode) {
        params.set('channelCode', channelCode);
    }
    if (storeCode) {
        params.set('storeCode', storeCode);
    }
    if (sku) {
        params.set('sku', sku);
    }

    return datasource.remote
        .getData<StockPoolStockSnapshotStructure.ResponseOf<T>>(
            uriFormatter(EndpointSku.endPoint.concat(EndpointSku.getStockPoolStockSnapshot), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const getStockOutsideStockpoolSnapshot = <T>(
    authState: AuthState,
    channelCode: string,
    storeCode: string,
    sku: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StockPoolStockSnapshotStructure.ResponseOf<T>> => {

    const params = new Map();

    if (channelCode) {
        params.set('channelCode', channelCode);
    }
    if (storeCode) {
        params.set('storeCode', storeCode);
    }
    if (sku) {
        params.set('sku', sku);
    }

    return datasource.remote
        .getData<StockOutsideStockpoolSnapshotStructure.ResponseOf<T>>(
            uriFormatter(EndpointSku.endPoint.concat(EndpointSku.getStockOutsideStockpoolSnapshot), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const getStockPoolReservationValuesSnapshot = <T>(
    authState: AuthState,
    channelCode: string,
    storeCode: string,
    sku: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StockPoolReservationValuesSnapshotStructure.ResponseOf<T>> => {

    const params = new Map();

    if (channelCode) {
        params.set('channelCode', channelCode);
    }
    if (storeCode) {
        params.set('storeCode', storeCode);
    }
    if (sku) {
        params.set('sku', sku);
    }

    return datasource.remote
        .getData<StockPoolReservationValuesSnapshotStructure.ResponseOf<T>>(
            uriFormatter(EndpointSku.endPoint.concat(EndpointSku.getStockPoolReservationValuesSnapshot), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const EndpointSku = {
    endPoint: '/end-point',
    columnDefinition: '/sku-availability/column-information',
    lastUpdateTime: '/sku-availability/last-update-time',
    searchSku: '/sku',
    getSkuAvailability: '/sku-availability/',
    getStockPoolStockSnapshot: '/sku-availability/stock-pool-stock-snapshot',
    getStockOutsideStockpoolSnapshot: '/sku-availability/stock-outside-stockpool-snapshot',
    getStockPoolReservationValuesSnapshot: '/sku-availability/stock-pool-reservations-values-snapshot'
};