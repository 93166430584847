import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const ITEM_HEIGHT = 48;

export interface DatatableMenuOptions<T extends number> {
    id: T;
    name: string;
    onClick: (option: T, data: any) => void;
}

interface DatatableMenuButtonProps<T extends number> {
    options: DatatableMenuOptions<T>[];
    data: any;
}

/**
 * DatatableMenuButton
 * @param props 
 * - onClick: callback to specify the action to do (edit, copy or delete)
 * - data: type 'any'. Specify the data associated with the menu button.
 * - options: list of option to show in the menu

 */
export const DatatableMenuButton = <T extends number>(props: DatatableMenuButtonProps<T>) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const options = props.options || [];

    const handleClick = (e: any) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (event: any) => {
        setAnchorEl(null);
    };

    const handleItemClick = (action: T, e: any) => {
        e.stopPropagation();
        const actionCallback = options.find((option) => option.id === action);

        actionCallback!.onClick(action, props.data);
        setAnchorEl(null);
    };

    return (
        <aside>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="small"
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option.id} onClick={handleItemClick.bind(null, option.id)}>
                        {option.name}
                    </MenuItem>
                ))}
            </Menu>
        </aside>
    );
};
