import {ApiDatasource} from '../../adapters/io/datasources/ApiDatasource';
import {unwrap} from '../../adapters/io/network/HttpResponse';
import {formatText} from '../../adapters/io/network/utils';
import {ApiDatasourceSpec} from '../../core/datasource';
import {AuthState} from '@okta/okta-auth-js';
import {buildHeader} from './utils/Utils';
import {DefaultLeadTimeTableStructure} from "../indexes/DefaultLeadTimeIndexes";
import {Order} from "../../../../graphics/tables/operations/sort";

export const searchDefaultLeadTime = <T>(
    authState: AuthState,
    search: DefaultLeadTimeTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<DefaultLeadTimeTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches: search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<DefaultLeadTimeTableStructure.ResponseOf<T>, DefaultLeadTimeTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointDefaultLeadTime.endPoint.concat(EndpointDefaultLeadTime.searchDefaultLeadTime), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};
export const getDefaultLeadTimeColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<DefaultLeadTimeTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<DefaultLeadTimeTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointDefaultLeadTime.endPoint.concat(EndpointDefaultLeadTime.getDefaultLeadTimeColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const upsertDefaultLeadTime = <RecordType>(
    authState: AuthState,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .postData<void, RecordType>(uriFormatter(EndpointDefaultLeadTime.endPoint.concat(EndpointDefaultLeadTime.upsertDefaultLeadTime), {}), record, buildHeader(authState), new Map())
        .then(() => {});
};

export const deleteDefaultLeadTime = (
    authState: AuthState,
    id: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
) : Promise<void> => {

    return datasource.remote
        .deleteData(uriFormatter(EndpointDefaultLeadTime.endPoint.concat(EndpointDefaultLeadTime.deleteDefaultLeadTime), {}) + `?id=${id}`, buildHeader(authState), new Map())
        .then(() => {});
};

export const EndpointDefaultLeadTime = {
    endPoint: '/end-point/default-lead-time',
    getDefaultLeadTimes: '',
    searchDefaultLeadTime: '/search',
    getDefaultLeadTimeColumnInformation: '/column-information',
    upsertDefaultLeadTime: '/upsert',
    deleteDefaultLeadTime:''
};