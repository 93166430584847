import { ApiDatasource } from '../../adapters/io/datasources/ApiDatasource';
import { unwrap } from '../../adapters/io/network/HttpResponse';
import { formatText } from '../../adapters/io/network/utils';
import { ApiDatasourceSpec } from '../../core/datasource';
import { ThresholdTableStructure } from '../indexes/ThresholdIndexes';
import {Order} from "../../../../graphics/tables/operations/sort";
import { AuthState } from '@okta/okta-auth-js';
import { buildHeader } from './utils/Utils';

export const searchThresholds = <T>(
    authState: AuthState,
    search: ThresholdTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<ThresholdTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<ThresholdTableStructure.ResponseOf<T>, ThresholdTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointThreshold.endPoint.concat(EndpointThreshold.searchThresholds), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const deleteThreshold = (
    authState: AuthState,
    thresholdId: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
) => datasource.remote.deleteData(uriFormatter(EndpointThreshold.endPoint.concat(EndpointThreshold.deleteThreshold), {}) + `?thresholdId=${thresholdId}`, buildHeader(authState), new Map());


export const getSKUThresholdColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<ThresholdTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<ThresholdTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointThreshold.endPoint.concat(EndpointThreshold.getSKUThresholdColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};


export const createSKUThreshold = <RecordType>(
    authState: AuthState,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .postData<void, RecordType>(uriFormatter(EndpointThreshold.endPoint.concat(EndpointThreshold.createSKUThreshold), {}), record, buildHeader(authState), new Map())
        .then(() => {});
};

export const editSKUThreshold = <RecordType>(
    authState: AuthState,
    thresholdId: string,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> =>
    datasource.remote
        .putData<void, RecordType>(uriFormatter(EndpointThreshold.endPoint.concat(EndpointThreshold.editSKUThreshold), {}) + `?thresholdId=${thresholdId}`, record, buildHeader(authState), new Map())
        .then(() => {});


export const massEditSKUThreshold = <MassEditRequest>(
    authState: AuthState,
    massEditRequest: MassEditRequest,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> =>
    datasource.remote
        .putData<void, MassEditRequest>(uriFormatter(EndpointThreshold.endPoint.concat(EndpointThreshold.massEditSKUThreshold), {}), massEditRequest, buildHeader(authState), new Map())
        .then(() => {});


export const getGNCThresholdColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<ThresholdTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<ThresholdTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointThreshold.endPoint.concat(EndpointThreshold.getGNCThresholdColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const createGNCThreshold = <RecordType>(
    authState: AuthState,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .postData<void, RecordType>(uriFormatter(EndpointThreshold.endPoint.concat(EndpointThreshold.createGNCThreshold), {}), record, buildHeader(authState), new Map())
        .then(() => {});
};

export const editGNCThreshold = <RecordType>(
    authState: AuthState,
    thresholdId: string,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> =>
    datasource.remote
        .putData<void, RecordType>(uriFormatter(EndpointThreshold.endPoint.concat(EndpointThreshold.editGNCThreshold), {}) + `?thresholdId=${thresholdId}`, record, buildHeader(authState), new Map())
        .then(() => {});

export const getSubActivityThresholdColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<ThresholdTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<ThresholdTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointThreshold.endPoint.concat(EndpointThreshold.getSubActivityThresholdColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const createSubActivityThreshold = <RecordType>(
    authState: AuthState,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .postData<void, RecordType>(uriFormatter(EndpointThreshold.endPoint.concat(EndpointThreshold.createSubActivityThreshold), {}), record, buildHeader(authState), new Map())
        .then(() => {});
};

export const editSubActivityThreshold = <RecordType>(
    authState: AuthState,
    thresholdId: string,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> =>
    datasource.remote
        .putData<void, RecordType>(uriFormatter(EndpointThreshold.endPoint.concat(EndpointThreshold.editSubActivityThreshold), {}) + `?thresholdId=${thresholdId}`, record, buildHeader(authState), new Map())
        .then(() => {});

export const getStockPoolThresholdColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<ThresholdTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<ThresholdTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointThreshold.endPoint.concat(EndpointThreshold.getStockPoolThresholdColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const createStockPoolThreshold = <RecordType>(
    authState: AuthState,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .postData<void, RecordType>(uriFormatter(EndpointThreshold.endPoint.concat(EndpointThreshold.createStockPoolThreshold), {}), record, buildHeader(authState), new Map())
        .then(() => {});
};

export const editStockPoolThreshold = <RecordType>(
    authState: AuthState,
    thresholdId: string,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> =>
    datasource.remote
        .putData<void, RecordType>(uriFormatter(EndpointThreshold.endPoint.concat(EndpointThreshold.editStockPoolThreshold), {}) + `?thresholdId=${thresholdId}`, record, buildHeader(authState), new Map())
        .then(() => {});


export const EndpointThreshold = {
    endPoint: '/end-point/threshold',
    searchThresholds: '',
    deleteThreshold: '',
    getSKUThresholdColumnInformation: '/sku/column-information',
    createSKUThreshold:'/sku',
    editSKUThreshold:'/sku',
    massEditSKUThreshold:'/sku/mass-edit',
    getGNCThresholdColumnInformation: '/gnc/column-information',
    createGNCThreshold:'/gnc',
    editGNCThreshold:'/gnc',
    getSubActivityThresholdColumnInformation: '/sub-activity/column-information',
    createSubActivityThreshold:'/sub-activity',
    editSubActivityThreshold:'/sub-activity',
    getStockPoolThresholdColumnInformation: '/stock-pool/column-information',
    createStockPoolThreshold:'/stock-pool',
    editStockPoolThreshold:'/stock-pool'
};