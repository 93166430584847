import { Order } from "../../../../graphics/tables/operations/sort";
import { ApiDatasource } from "../../adapters/io/datasources/ApiDatasource";
import {HttpResponse, unwrap} from "../../adapters/io/network/HttpResponse";
import { formatText } from "../../adapters/io/network/utils";
import { ApiDatasourceSpec } from "../../core/datasource";
import { AuthState } from '@okta/okta-auth-js';
import {buildCsvHeader, buildFileHeader, buildHeader} from './utils/Utils';
import {ShipmentMatrixTableStructure} from "../indexes/ShipmentMatrixIndexes";

export const searchShipmentMatrix = <T>(
    authState: AuthState,
    search: ShipmentMatrixTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<ShipmentMatrixTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<ShipmentMatrixTableStructure.ResponseOf<T>, ShipmentMatrixTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointShipmentMatrix.endPoint.concat(EndpointShipmentMatrix.searchShipmentMatrix), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const getShipmentMatrixColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<ShipmentMatrixTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<ShipmentMatrixTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointShipmentMatrix.endPoint.concat(EndpointShipmentMatrix.getShipmentMatrixColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const exportDataToCSV = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<string> => {

    let promise = datasource.remote
        .getDataCSV(
            uriFormatter(EndpointShipmentMatrix.endPoint.concat(EndpointShipmentMatrix.exportDataToCSV), {}),
            buildCsvHeader(authState))
        .then(unwrap);
    return promise;
};

export const importDataFromCSV = (
    authState: AuthState,
    data?: FormData,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .postDataCSV(
            uriFormatter(EndpointShipmentMatrix.endPoint.concat(EndpointShipmentMatrix.importDataFromCSV), {}),
            buildFileHeader(authState),
            data)
        .then(() => {});
};

/*export const createShipmentMatrix = <RecordType>(
    authState: AuthState,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .postData<void, RecordType>(uriFormatter(EndpointShipmentMatrix.endPoint.concat(EndpointShipmentMatrix.createShipmentMatrix), {}), record, buildHeader(authState), new Map())
        .then(() => {});
};

export const editShipmentMatrix = <RecordType>(
    authState: AuthState,
    countryDayOffId: string,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .putData<void, RecordType>(uriFormatter(EndpointShipmentMatrix.endPoint.concat(EndpointShipmentMatrix.editShipmentMatrix), {}) + `?id=${countryDayOffId}`, record, buildHeader(authState), new Map())
        .then(() => {});
};

export const deleteShipmentMatrix = (
    authState: AuthState,
    id: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
) => datasource.remote.deleteData(uriFormatter(EndpointShipmentMatrix.endPoint.concat(EndpointShipmentMatrix.deleteShipmentMatrix), {}) + `?id=${id}`, buildHeader(authState), new Map());
*/

export const EndpointShipmentMatrix = {
    endPoint: '/end-point/shipment-matrix',
    getShipmentMatrixColumnInformation: '/column-information',
    searchShipmentMatrix: '/search',
    createShipmentMatrix: '',
    editShipmentMatrix: '',
    exportDataToCSV: '/export-csv',
    importDataFromCSV: '/import-csv',
    deleteShipmentMatrix: ''
};