import { Button, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Color } from '@material-ui/lab';
import React, { useRef, useState } from 'react';
import { UIDialog } from '../../../../ui/feedback/UIDialog';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AsyncAutocompleteSelectComponent, DropDownValue } from '../components/AsyncAutocompleteSelect';
import {SubzoneTableStructure} from "../indexes/SubzoneLeadTimeIndexes";
import {Compellable} from "../indexes/Indexes";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            width: '550px'
        },
        tooltip: {
            marginLeft: '15px'
        },
        sliderRoot: {
            display: 'flex',
            alignItems: 'center',
            width: '550px',
            margin: '10px 0 10px 0'
        },
        sliderTooltip: {
            marginLeft: 'auto'
        },
        displayFlex: {
            display: 'flex',
            alignItems: 'center',
            width: '550px'
        }
    })
});

export type EditFormModalOptions = {
    title: string;
    open: boolean;
    applyLabel: string;
    cancelLabel: string;
    applyAndResetLabel?: string;
    onClose: () => void;
    onSuccess: (shouldReset: boolean) => Promise<void>;
};

type EditFormProps<T> = {
    constraints: Compellable;
    record?: SubzoneTableStructure.RecordOf<Compellable>;
    modalOptions: EditFormModalOptions;
    onSubmit: (form: T) => Promise<string>;
};

type ValidationFeedback = {
    [key in keyof Compellable]: boolean;
};

export const EditForm = function <RecordType>({
    constraints,
    record,
    onSubmit,
    modalOptions,
}: EditFormProps<RecordType>) {

    const initImmediateValidationFeedback = (resetOnSubmitAndContinue: boolean) => {
        const result: any = {};
        tableFields.map((field) => (result[field] = !!record || (!constraints[field].required || !constraints[field].editable || constraints[field].type === 'boolean' || resetOnSubmitAndContinue)));
        return result as ValidationFeedback;
    };

    const initFieldChangeState = () => {
        const isPristine = true;
        const result: any = {};
        tableFields.map((field) => (result[field] = record ? !isPristine : isPristine));
        return result as ValidationFeedback;
    };

    const tableFields = Object.keys(constraints).filter((key) => key !== 'id');
    const classes = useStyles();

    const [isOk, setIsOk] = useState(initImmediateValidationFeedback(false));
    const $form = useRef<HTMLFormElement>(null);
    const [isPristine, setIsPristine] = useState(true);
    const [isFieldPristine, setIsFieldPristine] = useState(initFieldChangeState());
    const [internalNotification, setInternalNotification] = useState<
        { severity: Color; message: string } | undefined
    >();

    const resetStates = (resetOnSubmitAndContinue: boolean) => {
        setIsPristine(true);
        setIsOk(initImmediateValidationFeedback(resetOnSubmitAndContinue));
        setIsFieldPristine(initFieldChangeState());
        setInternalNotification(undefined);
    };

    const handleModalClose = () => {
        modalOptions.onClose();
        resetStates(false);
    };

    const handleFormSubmit = (shouldReset: boolean) => {
        const form: any = {};
        let inputs = $form.current!.getElementsByTagName("input");

        for (let i = 0; i < inputs.length; i++) {
            form[inputs[i].id.replace("edit-","")] = inputs[i].value;
        }

        setInternalNotification(undefined);

        onSubmit(form)
            .then(async (message) => {
                setInternalNotification({ severity: 'success', message });

                await modalOptions.onSuccess(shouldReset)
                
                if(shouldReset) {
                    resetStates(true);
                    setInternalNotification(undefined);
                } else {
                    resetStates(false);
                }
                
            })
            .catch(({ message }) => { 
                setInternalNotification({ severity: 'error', message })
            });
    };

    const handleImmediateValidation = (
        field: keyof Compellable,
        e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>
    ) => {
        const isRequired = constraints[field].required;
        const isEditable = constraints[field].editable;
        const type = constraints[field].type;
        const value = e.target.value.trim();

        setIsOk((prev) => ({
            ...prev,
            [field]: fieldValidation(type, isRequired, isEditable, value),
        }));

        setIsFieldPristine((prev) => ({
            ...prev,
            [field]: false,
        }));

        isPristine && setIsPristine(false);
        setInternalNotification(undefined);
    };

    const fieldValidation = (type: String, isRequired: boolean, isEditable: boolean, value: any) => {
        switch(type) {
            case("boolean"): return true;
            case("number"):
                if(isNaN(parseInt(value))) {
                    return false;
                }
                return (isRequired && isEditable) ? '' !== value : true;

            default: return (isRequired && isEditable) ? '' !== value : true;
        }  
    };


    const getDefaultValue = (field: string | number) => {
        if (record) {
            if (field === 'code') {
                return {
                    key: String(record?.[String('code')]),
                    value: String(record?.[String('code')])
                };
            }
            if (field === 'description') {
                return {
                    key: String(record?.[String('description')]),
                    value: String(record?.[String('description')])
                };
            }
            if (field === 'subzoneCode') {
                return {
                    key: String(record?.[String('subzoneCode')]),
                    value: String(record?.[String('subzoneCode')]) + ' - ' + String(record?.[String('subzoneDescription')])
                };
            }
            if (field === 'country') {
                return {
                    key: String(record?.[String('country')]),
                    value: String(record?.[String('country')])
                };
            }
            if (field === 'state') {
                return {
                    key: String(record?.[String('state')]),
                    value: String(record?.[String('state')])
                };
            }
        }
        return null;
    }

    const buildModalActions = () => {
        const isButtonDisabled = isPristine || tableFields.some((field) => !isOk[field]);
        return [
            <Button key="modal-cancel" onClick={handleModalClose}>
                {modalOptions.cancelLabel}
            </Button>,
            <Button
                key="modal-validate"
                onClick={handleFormSubmit.bind(null, false)}
                variant="contained"
                color="primary"
                disabled={isButtonDisabled}
                disableElevation
            >
                {modalOptions.applyLabel}
            </Button>,
        ];
    };

    return (
        
        <UIDialog
            open={modalOptions.open}
            onClose={handleModalClose}
            title={modalOptions.title}
            actions={buildModalActions()}
        >
            {internalNotification && (
                <Alert severity={internalNotification.severity}>{internalNotification.message}</Alert>
            )}
            <form ref={$form} autoComplete={`off`}>
                {tableFields.map((field: keyof Compellable) => {
                    const isRequired = constraints[field].required;
                    const information = constraints[field].information;
                    const isEditable = constraints[field].editable;
                    const reference = constraints[field].reference;
                    const displayName = constraints[field].displayName;

                    if(reference && reference !== "") {
                        return (
                            <div className={classes.displayFlex}>
                                <AsyncAutocompleteSelectComponent
                                    id={`edit-${field}`}
                                    key={field}
                                    autocompleteContainerStyle={{
                                        'flexGrow': 1
                                    }}
                                    reference={reference}
                                    required={isRequired}
                                    information={information}
                                    onSelect={handleImmediateValidation.bind(null, field)}
                                    label={displayName}
                                    defaultValue={getDefaultValue(field)}
                                />

                                {information && (
                                    <Tooltip title={information} className={classes.tooltip}>
                                        <HelpIcon />
                                    </Tooltip>
                                )}
                            </div>
                          );
                    }
                    else {
                        const inputProps = field === 'code' ? { maxLength: 5 } : {};
                        return (
                            ((isEditable || (field === 'code')) && (
                                <div className={classes.root}>
                                    <TextField
                                        key={field}
                                        required={isRequired}
                                        fullWidth
                                        error={!isFieldPristine[field] ? !isOk[field] : false}
                                        margin="normal"
                                        size="small"
                                        inputProps={inputProps}
                                        onInput={handleImmediateValidation.bind(null, field)}
                                        id={`edit-${field}`}
                                        label={displayName}
                                        variant="outlined"
                                        disabled={field === 'code' && modalOptions.title === 'Edit Subzone'}
                                        defaultValue={record ? record[field] : undefined}
                                    />

                                    {information && (
                                        <Tooltip title={information} className={classes.tooltip}>
                                            <HelpIcon />
                                        </Tooltip>
                                    )}
                                </div>
                            )));
                    }}
                )}
            </form>
        </UIDialog>
    );
};

