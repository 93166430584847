import { ApiDatasource } from '../../adapters/io/datasources/ApiDatasource';
import {ResponseOk, unwrap} from '../../adapters/io/network/HttpResponse';
import { formatText } from '../../adapters/io/network/utils';
import { ApiDatasourceSpec } from '../../core/datasource';
import { StockPoolTableStructure } from '../indexes/StockPoolIndexes';
import { Order } from "../../../../graphics/tables/operations/sort";
import { AuthState } from '@okta/okta-auth-js';
import {buildExcelHeader, buildHeader} from './utils/Utils';
import {EndpointStore} from "./StoreRepository";


export const getStockPool = <T>(
    authState: AuthState,
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StockPoolTableStructure.ResponseOf<T>> => {

    const params = new Map();

    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);


    return datasource.remote
        .getData<StockPoolTableStructure.ResponseOf<T>>(uriFormatter(EndpointStockPool.endPoint.concat(EndpointStockPool.getStockPool), {}),
            buildHeader(authState), params)
        .then(unwrap);
};


export const searchStockPool = <T>(
    authState: AuthState,
    search: StockPoolTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StockPoolTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<StockPoolTableStructure.ResponseOf<T>, StockPoolTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointStockPool.endPoint.concat(EndpointStockPool.searchStockPool), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const editStockPoolRecord = <RecordType>(
    authState: AuthState,
    stockPoolId: string,
    record: RecordType,
    setMessage?: (message: string) => void,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> =>
    datasource.remote
        .putData<void, RecordType>(uriFormatter(EndpointStockPool.endPoint.concat(EndpointStockPool.editStockPool), {}) + `?stockPoolId=${stockPoolId}`, record, buildHeader(authState), new Map())
        .then((response) => {if(setMessage) {// @ts-ignore
            setMessage(response?.data?.message)}});

export const massEditStockPool = <MassEditRequest>(
    authState: AuthState,
    massEditRequest: MassEditRequest,
    setMessage?: (message: string) => void,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> =>
    datasource.remote
        .putData<void, MassEditRequest>(uriFormatter(EndpointStockPool.endPoint.concat(EndpointStockPool.massEditStockPool), {}), massEditRequest, buildHeader(authState), new Map())
        .then((response) => {
            if(setMessage) {// @ts-ignore
                setMessage(response?.data?.response)
            }});

export const addStockPoolRecord = <RecordType>(
    authState: AuthState,
    record: RecordType,
    setMessage?: (message: string) => void,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .postData<void, RecordType>(uriFormatter(EndpointStockPool.endPoint.concat(EndpointStockPool.addStockPool), {}), record, buildHeader(authState), new Map())
        .then((response) => {if(setMessage) {// @ts-ignore
            setMessage(response?.data?.message)}});
};

export const removeStockPoolRecord = (
    authState: AuthState,
    stockPoolId: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
) => datasource.remote.deleteData(uriFormatter(EndpointStockPool.endPoint.concat(EndpointStockPool.removeStockPool), {}) + `?stockPoolId=${stockPoolId}`, buildHeader(authState), new Map());

export const getStockPoolColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StockPoolTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<StockPoolTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointStockPool.endPoint.concat(EndpointStockPool.getStockPoolColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const exportDataToExcel = (
    authState: AuthState,
    search: StockPoolTableStructure.FormattedSearchValue[],
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<ArrayBuffer | Error> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }

    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .getDataExcel(
            uriFormatter(EndpointStockPool.endPoint.concat(EndpointStockPool.exportDataToExcel), {}),
            body, buildExcelHeader(authState)
        )
        .then(response => {
            return response;
        });
};

export const EndpointStockPool = {
    endPoint: '/end-point',
    getStockPool: '/stock-pool',
    searchStockPool: '/stock-pool/search',
    editStockPool: '/stock-pool',
    massEditStockPool:'/stock-pool/mass-edit',
    addStockPool: '/stock-pool',
    removeStockPool : '/stock-pool',
    exportDataToExcel : '/stock-pool/export-excel',
    getStockPoolColumnInformation : '/stock-pool/column-information'
};