import React, {ChangeEvent, useEffect, useState} from 'react';

import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Autocomplete, AutocompleteInputChangeReason} from '@material-ui/lab';

import { ChannelTableStructure } from '../indexes/ChannelIndexes';
import { getChannelRecords } from '../repository/ChannelRepository';

import { StockLocationTableStructure } from '../indexes/StockLocationIndexes';
import {
    getStockLocation,
    searchStockLocationStartingWith
} from '../repository/StockLocationRepository';

import { StoreTableStructure} from '../indexes/StoreIndexes';
import { getStores } from '../repository/StoreRepository';

import { SubZoneTableStructure } from '../indexes/SubZoneIndexes';
import { getSubZoneRecords, getSubZoneCountryRecords, getSubZoneStateRecords } from '../repository/SubZoneRepository';

import { SkuTableStructure } from '../indexes/SkuIndexes';
import { searchSku } from "../repository/SkuRepository";

import { ActivityTableStructure } from "../indexes/ActivityIndexes";
import { getActivities } from "../repository/ActivityRepository";

import { SubActivityTableStructure } from "../indexes/SubActivityIndexes";
import { searchSubActivity } from "../repository/SubActivityRepository";

import { useOktaAuth } from '../../../../okta-react';

import { strings } from '../../i18n/strings'
import { getZoneRecords } from '../repository/ZoneRespository';
import { ZoneTableStructure } from '../indexes/ZoneIndexes';

import { getCountryRecords, getCountryByStoreRecords } from '../repository/CountryRespository';
import { CountryTableStructure } from '../indexes/CountryIndexes';
import {searchStockLocationChannelStoreRecords} from "../repository/StockLocationChannelStoreRepository";
import {StockLocationChannelStoreTableStructure} from "../indexes/StockLocationChannelStoreIndexes";
import {getHierarchyRegionRecords} from "../repository/HierarchyRegionRespository";
import {HierarchyRegionTableStructure} from "../indexes/HierarchyRegionIndexes";
import {TransporterTableStructure} from "../indexes/TransporterIndexes";
import {getTransporterRecords} from "../repository/TransporterRespository";

import {ShipmentMethodTableStructure} from "../indexes/ShipmentMethodIndexes";
import { getShipmentMethods } from '../repository/ShipmentMethodRepository';

type TableChannel = ChannelTableStructure.Channel;
type RecordChannel = ChannelTableStructure.RecordOf<TableChannel>;

type TableStockLocation = StockLocationTableStructure.StockLocation;
type RecordStockLocation = StockLocationTableStructure.RecordOf<TableStockLocation>;

type TableShipmentMethod = ShipmentMethodTableStructure.ShipmentMethod;
type RecordShipmentMethod = ShipmentMethodTableStructure.RecordOf<TableShipmentMethod>;

type TableStore = StoreTableStructure.Store;
type RecordStore = StoreTableStructure.RecordOf<TableStore>;

type TableSubZone = SubZoneTableStructure.SubZone;
type RecordSubZone = SubZoneTableStructure.RecordOf<TableSubZone>;

type TableActivity = ActivityTableStructure.Activity;
type RecordActivity = ActivityTableStructure.RecordOf<TableActivity>;

type TableSubActivity = SubActivityTableStructure.SubActivity;
type RecordSubActivity = SubActivityTableStructure.RecordOf<TableActivity>;

type TableSku = SkuTableStructure.Sku;
type RecordSku = SkuTableStructure.RecordOf<TableSku>;

type TableZone = ZoneTableStructure.Zone;
type RecordZone = ZoneTableStructure.RecordOf<TableZone>;

type TableCountry = CountryTableStructure.Country;
type RecordCountry = CountryTableStructure.RecordOf<TableCountry>;

type TableTransporter = TransporterTableStructure.Transporter;
type RecordTransporter = TransporterTableStructure.RecordOf<TableTransporter>;

type TableStockLocationChannelStore = StockLocationChannelStoreTableStructure.StockLocationChannelStore;
type RecordStockLocationChannelStore = StockLocationChannelStoreTableStructure.RecordOf<TableStockLocationChannelStore>;

type TableHierarchyRegion = HierarchyRegionTableStructure.HierarchyRegion;
type RecordHierarchyRegion = HierarchyRegionTableStructure.RecordOf<TableHierarchyRegion>;

export interface DropDownValue {
    key: string;
    value: string;
}

export const SupportedReference = {
    channel : 'channel',
    stockLocation: 'stockLocation',
    shipmentMethod: 'shipmentMethod',
    activity: 'activity',
    subActivity: 'subActivity',
    store: 'store',
    subZone: 'subZone',
    subZoneCountry: 'subZoneCountry',
    subZoneState: 'subZoneState',
    sku : 'sku',
    gnc : 'gnc',
    zoneDescription: 'zoneDescription',
    country: 'country',
    cfoCountries: 'cfoCountries',
    countryName: 'countryName',
    stockLocationTwoChars: 'stockLocationTwoChars',
    stockLocationTwoCharsFromStockLocationChannelStore: 'stockLocationTwoCharsFromStockLocationChannelStore',
    hierarchyRegion: 'hierarchyRegion',
    transporter: 'transporter'
};

export interface AsyncAutocompleteSelectOptions {
    id: string;
    key: string | number;
    reference: string;
    required: boolean;
    autocompleteContainerStyle?: Object;
    autocompleteDropDownStyle?: Object;
    information?: string;
    onSelect?: (e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => void;
    onParentChangeValue?: (parentId: string | number, parentValue : DropDownValue | null) => void;
    parentValue?: string;
    label: string | number;
    defaultValue?: DropDownValue | null;
    pristine?: boolean;
    error?: boolean;
    onChangeZone?: (event: ChangeEvent<{}>, value: DropDownValue | null) => void;
    onChange?: (value: DropDownValue | null) => void;
}

export const AsyncAutocompleteSelectComponent = ({
    id,
    key,
    reference,
    required,
    onSelect,
    label,
    autocompleteContainerStyle,
    autocompleteDropDownStyle,
    defaultValue,
    parentValue,
    onParentChangeValue,
    onChangeZone,
    onChange:skuAvailability
}: AsyncAutocompleteSelectOptions) => {

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<DropDownValue[]>([]);
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState<DropDownValue | null | undefined>(null);
    const [inputValue, setInputValue] = useState('');
    const { authState } = useOktaAuth();

    const setPlaceholder = ()=> {
        if(id === "edit-skuCode" || id === "edit-gnc" || id === "edit-transporterCode") {
            return strings.placeholder.dropdown
        } else if(id === "edit-countryId" || id === "edit-stockLocationCountry" || id === "edit-countryCode" || id === "edit-country") {
            return strings.placeholderOneChar.dropdown
        } else if (id === "edit-stockLocation" || id === "edit-defaultPickPackShip") {
            return strings.placeholderTwoChars.dropdown
        }
        else { return ""}
    };

    useEffect(()=>{
        setValue(defaultValue);
    },[]);

   useEffect(()=>{
       if(["edit-zoneDescription", "edit-sku-sku-availability", "edit-store-sku-availability", "edit-channel-sku-availability"].includes(id)){
           setValue(defaultValue);
       }
    },[id, defaultValue]);

    const placeholder = setPlaceholder();
    React.useEffect(() => {
        switch(reference){
            case SupportedReference.channel :
                (async () => {
                    const dropDownValues: DropDownValue[] = await getChannelRecords<TableChannel>(authState!, 1, 100).then((data) => {
                        
                        let responseValues: DropDownValue[] = data.values.map((channel : RecordChannel) => {
                            var rObj: DropDownValue = {
                                key: channel.code,
                                value:  channel.code + ' - ' + channel.description
                            };
                            return rObj;
                        })
            
                        return responseValues;
                    });
        
                    if (open) {
                        setOptions(dropDownValues as DropDownValue[]);
                    }

                })();
                break;

            case SupportedReference.stockLocation :         
                (async () => {
                    const dropDownValues: DropDownValue[] = await getStockLocation<TableStockLocation>(authState!, 1, 1000, 'desc', 'code').then((data) => {

                        let responseValues: DropDownValue[] = [];

                        data.values.forEach((stockLocation : RecordStockLocation) => {
                            var rObj: DropDownValue = {
                                key: String(stockLocation.code),
                                value: stockLocation.code + ' - ' + stockLocation.name
                            };
                            responseValues.push(rObj);
                        })
                        
                        return responseValues;
                    });
        
                    if (open) {
                        setOptions(dropDownValues as DropDownValue[]);
                    }

                })();
                break;

            case SupportedReference.shipmentMethod :
                (async () => {
                    const dropDownValues: DropDownValue[] = await getShipmentMethods<TableShipmentMethod>(authState!, 1, 1000, 'asc', 'code').then((data) => {

                        let responseValues: DropDownValue[] = [];

                        data.values.forEach((shipmentMethod : RecordShipmentMethod) => {
                            var rObj: DropDownValue = {
                                key: String(shipmentMethod.code),
                                value: shipmentMethod.code + ' - ' + shipmentMethod.description
                            };
                            responseValues.push(rObj);
                        })

                        return responseValues;
                    });

                    if (open) {
                        setOptions(dropDownValues as DropDownValue[]);
                    }

                })();
                break;

            case SupportedReference.store :         
                (async () => {
                    const dropDownValues: DropDownValue[] = await getStores<TableStore>(authState!, 1, 100, 'desc', 'code').then((data) => {
                        
                        let responseValues: DropDownValue[] = [];

                        data.values.forEach((store : RecordStore) => {
                            var rObj: DropDownValue = {
                                key: String(store.code),
                                value: store.code + " - " + store.description
                            };
                            responseValues.push(rObj);
                        })
                        
                        return responseValues;
                    });
        
                    if (open) {
                        setOptions(dropDownValues as DropDownValue[]);
                    }
                    
                })();
                break;

            case SupportedReference.subZone :

                (async () => {
                    const dropDownValues: DropDownValue[] = await getSubZoneRecords<TableSubZone>(authState!, 1, 100).then((data) => {
                        
                        let responseValues: DropDownValue[] = [];
                        data.values.forEach((subzone : RecordSubZone) => {
                            var rObj: DropDownValue = {
                                key: String(subzone.code),
                                value: subzone.code + " - " + subzone.description
                            };
                            responseValues.push(rObj);
                        })
                        
                        return responseValues;
                    });
        
                    if (open) {
                        setOptions(dropDownValues as DropDownValue[]);
                    }

                })();
                break;

            case SupportedReference.subZoneCountry :

                (async () => {
                    const dropDownValues: DropDownValue[] = await getSubZoneCountryRecords<TableSubZone>(authState!, 1, 100).then((data) => {

                        let responseValues: DropDownValue[] = [];
                        data.values.forEach((subzone : RecordSubZone) => {
                            var rObj: DropDownValue = {
                                key: String(subzone.code),
                                value: subzone.code + " - " + subzone.description
                            };
                            responseValues.push(rObj);
                        })

                        return responseValues;
                    });

                    if (open) {
                        setOptions(dropDownValues as DropDownValue[]);
                    }

                })();
                break;

            case SupportedReference.cfoCountries :
                (async () => {
                    const dropDownValues: DropDownValue[] = await getCountryByStoreRecords<TableCountry>(authState!, [], 1, 100).then((data) => {

                        let responseValues: DropDownValue[] = [];
                        data.values.forEach((row: RecordCountry) => {
                            let rObj: DropDownValue = {
                                key: String(row.code),
                                value: String(row.code)
                            };
                            responseValues.push(rObj);
                        })
                        return responseValues;
                    });

                    if (open) {
                        setOptions(dropDownValues as DropDownValue[]);
                    }
                })();
                break;

            case SupportedReference.subZoneState :

                (async () => {
                    const dropDownValues: DropDownValue[] = await getSubZoneStateRecords<TableSubZone>(authState!, 1, 100).then((data) => {

                        let responseValues: DropDownValue[] = [];
                        data.values.forEach((subzone : RecordSubZone) => {
                            var rObj: DropDownValue = {
                                key: String(subzone.code),
                                value: subzone.code + " - " + subzone.description
                            };
                            responseValues.push(rObj);
                        })

                        return responseValues;
                    });

                    if (open) {
                        setOptions(dropDownValues as DropDownValue[]);
                    }

                })();
                break;

            case SupportedReference.activity :

                (async () => {
                    const dropDownValues: DropDownValue[] = await getActivities<TableActivity>(authState!, 1, 100, 'desc', 'code').then((data) => {

                        let responseValues: DropDownValue[] = [];

                        data.values.forEach((activity : RecordActivity) => {
                            var rObj: DropDownValue = {
                                key: String(activity.code),
                                value: String(activity.code + " - " + activity.name)
                            };
                            responseValues.push(rObj);
                        })

                        return responseValues;
                    });

                    if (open) {
                        setOptions(dropDownValues as DropDownValue[]);
                    }

                })();
                break;

            case SupportedReference.subActivity :

                (async () => {
                    let formattedSearchValues = [{key: "activityCode", value: (parentValue ? parentValue : "1")}];
                    const dropDownValues: DropDownValue[] = await searchSubActivity<TableSubActivity>(authState!, formattedSearchValues, 1, 100, 'desc', 'code').then((data) => {

                        let responseValues: DropDownValue[] = [];

                        data.values.forEach((subActivity : RecordSubActivity) => {
                            var rObj: DropDownValue = {
                                key: String(subActivity.code),
                                value: String(subActivity.code + " - "  + subActivity.name)
                            };
                            responseValues.push(rObj);
                        })

                        return responseValues;
                    });

                    if (open) {
                        setOptions(dropDownValues as DropDownValue[]);
                    }

                })();
                break;

            case SupportedReference.zoneDescription :
                (async () => {
                    const dropDownValues: DropDownValue[] = await getZoneRecords<TableZone>(authState!, 1, 100).then((data) => {

                        let responseValues: DropDownValue[] = [];
                        data.values.forEach((zone : RecordZone) => {
                            if(zone.code && zone.description){
                                var rObj: DropDownValue = {
                                    key: String(zone.code),
                                    value: String(zone.description)
                                };
                                responseValues.push(rObj);
                            }
                        })

                        return responseValues;
                    });

                    if (open) {
                        setOptions(dropDownValues as DropDownValue[]);
                    }

                })();
                break;
            case SupportedReference.hierarchyRegion :
                (async () => {
                    const dropDownValues: DropDownValue[] = await getHierarchyRegionRecords<TableHierarchyRegion>(authState!, 1, 100).then((data) => {

                        let responseValues: DropDownValue[] = data.values.map((hierarchyRegion : RecordHierarchyRegion) => {
                            var rObj: DropDownValue = {
                                key: "hierarchyRegion",
                                value:  hierarchyRegion.hierarchyRegion
                            };
                            return rObj;
                        })

                        return responseValues;
                    });

                    if (open) {
                        setOptions(dropDownValues as DropDownValue[]);
                    }

                })();
                break;
            case SupportedReference.transporter :
                (async () => {
                    const dropDownValues: DropDownValue[] = await getTransporterRecords<TableTransporter>(authState!, 1, 100).then((data) => {

                        let responseValues: DropDownValue[] = data.values.map((transporter : RecordTransporter) => {
                            var rObj: DropDownValue = {
                                key: transporter.code,
                                value:  transporter.code
                            };
                            return rObj;
                        })

                        return responseValues;
                    });

                    if (open) {
                        setOptions(dropDownValues as DropDownValue[]);
                    }

                })();
                break;
    }}, [open, authState, parentValue, reference]);


    const onInputChange = (event: ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
        setInputValue(value);
        switch(reference){
            case SupportedReference.sku :
                (async () => {
                    try {
                        let dropDownValues: DropDownValue[] = [];

                        if(value.length >= 4) {
                            dropDownValues = await searchSku<TableSku>(authState!,
                                [
                                    {
                                        "key": "sku",
                                        "value": value
                                    }
                                ], 1, 20).then((data) => {

                                let responseValues: DropDownValue[] = [];
                                data.values.forEach((row: RecordSku) => {
                                    let rObj: DropDownValue = {
                                        key: String(row.id),
                                        value: String(row.sku)
                                    };
                                    responseValues.push(rObj);
                                })
                                
                                return responseValues;
                            });
                        }
                        if (open) {
                            setOptions(dropDownValues as DropDownValue[]);
                        }
                    } catch (error: any) {
                        console.log("ERROR Something went wrong while getting sku ids", error);
                    }
                })();
                break;

            case SupportedReference.gnc :
                (async () => {
                    try {
                        let dropDownValues: DropDownValue[] = [];

                        if(value.length >= 4) {
                            dropDownValues = await searchSku<TableSku>(authState!,
                                [
                                    {
                                        "key": "gnc",
                                        "value": value
                                    }
                                ], 1, 20).then((data) => {

                                let responseValues: DropDownValue[] = [];

                                data.values.forEach((row: RecordSku) => {
                                    let rObj: DropDownValue = {
                                        key: String(row.id),
                                        value: String(row.gnc)
                                    };
                                    responseValues.push(rObj);
                                })
                                return responseValues;
                            });
                        }
                        if (open) {
                            setOptions(dropDownValues as DropDownValue[]);
                        }
                    } catch (error: any) {
                        console.log("ERROR Something went wrong while getting gnc ids", error);
                    }
                })();
                break;

            case SupportedReference.country :
                (async () => {
                    try {
                        let dropDownValues: DropDownValue[] = [];

                        if(value.length >= 1) {
                            dropDownValues = await getCountryRecords<TableCountry>(authState!,
                                [
                                    {
                                        "key": "code",
                                        "value": value
                                    }
                                ], 1, 20, 'asc', 'code').then((data) => {

                                let responseValues: DropDownValue[] = [];

                                data.values.forEach((row: RecordCountry) => {
                                    let rObj: DropDownValue = {
                                        key: String(row.code),
                                        value: String(row.code)
                                    };
                                    responseValues.push(rObj);
                                })
                                return responseValues;
                            });
                            if (open) {
                                setOptions(dropDownValues as DropDownValue[]);
                            }
                        }
                    } catch (error: any) {
                        console.log("ERROR Something went wrong while getting country ids", error);
                    }
                })();
                break;

            case SupportedReference.countryName :
                (async () => {
                    try {
                        let dropDownValues: DropDownValue[] = [];

                        if(value.length >= 1) {
                            dropDownValues = await getCountryRecords<TableCountry>(authState!,
                                [
                                    {
                                        "key": "code",
                                        "value": value
                                    }
                                ], 1, 20, 'asc', 'code').then((data) => {

                                let responseValues: DropDownValue[] = [];

                                data.values.forEach((row: RecordCountry) => {
                                    let rObj: DropDownValue = {
                                        key: String(row.code),
                                        value: row.code + ' - ' + row.name
                                    };
                                    responseValues.push(rObj);
                                })
                                return responseValues;
                            });
                            if (open) {
                                setOptions(dropDownValues as DropDownValue[]);
                            }
                        }
                    } catch (error: any) {
                        console.log("ERROR Something went wrong while getting country ids", error);
                    }
                })();
                break;
            case SupportedReference.stockLocationTwoChars :
                (async () => {
                    try {
                        let dropDownValues: DropDownValue[] = [];

                        if(value.length >= 2) {
                            dropDownValues = await searchStockLocationStartingWith<TableStockLocation>(authState!,
                                [
                                    {
                                        "key": "code",
                                        "value": value
                                    }
                                ], 1, 20, 'asc', 'code').then((data) => {

                                let responseValues: DropDownValue[] = [];

                                data.values.forEach((row: RecordStockLocation) => {
                                    let rObj: DropDownValue = {
                                        key: String(row.code),
                                        value: String(row.code)
                                    };
                                    responseValues.push(rObj);
                                })
                                return responseValues;
                            });
                        }
                        if (open) {
                            setOptions(dropDownValues as DropDownValue[]);
                        }
                    } catch (error: any) {
                        console.log("ERROR Something went wrong while getting stock location ids", error);
                    }
                })();
                break;
            case SupportedReference.stockLocationTwoCharsFromStockLocationChannelStore :
                (async () => {
                    try {
                        let dropDownValues: DropDownValue[] = [];

                        if(value.length >= 2) {
                            dropDownValues = await searchStockLocationChannelStoreRecords<TableStockLocation>(authState!,
                                [
                                    {
                                        "key": "stockLocationCode",
                                        "value": value
                                    }
                                ], 1, 20, 'desc', 'stockLocation').then((data) => {

                                let responseValues: DropDownValue[] = [];

                                data.values.forEach((row: RecordStockLocation) => {
                                    let rObj: DropDownValue = {
                                        key: String(row.code),
                                        value: String(row.code)
                                    };
                                    responseValues.push(rObj);
                                })
                                return responseValues;
                            });
                        }
                        if (open) {
                            setOptions(dropDownValues as DropDownValue[]);
                        }
                    } catch (error: any) {
                        console.log("ERROR Something went wrong while getting stock location ids", error);
                    }
                })();
                break;
        }
    }

    const onChange = (event: ChangeEvent<{}>, newValue: DropDownValue | null) => {
        setValue(newValue);
        if(skuAvailability) skuAvailability(newValue);
        if(onParentChangeValue &&
            [
                'edit-activityCode',
                'edit-countryCode',
                'edit-transporterCode',
                'edit-allocationPointCode',
                'edit-destinatedStockAllocationCode',
                'edit-country',
                'edit-channel',
                'edit-store',
                'edit-shipmentMethod',
            ].includes(id)) {
            onParentChangeValue(id, newValue);
        }
    }

    const [firstCallModalOpen, setFirstCallModalOpen] = useState<boolean>(true);

    return (
        <div style={autocompleteContainerStyle}>
            <Autocomplete
                id={id}
                fullWidth
                size="small"
                style={autocompleteDropDownStyle}
                open={open}
                key={key}
                onOpen={() => {
                    setOpen(true);
                    if(id === "edit-skuCode" || id === "edit-gnc"
                        || id === "edit-countryId" || id === "edit-stockLocationCountry"
                        || id === "edit-clientCountry" || id === "edit-countryCode" || id === "edit-country" || id === "edit-stockLocation"
                        || id === "edit-defaultPickPackShipCode") {
                        setLoading(false);
                    }
                }}
                onInputChange={onInputChange}
                onChange={(event, value) => {
                    onChangeZone ? onChangeZone(event, value) : onChange(event, value);
                    if (onSelect) {
                        // Manually create a new event object with the value set
                        const newEvent = {
                            ...event,
                            target: {
                                ...event.target,
                                value: value ? value.value : ''
                            }
                        } as React.ChangeEvent<HTMLInputElement>;
                        onSelect(newEvent);
                    }
                }}
                value={value || null}
                inputValue={inputValue}
                onClose={() => {
                    setOpen(false);
                }}
                disabled={ id === "edit-subActivityCode" && parentValue === ""}
                getOptionSelected={(option, value) => option.key === value?.key}
                getOptionLabel={(option) => option.value}
                defaultValue={defaultValue}
                onSelect={onSelect}
                options={options}
                loading={loading}
                disableClearable={required}
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        key={key}
                        required={required}
                        margin="normal"
                        label={label}
                        variant="outlined"
                        placeholder={placeholder}
                        inputProps={{
                            ...params.inputProps,
                            endAdornment: (
                                <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </div>
    );
};
