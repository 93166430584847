import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import StoreIcon from '@material-ui/icons/Store';
import WidgetsIcon from '@material-ui/icons/Widgets';
import PublicIcon from '@material-ui/icons/Public';
import { Routes } from './AppRouter';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PersonIcon from '@material-ui/icons/Person';
import UIDynamicNavBar from 'ui/navigation/drawer/UINavBar';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { ProtectedAppRouter } from './AppRouter';
import ExposurePlus1Icon from '@material-ui/icons/ExposurePlus1';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import RedeemIcon from '@material-ui/icons/Redeem';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import {DateRange} from "@material-ui/icons";

type PushToHistory = (path: string) => void;

export const NavigationController = () => {
    
    const history = useHistory();
    const location = useLocation();
    const APP_BRAND_NAME = process.env.REACT_APP_BRAND_NAME ?? '';

    const drawerItems = (redirect: PushToHistory) => [
        [
            { id: 'stock-pool', label: 'Stock Pool', icon: <WidgetsIcon />, onSelect: () => redirect(Routes.stockPoolPage) },
            { id: 'stock-location', label: 'Stock Locations', icon: <StoreIcon />, onSelect: () => redirect(Routes.stockLocationPage) },
            { id: 'store', label: 'StockPool ID', icon: <PublicIcon />, onSelect: () => redirect(Routes.storePage) },
            { id: 'stock-location-ranking', label: 'Stock Location Ranking', icon: <FormatListNumberedIcon />, onSelect: () => redirect(Routes.stockLocationRankingPage) },
            { id: 'threshold', label: 'Threshold', icon: <ShowChartIcon />, onSelect: () => redirect(Routes.thresholdPage) },
            { id: 'quota', label: 'Quota', icon: <ExposurePlus1Icon />, onSelect: () => redirect(Routes.quotaPage) },
            { id: 'cc-zone', label: 'Click And Collect Zone', icon: <AddLocationIcon />, onSelect: () => redirect(Routes.cCZonePage) },
            { id: 'default-pps', label: 'Default Pick Pack Ship', icon: <RedeemIcon />, onSelect: () => redirect(Routes.defaultPpsPage) },
            { id: 'subzone', label: 'Subzone', icon: <DataUsageIcon />, onSelect: () => redirect(Routes.subzone) },
            {
                id: 'leadtime',
                label: 'Leadtime',
                icon: <DateRange/>,
                submenuItems: [
                    {
                        id: 'days-off-by-country',
                        label: 'Days Off by Country',
                        path: Routes.countryDaysOffPage,
                        onSelect: () => redirect(Routes.countryDaysOffPage)
                    },
                    {
                        id: 'transporter-by-country',
                        label: 'Transporter by Country',
                        path: Routes.transporterByCountryPage,
                        onSelect: () => redirect(Routes.transporterByCountryPage)
                    },
                    {
                        id: 'transporter-days-off-week',
                        label: 'Transporter days off week',
                        path: Routes.transporterDaysOffWeek,
                        onSelect: () => redirect(Routes.transporterDaysOffWeek)
                    },
                    {
                        id: 'transfer-delay',
                        label: 'Transfer Delay',
                        path: Routes.transferDelay,
                        onSelect: () => redirect(Routes.transferDelay)
                    },
                    {
                        id: 'shipment-matrix',
                        label: 'Shipment matrix',
                        path: Routes.shipmentMatrix,
                        onSelect: () => redirect(Routes.shipmentMatrix)
                    },
                    {
                        id: 'promise-date-margin',
                        label: 'Promise Date Margin',
                        path: Routes.promiseDateMargin,
                        onSelect: () => redirect(Routes.promiseDateMargin)
                    },
                    {
                        id: 'default-lead-time',
                        label: 'Default Lead Time',
                        path: Routes.defaultLeadTime,
                        onSelect: () => redirect(Routes.defaultLeadTime)
                    }
                ]
            },
        ],
        [
            { id: 'profile', label: 'Mon profil', icon: <PersonIcon />, onSelect: () => redirect('/profile') }
        ],
        [
            {
                id: 'help',
                label: 'Help',
                icon: <HelpOutlineIcon />,
                submenuItems: [
                    {
                        id: 'sku-availability',
                        label: 'SKU Availability',
                        path: Routes.skuAvailabilityPage,
                        onSelect: () => redirect(Routes.skuAvailabilityPage)
                    }
                ]
            }
        ]
    ];

    return (
        <UIDynamicNavBar
            headerTitle={APP_BRAND_NAME}
            menuItems={drawerItems(history.push)}
            content={<ProtectedAppRouter />}
            currentLocation={location.pathname}
        />
    );
};
