import { Order } from "../../../../graphics/tables/operations/sort";
import { ApiDatasource } from "../../adapters/io/datasources/ApiDatasource";
import { unwrap } from "../../adapters/io/network/HttpResponse";
import { formatText } from "../../adapters/io/network/utils";
import { ApiDatasourceSpec } from "../../core/datasource";
import { AuthState } from '@okta/okta-auth-js';
import { buildHeader } from './utils/Utils';
import {CCZoneTableStructure} from "../indexes/CCZoneIndexes";
import {CountryDayOffTableStructure} from "../indexes/CountryDayOffIndexes";
import {EndpointCCZone} from "./CCZoneRepository";

export const searchCountryDaysOff = <T>(
    authState: AuthState,
    search: CountryDayOffTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    isSorting?: boolean,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<CountryDayOffTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);
    isSorting !== undefined && params.set('isSorting', isSorting);

    return datasource.remote
        .postData<CountryDayOffTableStructure.ResponseOf<T>, CountryDayOffTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointCountryDaysOff.endPoint.concat(EndpointCountryDaysOff.searchCountryDaysOff), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const getCountryDaysOffColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<CountryDayOffTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<CountryDayOffTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointCountryDaysOff.endPoint.concat(EndpointCountryDaysOff.getCountryDaysOffColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const createCountryDaysOff = <RecordType>(
    authState: AuthState,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .postData<void, RecordType>(uriFormatter(EndpointCountryDaysOff.endPoint.concat(EndpointCountryDaysOff.createCountryDaysOff), {}), record, buildHeader(authState), new Map())
        .then(() => {});
};

export const editCountryDaysOff = <RecordType>(
    authState: AuthState,
    countryDayOffId: string,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .putData<void, RecordType>(uriFormatter(EndpointCountryDaysOff.endPoint.concat(EndpointCountryDaysOff.editCountryDaysOff), {}) + `?id=${countryDayOffId}`, record, buildHeader(authState), new Map())
        .then(() => {});
};

export const deleteCountryDaysOff = (
    authState: AuthState,
    id: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
) => datasource.remote.deleteData(uriFormatter(EndpointCountryDaysOff.endPoint.concat(EndpointCountryDaysOff.deleteCountryDaysOff), {}) + `?id=${id}`, buildHeader(authState), new Map());


export const EndpointCountryDaysOff = {
    endPoint: '/end-point/country-day-off',
    getCountryDaysOffColumnInformation: '/column-information',
    searchCountryDaysOff: '/search',
    createCountryDaysOff: '',
    editCountryDaysOff: '',
    deleteCountryDaysOff: ''
};