import {Button, TextField} from '@material-ui/core';
import {Alert, Color} from '@material-ui/lab';
import {StoreTableStructure} from '../indexes/StoreIndexes';
import React, {useEffect, useRef, useState} from 'react';
import {UIDialog} from 'ui/feedback/UIDialog';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import {Compellable} from "../indexes/Indexes";
import {DropDownValue} from "../components/AsyncAutocompleteSelect";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import {getCountryByStoreRecords} from "../repository/CountryRespository";
import {CountryTableStructure} from '../indexes/CountryIndexes';
import {useOktaAuth} from "okta-react";
import CancelIcon from '@material-ui/icons/Cancel';

type TableCountry = CountryTableStructure.Country;
type RecordCountry = CountryTableStructure.RecordOf<TableCountry>;

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            width: '550px'
        },
        tooltip: {
            marginLeft: '15px'
        },
        sliderRoot: {
            display: 'flex',
            alignItems: 'center',
            width: '550px',
            margin: '10px 0 10px 0'
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 500,
        },
        sliderTooltip: {
            marginLeft: 'auto'
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        displayFlex: {
            display: 'flex',
            alignItems: 'center',
            width: '550px'
        }
    })
});

export type EditFormModalOptions = {
    title: string;
    open: boolean;
    applyLabel: string;
    cancelLabel: string;
    applyAndResetLabel?: string;
    onClose: () => void;
    onSuccess: (shouldReset: boolean) => Promise<void>;
};

type EditFormProps<T> = {
    constraints: Compellable;
    record?: StoreTableStructure.RecordOf<Compellable>;
    modalOptions: EditFormModalOptions;
    onSubmit: (form: T) => Promise<string>;
};

type ValidationFeedback = {
    [key in keyof Compellable]: boolean;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


export const StoreEditForm = function <RecordType>({
                                                       constraints,
                                                       record,
                                                       onSubmit,
                                                       modalOptions,
                                                   }: EditFormProps<RecordType>) {

    const [isRecordChanged, setIsRecordChanged] = useState(false);
    const {authState} = useOktaAuth();

    const initImmediateValidationFeedback = (resetOnSubmitAndContinue: boolean) => {
        const result: any = {};
        tableFields.map((field) => (result[field] = !!record || (!constraints[field].required || !constraints[field].editable || constraints[field].type === 'boolean' || resetOnSubmitAndContinue)));
        return result as ValidationFeedback;
    };

    const initFieldChangeState = () => {
        const isPristine = true;
        const result: any = {};
        tableFields.map((field) => (result[field] = record ? !isPristine : isPristine));
        return result as ValidationFeedback;
    };

    const tableFields = Object.keys(constraints).filter((key) => key !== 'id');
    const classes = useStyles();
    const [isOk, setIsOk] = useState(initImmediateValidationFeedback(false));
    const $form = useRef<HTMLFormElement>(null);
    const [isPristine, setIsPristine] = useState(true);
    const [isFieldPristine, setIsFieldPristine] = useState(initFieldChangeState());
    const [internalNotification, setInternalNotification] = useState<
        { severity: Color; message: string } | undefined
    >();

    const [hierarchyRegion, setHierarchyRegion] = useState<DropDownValue | null>();
    const [cfoCountries, setCfoCountries] = useState<string[]>([]);
    const [options, setOptions] = useState<string[]>([]);

    const resetStates = (resetOnSubmitAndContinue: boolean) => {
        setIsPristine(true);
        setIsOk(initImmediateValidationFeedback(resetOnSubmitAndContinue));
        setIsFieldPristine(initFieldChangeState());
        setInternalNotification(undefined);
    };
    const loadCountries = (recordData?: StoreTableStructure.RecordOf<Compellable> | undefined) => {
        var countries: Array<string> = [];
        if (recordData) {
            countries = recordData.cfoCountries ? recordData.cfoCountries.toString().split(',') : []
        }
        setCfoCountries(countries);
    };
    useEffect(() => {
        loadCountries(record);
        (async () => {
            try {
                const dropDownValues: string[] = await getCountryByStoreRecords<TableCountry>(authState!, [], 1, 100).then((data) => {

                    let responseValues: string[] = [];
                    data.values.forEach((row: RecordCountry) => {
                        responseValues.push(row.code);
                    })
                    return responseValues;
                });
                setOptions(dropDownValues);
            } catch (error: any) {
                console.log("ERROR: ", error);
            }
        })();
        // eslint-disable-next-line
    }, [record]);

    const theme = useTheme();
    const getStyles = (name: string, personName: string[], theme: Theme) => {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    };

    const handleModalClose = () => {
        modalOptions.onClose();
        resetStates(false);
        setCfoCountries([])
    };

    const handleFormSubmit = (shouldReset: boolean) => {
        const form: any = {};
        let inputs = $form.current!.getElementsByTagName("input");

        for (let i = 0; i < inputs.length; i++) {
            form[inputs[i].id.replace("edit-", "")] = inputs[i].value;
        }

        setInternalNotification(undefined);

        onSubmit(form)
            .then(async (message) => {
                setInternalNotification({severity: 'success', message});

                await modalOptions.onSuccess(shouldReset)

                if (shouldReset) {
                    resetStates(true);
                } else {
                    resetStates(false);
                }

            })
            .catch(({message}) => {
                setInternalNotification({severity: 'error', message})
            })
            .finally(() => setCfoCountries([]));
        ;
    };

    const handleImmediateValidation = (
        field: keyof Compellable,
        e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>
    ) => {

        const isRequired = constraints[field].required;
        const isEditable = constraints[field].editable;
        const type = constraints[field].type;
        const value = e.target.value.trim();

        switch (field) {
            case "hierarchyRegion":
                setIsOk((prev) => ({
                    ...prev,
                    [field]: fieldValidation(type, isRequired, isEditable, hierarchyRegion),
                }));
                break;
            default:
                setIsOk((prev) => ({
                    ...prev,
                    [field]: (isRequired && isEditable) ? '' !== value && null !== value && undefined !== value : true,
                }));
        }

        setIsOk((prev) => ({
            ...prev,
            [field]: fieldValidation(type, isRequired, isEditable, value),
        }));

        setIsFieldPristine((prev) => ({
            ...prev,
            [field]: false,
        }));

        isPristine && setIsPristine(false);
        setInternalNotification(undefined);
    };

    const handleImmediateValidationCfoCountries = (
        field: keyof Compellable,
    ) => {
        const isRequired = constraints[field].required;
        const isEditable = constraints[field].editable;
        const type = constraints[field].type;

        setIsOk((prev) => ({
            ...prev,
            [field]: fieldValidation(type, isRequired, isEditable, cfoCountries.join(',')),
        }));

        setIsFieldPristine((prev: any) => ({
            ...prev,
            [field]: false,
        }));
        isPristine && setIsPristine(false);
        setInternalNotification(undefined);
    };

    const fieldValidation = (type: String, isRequired: boolean, isEditable: boolean, value: any) => {
        switch (type) {
            case("boolean"):
                return true;
            case("number"):
                if (isNaN(parseInt(value))) {
                    return false;
                }
                return (isRequired && isEditable) ? '' !== value : true;

            default:
                return (isRequired && isEditable) ? '' !== value : true;
        }
    };

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCfoCountries(event.target.value as string[]);
    };
    const handleDelete = (e: Event | undefined,
                          field: keyof Compellable,
                          valueToRemove: string) => {
        e?.stopPropagation();
        setCfoCountries((current) => current.filter((value) => value !== valueToRemove));
        handleImmediateValidationCfoCountries(field)
    };

    const buildModalActions = () => {
        const isButtonDisabled = isPristine || tableFields.some((field) => !isOk[field]);
        return [
            <Button key="modal-cancel" onClick={handleModalClose}>
                {modalOptions.cancelLabel}
            </Button>,
            modalOptions.applyAndResetLabel ? (
                <Button key="modal-apply-reset" onClick={handleFormSubmit.bind(null, true)} disabled={isButtonDisabled}>
                    {modalOptions.applyAndResetLabel}
                </Button>
            ) : null,
            <Button
                key="modal-validate"
                onClick={handleFormSubmit.bind(null, false)}
                variant="contained"
                color="primary"
                disabled={isButtonDisabled}
                disableElevation
            >
                {modalOptions.applyLabel}
            </Button>,
        ];
    };

    return (

        <UIDialog
            open={modalOptions.open}
            onClose={handleModalClose}
            title={modalOptions.title}
            actions={buildModalActions()}
        >
            {internalNotification && (
                <Alert severity={internalNotification.severity}>{internalNotification.message}</Alert>
            )}
            <form ref={$form} autoComplete={`off`}>
                {tableFields.map((field: keyof Compellable) => {
                        const isRequired = constraints[field].required;
                        const information = constraints[field].information;
                        const displayName = constraints[field].displayName;
                        const reference = constraints[field].reference;
                        const isEditable = constraints[field].editable;


                        if (reference && reference !== "") {
                            return (
                                (isEditable && (
                                    <div className={classes.root}>
                                        <FormControl className={classes.formControl} fullWidth>
                                            <InputLabel id={`label-edit-${field}`}>{displayName}</InputLabel>
                                            <Select
                                                labelId={`label-edit-${field}`}
                                                id={`edit-${field}`}
                                                multiple
                                                fullWidth
                                                value={cfoCountries}
                                                onChange={handleChange}
                                                onClose={(e) => handleImmediateValidationCfoCountries(field)}
                                                input={<Input id={`edit-${field}`} fullWidth/>}
                                                renderValue={(selected) => (
                                                    <div className={classes.chips}>
                                                        {(selected as string[]).map((value) => (
                                                            value &&
                                                            <Chip
                                                                key={value}
                                                                label={value}
                                                                className={classes.chip}
                                                                onDelete={(event) => handleDelete(event, field, value)}
                                                                deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}/>
                                                        ))}
                                                    </div>
                                                )}
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    },
                                                    transformOrigin: {
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    },
                                                    getContentAnchorEl: null,
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                            width: 250,
                                                        },
                                                    },
                                                }}
                                            >
                                                {options.map((option) => (
                                                    <MenuItem key={option} value={option}
                                                              style={getStyles(option, cfoCountries, theme)}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        {information && (
                                            <Tooltip title={information} className={classes.tooltip}>
                                                <HelpIcon/>
                                            </Tooltip>
                                        )}
                                    </div>
                                ))
                            );
                        } else {
                            return (
                                (isEditable && (
                                    <div className={classes.root}>
                                        <TextField
                                            key={field}
                                            required={isRequired}
                                            fullWidth
                                            error={!isFieldPristine[field] ? !isOk[field] : false}
                                            margin="normal"
                                            size="small"
                                            onInput={handleImmediateValidation.bind(null, field)}
                                            id={`edit-${field}`}
                                            label={displayName}
                                            variant="outlined"
                                            defaultValue={record ? record[field] : undefined}
                                        />
                                        {information && (
                                            <Tooltip title={information} className={classes.tooltip}>
                                                <HelpIcon/>
                                            </Tooltip>
                                        )}
                                    </div>
                                )));
                        }
                    }
                )}
            </form>
        </UIDialog>
    );
};
