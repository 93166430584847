import { Order } from "../../../../graphics/tables/operations/sort";
import { ApiDatasource } from "../../adapters/io/datasources/ApiDatasource";
import { unwrap } from "../../adapters/io/network/HttpResponse";
import { formatText } from "../../adapters/io/network/utils";
import { ApiDatasourceSpec } from "../../core/datasource";
import { AuthState } from '@okta/okta-auth-js';
import { buildHeader } from './utils/Utils';
import {QuotaAuditTableStructure} from "../indexes/QuotaAuditIndexes";

export const searchQuotaAudit = <T>(
    authState: AuthState,
    search: QuotaAuditTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<QuotaAuditTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<QuotaAuditTableStructure.ResponseOf<T>, QuotaAuditTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointQuotaAudit.endPoint.concat(EndpointQuotaAudit.searchQuotaAudit), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const getQuotaAuditColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<QuotaAuditTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<QuotaAuditTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointQuotaAudit.endPoint.concat(EndpointQuotaAudit.getQuotaAuditColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const EndpointQuotaAudit = {
    endPoint: '/end-point/quota',
    searchQuotaAudit: '/audit-quota/search',
    getQuotaAuditColumnInformation: '/audit-quota'
};