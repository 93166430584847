import { Order } from "../../../../graphics/tables/operations/sort";
import { ApiDatasource } from "../../adapters/io/datasources/ApiDatasource";
import { unwrap } from "../../adapters/io/network/HttpResponse";
import { formatText } from "../../adapters/io/network/utils";
import { ApiDatasourceSpec } from "../../core/datasource";
import { QuotaTableStructure } from "../indexes/QuotaIndexes";
import { AuthState } from '@okta/okta-auth-js';
import { buildHeader } from './utils/Utils';

export const searchQuotas = <T>(
    authState: AuthState,
    search: QuotaTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<QuotaTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<QuotaTableStructure.ResponseOf<T>, QuotaTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointQuota.endPoint.concat(EndpointQuota.searchQuotas), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const getQuotaColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<QuotaTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<QuotaTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointQuota.endPoint.concat(EndpointQuota.getQuotaColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const addQuotaRecord = <RecordType>(
    authState: AuthState,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .postData<void, RecordType>(uriFormatter(EndpointQuota.endPoint.concat(EndpointQuota.addQuotaRecord), {}), record, buildHeader(authState), new Map())
        .then(() => {});
};

export const editQuotaRecord = <RecordType>(
    authState: AuthState,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .putData<void, RecordType>(uriFormatter(EndpointQuota.endPoint.concat(EndpointQuota.editQuotaRecord), {}), record, buildHeader(authState), new Map())
        .then(() => {});
};

export const deleteQuota = (
    authState: AuthState,
    quotaId: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
) => datasource.remote.deleteData(uriFormatter(EndpointQuota.endPoint.concat(EndpointQuota.removeQuotaRecord), {}) + `?quotaId=${quotaId}`, buildHeader(authState), new Map());


export const search = <T>(
    authState: AuthState,
    search: QuotaTableStructure.FormattedSearchValue[],
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
    ): Promise<QuotaTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }

    return datasource.remote
        .postData<QuotaTableStructure.ResponseOf<T>, QuotaTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointQuota.endPoint.concat(EndpointQuota.search), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};


export const EndpointQuota = {
    endPoint: '/end-point/quota',
    searchQuotas: '/search',
    getQuotaColumnInformation: '/column-information',
    addQuotaRecord: '',
    editQuotaRecord: '',
    removeQuotaRecord: '',
    search: '/search-all'
};