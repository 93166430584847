import React, { useContext, useState, useEffect } from 'react';

import { strings } from '../../i18n/strings';
import { AppContext, AppContextSpec, useAsyncEffect} from '../../App';
import { DynamicDatatable, EditOptionType} from 'graphics/tables/DynamicDatatable';
import { Order } from "../../../../graphics/tables/operations/sort";
import { HeadCell } from 'graphics/tables/data/HeadCell';
import { TableRowData } from 'graphics/tables/data/TableRowData';
import { EditForm as SubzoneEditForm, EditFormModalOptions} from "../../domain/forms/SubzoneEditForm";
import { SubzoneTableStructure } from '../../domain/indexes/SubzoneCountryLeadTimeIndexes';
import {
    createSubzone,
    deleteSubzone,
    editSubzone,
    getSubzoneColumnInformation,
    searchSubzone
} from '../../domain/repository/SubzoneCountryLeadTimeRepository';
import { useOktaAuth } from '../../../../okta-react';

// Interfaces & types
type Record = SubzoneTableStructure.RecordOf<SubzoneTableStructure.Subzone>;
interface RecordRepresentation extends Record, TableRowData { };
interface RecordData { record: RecordRepresentation, type: EditMode }

// Datatable configuration
enum EditMode {
    Add,
    Edit,
}

const tableFields: Array<string> = [
    'id',
    'subzoneCode',
    'subzoneDescription',
    'country'
];

// Wordings
const wordingsSubzonePage = strings.page.subzone.datatable.country;
const wordingsDatatable = strings.datatable;


export const SubzoneCountryTable = () => {

    const DEFAULT_PAGINATION_LIMIT : number = (process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT) ? parseInt(process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT) : 20;
    const [headCellsConfig, setHeadCellsConfig] = useState<HeadCell<RecordRepresentation>[]>([]);
    const [rowData, setRowData] = useState<RecordRepresentation[]>([]);
    const [tableData, setTableData] = useState<SubzoneTableStructure.ResponseOf<SubzoneTableStructure.Subzone>>();
    const [tableDataDefinition, setTableDataDefinition] = useState<SubzoneTableStructure.ResponseOfColumn<SubzoneTableStructure.Subzone>>();
    const [limit, setLimit] = useState(DEFAULT_PAGINATION_LIMIT);
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>(tableFields[1]);
    const appContext = useContext<AppContextSpec>(AppContext);
    const [editModalOpened, setEditModalOpened] = useState(false);
    const [recordToEdit, setRecordToEdit] = useState<RecordData>();
    const { authState } = useOktaAuth();
    const [updateSignal, setUpdateSignal] = useState(false);

    const currentPage = wordingsSubzonePage.title;


    // Define configuration and try to override by algo configuration
    const headCells: HeadCell<RecordRepresentation>[] = tableFields.map((field) => ({
        id: field,
        label: field,
        displayName: field,
        type: "",
        required: true,
        information: "",
        position: 1,
        visible: true,
        editable: true,
        massEditable: false,
        reference: "",
        referenceColumnLabel: "",
        disablePadding: false,
        searchValue: "",
    }));

    useEffect(() => {
        (async () => {
            try {
                const headCellConfigValues: SubzoneTableStructure.ResponseOfColumn<SubzoneTableStructure.Subzone> = await getSubzoneColumnInformation<SubzoneTableStructure.Subzone>(authState!);
                headCells.forEach((cell) => {
                    const config = headCellConfigValues[cell.id];
                    cell.id = config.name;
                    cell.label = config.name;
                    cell.type = config.type;
                    cell.required = config.required;
                    cell.information = config.information;
                    cell.position = config.position;
                    cell.visible = config.visible;
                    cell.editable = config.editable;
                    cell.massEditable = config.massEditable;
                    cell.displayName = (config.displayName.length > 0) ? String(config.displayName) : String(config.name);
                });
            setHeadCellsConfig(headCells);
            setTableDataDefinition(headCellConfigValues);
        } catch (error: any) {
            console.log("ERROR: ", error);
        }
        })();
        // eslint-disable-next-line
    }, []);

    // Get filtered data logic
    const getAndRefreshRecords = (newList ?: HeadCell<RecordRepresentation>[]) => {

        appContext.displayLoader(true);

        let headCellsConfigForGetAndRefresh = (newList) ? newList : headCellsConfig;

        let formattedSearchValues: SubzoneTableStructure.FormattedSearchValue[] = headCellsConfigForGetAndRefresh
            .filter(cell => cell.label && cell.searchValue)
            .map(cell  => {
                return {
                    key: cell.label,
                    value : cell.searchValue
                };
            });

        const updateState = (subzoneValues: SubzoneTableStructure.ResponseOf<SubzoneTableStructure.Subzone>) => {
            setTableData(subzoneValues);
            setRowData(subzoneValues.values);

            if (page !== subzoneValues.pagination.page) {
                setPage(subzoneValues.pagination.page);
            }
        }

        (async () => {
            try {
                const subzoneValues: SubzoneTableStructure.ResponseOf<SubzoneTableStructure.Subzone> =
                    await searchSubzone<Record>(authState!, formattedSearchValues, page, limit, order, orderBy);

                if (page !== subzoneValues.pagination.page) {
                    setPage(subzoneValues.pagination.page);
                }

                updateState(subzoneValues);
            } catch (error: any) {
                console.log("ERROR: ", error);
            }
        })();

        appContext.displayLoader(false)
    }

    useAsyncEffect(async () => {
        await getAndRefreshRecords();
        }, [page, limit, order, orderBy, updateSignal]
    );

    // Datatable handle actions
    const handlePaginationChange = (page: number, limit: number) => {
        setPage(page);
        setLimit(limit);
    };

    const handleSortChange = async (order: Order, orderBy: string) => {
        setOrder(order);
        setOrderBy(orderBy);
    };

    const handleMultiCellsSearch = ( newList: HeadCell<RecordRepresentation>[]) => {
        setPage(1);
        headCellsConfig.forEach((cell : HeadCell<RecordRepresentation>) => {
            cell.searchValue = newList.filter(newListCell => newListCell.id === cell.id)[0].searchValue;
        });

        getAndRefreshRecords(headCellsConfig);
    }

    /**
     * Row edition
     */
    const handleEditFormSubmit = async (form: Record) => {
        const feedbackWordings = recordToEdit ? wordingsSubzonePage.action.edit : wordingsSubzonePage.action.add;
        await (recordToEdit
            ? (recordToEdit.type === EditMode.Edit ? editSubzone(authState!, recordToEdit.record.id, form) : createSubzone(authState!, form))
            : createSubzone(authState!, form));

        return feedbackWordings.confirmationFeedbackMessage;
    };

    const fillAndOpenEditForm = (record?: RecordData) => {
        setRecordToEdit(record);
        setEditModalOpened(true);
    };

    const computeAddOption = () => {
        return {
            buttonLabel: wordingsSubzonePage.action.add.buttonLabel,
            onClick: fillAndOpenEditForm,
        }
    };

    /**
     * Computed options
     */
    const handleEditOptionClick = (option: EditOptionType, record: RecordRepresentation) => {
        switch (option) {
            case EditOptionType.Edit:
                fillAndOpenEditForm({ record: record, type: EditMode.Edit });
                break;
            case EditOptionType.Delete:
                appContext.prompt(
                    wordingsSubzonePage.action.remove.confirmationPromptMessage,
                    (isConfirmed) => {
                        if (isConfirmed) {
                            appContext.displayLoader(true);
                            deleteSubzone(authState!, record.id)
                                .then(() => {
                                    setRowData((prev) => prev.filter((row) => row.id !== record.id));
                                    appContext.notify({ message: wordingsSubzonePage.action.remove.confirmedFeedbackMessage });
                                })
                                .catch(({ message }) => message && appContext.notifyError(message))
                                .finally(() => appContext.displayLoader(false));
                        }
                    },
                    { confirmButtonColor: 'secondary' }
                );
                break;
        }
    };

    const computeEditOptions = () => {
        return [
            { id: EditOptionType.Edit, name: wordingsSubzonePage.action.edit.menuItemLabel, onClick: handleEditOptionClick },
            { id: EditOptionType.Delete, name: wordingsSubzonePage.action.remove.menuItemLabel, onClick: handleEditOptionClick },
        ];
    };

    /**
     * Modal row add
     */
    const handleEditModalClose = () => setEditModalOpened(false);

    const handleEditModalOk = (shouldReset: boolean) => {
        const feedbackWordings = recordToEdit ? wordingsSubzonePage.action.edit : wordingsSubzonePage.action.add;
        !shouldReset && setEditModalOpened(false);

        (async () => {
            try {
                getAndRefreshRecords();
                appContext.notifySuccess(feedbackWordings.confirmationFeedbackMessage);
            } catch (error: any) {
                appContext.notifyError(error.message);
            }
        })();
    };

    const computeEditModalOptions = (): EditFormModalOptions => {

        const modalWordings = recordToEdit
            ?  wordingsSubzonePage.action.edit.modal
            : wordingsSubzonePage.action.add.modal;

        return {
            title: modalWordings.title,
            applyLabel: modalWordings.applyButtonLabel,
            cancelLabel: modalWordings.cancelButtonLabel,
            open: editModalOpened,
            onClose: handleEditModalClose,
            onSuccess: async (shouldReset: boolean) => {
                await handleEditModalOk(shouldReset);
            }
        };
    };

    return (
        <div>
            <DynamicDatatable
                    stickyHeader={true}
                    headCells={headCellsConfig}
                    data={rowData}
                    initialOrderBy={orderBy}
                    showPadding={false}
                    editOptions={computeEditOptions()}
                    allowAdding={computeAddOption()}
                    paginationOptions={(() => ({
                        page,
                        limit,
                        order,
                        orderBy,
                        total: tableData?.pagination?.total || 0,
                        rowsPerPageValues: [
                            { nb: DEFAULT_PAGINATION_LIMIT / 2, isDefault: true },
                            { nb: DEFAULT_PAGINATION_LIMIT, isDefault: true },
                            { nb: DEFAULT_PAGINATION_LIMIT * 2, isDefault: false },
                            { nb: DEFAULT_PAGINATION_LIMIT * 4, isDefault: false },
                        ],
                        meetNbRowsPerPage: false,
                        rowsPerPageLabel: wordingsDatatable.pagination.rowsPerPage,
                        onPaginationChange: handlePaginationChange,
                        onHandleSortChange: handleSortChange,
                        onMultiCellsSearch: handleMultiCellsSearch
                    }))()}
                    pageTitles={[currentPage]}
                />
            {tableDataDefinition && (
                <SubzoneEditForm
                    key={recordToEdit ? recordToEdit.type : EditMode.Add}
                    record={recordToEdit ? recordToEdit.record : undefined}
                    constraints={tableDataDefinition}
                    onSubmit={handleEditFormSubmit}
                    modalOptions={computeEditModalOptions()}
                />
            )}
        </div>
    );
};


