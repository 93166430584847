import { AuthState } from "@okta/okta-auth-js";

export const buildHeader = (authState : AuthState) => {
    return {
        'Authorization': 'Bearer ' + authState.accessToken?.accessToken,
        'Content-Type': 'application/json'
    }
};
export const buildTextHeader = (authState : AuthState) => {
    return {
        'Authorization': 'Bearer ' + authState.accessToken?.accessToken,
        'Content-Type': 'text/plain'
    }
};
export const buildCsvHeader = (authState : AuthState) => {
    return {
        'Authorization': 'Bearer ' + authState.accessToken?.accessToken,
        'Content-Type': 'text/csv'
    }
};
export const buildFileHeader = (authState : AuthState) => {
    return {
        'Authorization': 'Bearer ' + authState.accessToken?.accessToken,
    }
};
export const buildExcelHeader = (authState: AuthState) => {
    return {
        'Authorization': 'Bearer ' + authState.accessToken?.accessToken,
        'Content-Type': 'application/json'
    };
};
