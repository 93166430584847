import React, { ChangeEvent, useRef, useState, useEffect } from "react";
import { Button, CircularProgress, TextField, Tooltip } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Alert, AutocompleteInputChangeReason, Color } from "@material-ui/lab";
import { UIDialog } from "../../../../ui/feedback/UIDialog";
import { AsyncAutocompleteSelectComponent, DropDownValue } from "../components/AsyncAutocompleteSelect";
import { addQuotaRecord, editQuotaRecord} from "../repository/QuotaRepository";
import { Checkbox } from '@material-ui/core';
import { SkuTableStructure } from "../indexes/SkuIndexes";
import { searchSku } from "../repository/SkuRepository";
import { useOktaAuth } from '../../../../okta-react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { strings } from '../../i18n/strings';
import { Compellable } from "../indexes/Indexes";
import {EditMode, RecordData} from "../../presenters/pages/QuotaPage"


const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            width: '550px'
        },
        tooltip: {
            marginLeft: '15px'
        },
        sliderRoot: {
            display: 'flex',
            alignItems: 'center',
            width: '550px',
            margin: '10px 0 10px 0'
        },
        sliderTooltip: {
            marginLeft: 'auto'
        },
        displayFlex: {
            display: 'flex',
            alignItems: 'center',
            width: '550px'
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 300,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
          },
    })
});

const wordingQuotaPage = strings.page.quota;

export type EditFormModalOptions = {
    title: string;
    open: boolean;
    applyLabel: string;
    cancelLabel: string;
    applyAndResetLabel?: string;
    onClose: () => void;
    onSuccess: (shouldReset: boolean) => Promise<void>;
};

type EditFormProps = {
    constraints: Compellable;
    record?: RecordData | undefined;
    modalOptions: EditFormModalOptions;
    gncCodesToEdit: DropDownValue[];
    zoneCodeToEdit: DropDownValue | null | undefined;
};

type ValidationFeedback = {
    [key in keyof Compellable]: boolean;
};

type TableSku = SkuTableStructure.Sku;
type RecordSku = SkuTableStructure.RecordOf<TableSku>;

export const EditForm = function ({
                                                  constraints,
                                                  record,
                                                  modalOptions,
                                                  gncCodesToEdit,
                                                  zoneCodeToEdit
                                              }: EditFormProps) {

    const [isRecordChanged, setIsRecordChanged] = useState(false);

    const initImmediateValidationFeedback = (resetOnSubmitAndContinue: boolean) => {
        const result: any = {};
        tableFields.map((field) => (result[field] = !!record || (!constraints[field].required || !constraints[field].editable || constraints[field].type === 'boolean' || resetOnSubmitAndContinue)));
        return result as ValidationFeedback;
    };

    const initFieldChangeState = () => {
        const isPristine = true;
        const result: any = {};
        tableFields.map((field) => (result[field] = record?.record ? !isPristine : isPristine));
        return result as ValidationFeedback;
    };

    const tableFields = Object.keys(constraints).filter((key) => key !== 'id' && key !== 'quotaConsumption' && key !== 'skuCode');
    const [gncCodes, setGncCodes] = useState<DropDownValue[]>([]);
    const [isFieldPristine, setIsFieldPristine] = useState(initFieldChangeState());
    const classes = useStyles();
    const [isOk, setIsOk] = useState(initImmediateValidationFeedback(false));
    const $form = useRef<HTMLFormElement>(null);
    const [internalNotification, setInternalNotification] = useState<
        { severity: Color; message: string } | undefined
        >();
    const [options, setOptions] = useState<DropDownValue[]>([]);
    const [open, setOpen] = useState(false);
    const [zoneCode, setZoneCode] = useState<DropDownValue | null>();
    const { authState } = useOktaAuth();
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line
    const [reason, setReason] = useState<AutocompleteInputChangeReason>();

    useEffect(() => {
        setGncCodes(gncCodesToEdit);
    }, [gncCodesToEdit]);

    useEffect(() => {
        if(record?.record !== undefined){
            const value:DropDownValue = {key: String(record?.record.zoneCode), value: String(record?.record.zoneDescription)};
            setZoneCode(value);
        } else {
            setZoneCode(zoneCodeToEdit)
        }
        // eslint-disable-next-line
    }, [zoneCodeToEdit]);

    const onChange = (event: ChangeEvent<{}>, values: DropDownValue[]) => {
        if(!!record?.record){
            setIsRecordChanged(true);
        }
        setGncCodes(values);
        const fieldsOk = isOk;
        if(values.length === 0) {
            setIsFieldPristine((isFieldPristine: any) => ({
                ...isFieldPristine,
                "gnc": true
            }));
            fieldsOk['gnc'] = false;
        } else {
            setIsFieldPristine((isFieldPristine: any) => ({
                ...isFieldPristine,
                "gnc": false
            }));
            fieldsOk['gnc'] = true;
        }
        setIsOk(fieldsOk);
    }

    const onChangeZone = (event: ChangeEvent<{}>, value: DropDownValue | null) => {
        if(!!record?.record){
            setIsRecordChanged(true);
        }
        setZoneCode(value);
        setIsFieldPristine((isFieldPristine: any) => ({
            ...isFieldPristine,
            "zoneDescription": false
        }));
    }

    const onInputChange = (event: ChangeEvent<{}>, newInput: string, reason: AutocompleteInputChangeReason) => {
        setReason(reason);
        (async () => {
            try {
                let dropDownValues: DropDownValue[] = [];

                if(newInput.length >= 4) {
                    dropDownValues = await searchSku<TableSku>(authState!,
                        [
                            {
                                "key": "gnc",
                                "value": newInput
                            }
                        ], 1, 20).then((data) => {

                        let responseValues: DropDownValue[] = [];

                        data.values.forEach((row: RecordSku) => {
                            let rObj: DropDownValue = {
                                key: String(row.gnc),
                                value: String(row.gnc)
                            };
                            responseValues.push(rObj);
                        })
                        return responseValues;
                    });
                }
                if (open) {
                    setOptions(dropDownValues as DropDownValue[]);
                }
            } catch (error: any) {
                console.log("ERROR Something went wrong while getting gnc ids", error);
            }
        })();
    }

    const resetStates = (resetOnSubmitAndContinue: boolean) => {
        if(resetOnSubmitAndContinue === false) {
            setIsOk(initImmediateValidationFeedback(!resetOnSubmitAndContinue));
            setIsFieldPristine(initFieldChangeState());
        }
        setInternalNotification(undefined);
    };

    const handleModalClose = () => {
        modalOptions.onClose();
        resetStates(false);
    }

    /**
     * Row edition
     */
    const handleEditFormSubmit = async (form: any) => {
        const feedbackWordings = record ?
            (record.type === EditMode.Edit ?
                wordingQuotaPage.datatable.action.edit
                : wordingQuotaPage.datatable.action.duplicate)
            : wordingQuotaPage.datatable.action.add;
        await (record
            ? (record.type === EditMode.Edit ? editQuotaRecord(authState!, form) : addQuotaRecord(authState!, form))
            : addQuotaRecord(authState!, form));

        return feedbackWordings.confirmationFeedbackMessage;
    };

    const handleFormSubmit = (shouldReset: boolean) => {
        const form: any = {};
        let inputs = $form.current!.getElementsByTagName("input");
        for (let i = 0; i < inputs.length; i++) {
            form[inputs[i].id.replace("edit-","")] = inputs[i].value;
        }
        delete form["disable-close-on-select"];

        const codes:string[] = [];
        gncCodes.forEach(gncCode => codes.push(gncCode.key));

        form["gncCodes"] = codes;
        form["zoneCode"] = zoneCode?.key;
        delete form["zoneDescription"];
        if(record?.type === EditMode.Edit) {
            form["id"] = record?.record.id;
        } else {
            form["id"] = null;
        }

        setInternalNotification(undefined);

        (async () => {
            try {
                const message = await handleEditFormSubmit(form);
                await setInternalNotification({ severity: 'success', message });
                modalOptions.onSuccess(shouldReset);
                if(!shouldReset) {
                    resetStates(false);
                    setGncCodes([]);
                    if(record === undefined){
                        setZoneCode(null);
                    }
                } else {
                    resetStates(true);
                    setIsRecordChanged(false);
                }

            } catch( error:any ) {
                const errorMessage:string = error.message;
                setInternalNotification({ severity: 'error', message: errorMessage });
            }
        })();
    };

    const handleImmediateValidation = (
        field: keyof Compellable,
        e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>
    ) => {
        const isRequired = constraints[field].required;
        const isEditable = constraints[field].editable;
        const type = constraints[field].type;
        const value = e.target.value.trim();

        switch(field) {
            case "gnc":
                if(gncCodes.length > 0) {
                    setIsOk((prev) => ({
                        ...prev,
                        [field]: fieldValidation(type, isRequired, isEditable, gncCodes),
                    }));
                }
                break;
            case "zoneDescription":
                setIsOk((prev) => ({
                    ...prev,
                    [field]: fieldValidation(type, isRequired, isEditable, zoneCode),
                }));
                break;
            default:
                setIsOk((prev) => ({
                    ...prev,
                    [field]: (isRequired && isEditable) ? '' !== value && null !== value && undefined !== value : true,
                }));
        }

        setIsFieldPristine((prev:any) => ({
            ...prev,
            [field]: false,
        }));
        setIsRecordChanged(true);
        setInternalNotification(undefined);
    };

    const fieldValidation = (type: String, isRequired: boolean, isEditable: boolean, value: any) => {
        switch(type) {
            case("boolean"):
                return true;
            case("number"):
                if(isNaN(parseInt(value))) {
                    return false;
                }
                return (isRequired && isEditable) ? '' !== value : true;

            default: return (isRequired && isEditable) ? '' !== value : true;
        }
    };

    const cancelButtonOnClick = () => {
        modalOptions.onClose();
        setInternalNotification(undefined);
        resetStates(false);
        setZoneCode(null);
    }

    const buildModalActions = () => {
        let isPristine = tableFields.some((field) => {
            return isFieldPristine[field] === true;
        })
        const isButtonDisabled = (isPristine || !isRecordChanged) || tableFields.some((field) => !isOk[field]);
        return [
            <Button key="modal-cancel" onClick={cancelButtonOnClick}>
                {modalOptions.cancelLabel}
            </Button>,
            modalOptions.applyAndResetLabel ? (
                <Button key="modal-apply-reset" onClick={handleFormSubmit.bind(null, true)} disabled={isButtonDisabled}>
                    {modalOptions.applyAndResetLabel}
                </Button>
            ) : null,
            <Button
                key="modal-validate"
                onClick={handleFormSubmit.bind(null, false)}
                variant="contained"
                color="primary"
                disabled={isButtonDisabled}
                disableElevation
            >
                {modalOptions.applyLabel}
            </Button>,
        ];
    };

    return (

        <UIDialog
            open={modalOptions.open}
            onClose={handleModalClose}
            title={modalOptions.title}
            actions={buildModalActions()}
        >
            {internalNotification && (
                <Alert severity={internalNotification.severity}>{internalNotification.message}</Alert>
            )}
            <form ref={$form} autoComplete={`off`}>
                {tableFields.map((field: keyof Compellable) => {
                        const isRequired = constraints[field].required;
                        const information = constraints[field].information;
                        const isEditable = constraints[field].editable;
                        const reference = constraints[field].reference;
                        const visible = constraints[field].visible
                        const displayName = constraints[field].displayName;

                        const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
                        const checkedIcon = <CheckBoxIcon fontSize="small" />;

                        switch(field){
                            case "gnc":
                                return (
                                    <div className={classes.displayFlex} key={field}>
                                        <Autocomplete
                                            multiple
                                            id="gncCodes"
                                            options={options}
                                            disableCloseOnSelect={true}
                                            getOptionLabel={(option) => option.value}
                                            onInputChange={onInputChange}
                                            onChange={onChange}
                                            open={open}
                                            openOnFocus={true}
                                            fullWidth
                                            size="small"
                                            onOpen={() => {
                                                setOpen(true);
                                                setLoading(false);
                                            }}
                                            onClose={() => {
                                                setOpen(false);
                                            }}
                                            value={gncCodes}
                                            loading={loading}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.value}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label={displayName}
                                                    margin="normal"
                                                    placeholder={strings.placeholder.dropdown}
                                                    required={gncCodes.length === 0}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}

                                                />
                                            )}
                                        />
                                        {information && (
                                            <Tooltip title={information} className={classes.tooltip}>
                                                <HelpIcon />
                                            </Tooltip>
                                        )}
                                    </div>
                                )
                            case "zoneDescription":
                                return (
                                    <div className={classes.displayFlex} key={field}>
                                        <AsyncAutocompleteSelectComponent
                                            id={`edit-${field}`}
                                            key={field}
                                            autocompleteContainerStyle={{
                                                'flexGrow': 1
                                            }}
                                            reference={reference}
                                            required={isRequired}
                                            information={information}
                                            onSelect={handleImmediateValidation.bind(null, field)}
                                            onChangeZone={onChangeZone}
                                            label={displayName}
                                            defaultValue={zoneCode !== null ? zoneCode : null}
                                        />

                                        {information && (
                                            <Tooltip title={information} className={classes.tooltip}>
                                                <HelpIcon />
                                            </Tooltip>
                                        )}
                                    </div>
                                );
                            default:
                                return (
                                    (isEditable && visible && (
                                        <div className={classes.root} key={field}>
                                            <TextField
                                                required={isRequired}
                                                fullWidth
                                                error={!isFieldPristine[field] ? !isOk[field] : false}
                                                margin="normal"
                                                size="small"
                                                onInput={handleImmediateValidation.bind(null, field)}
                                                id={`edit-${field}`}
                                                label={displayName}
                                                variant="outlined"
                                                defaultValue={record ? record.record[field] : undefined}
                                            />

                                            {information && (
                                                <Tooltip title={information} className={classes.tooltip}>
                                                    <HelpIcon />
                                                </Tooltip>
                                            )}
                                        </div>
                                    ))
                                );
                        }
                    }
                )}
            </form>
        </UIDialog>
    );
};