import React, {useContext, useEffect, useState} from 'react';
import {HeadCell} from '../../../../graphics/tables/data/HeadCell';
import {TableRowData} from '../../../../graphics/tables/data/TableRowData';
import {DynamicDatatable} from '../../../../graphics/tables/DynamicDatatable';
import {Order} from '../../../../graphics/tables/operations/sort';
import {strings} from '../../i18n/strings';
import {Page} from '../../presenters/pages/index';
import {AppContext, AppContextSpec, useAsyncEffect} from '../../App';
import {useOktaAuth} from '../../../../okta-react';
import {ShipmentMatrixTableStructure} from "../../domain/indexes/ShipmentMatrixIndexes";
import {
    exportDataToCSV,
    getShipmentMatrixColumnInformation,
    importDataFromCSV,
    searchShipmentMatrix
} from "../../domain/repository/ShipmentMatrixRepository";
import {UIDialog} from "../../../../ui/feedback/UIDialog";
import {Button, DialogContentText} from "@material-ui/core";

// Interfaces & types
export type Record = ShipmentMatrixTableStructure.RecordOf<ShipmentMatrixTableStructure.ShipmentMatrix>;

export interface RecordRepresentation extends Record, TableRowData {
};

export interface RecordData {
    record: RecordRepresentation;
    type: EditMode;
}

const tableFields: Array<string> = [
    'id',
    'shipmentMethodCode',
    'transporterCode',
    'stockLocationCode',
    'countryCode',
    'zipCodeTemplate',
    'shipmentDelay',
    'timeSlotId'
];

// Datatable configuration
export enum EditMode {
    Add,
    Edit,
    Duplicate
}

// Wordings
const wordingShipmentMatrixPage = strings.page.shipmentMatrix;

export const ShipmentMatrixPage = () => {

    const DEFAULT_PAGINATION_LIMIT: number = (process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT) ? parseInt(process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT) : 20;
    const [headCellsConfig, setHeadCellsConfig] = useState<HeadCell<RecordRepresentation>[]>([]);
    const [rowData, setRowData] = useState<RecordRepresentation[]>([]);
    const [tableData, setTableData] = useState<ShipmentMatrixTableStructure.ResponseOf<ShipmentMatrixTableStructure.ShipmentMatrix>>();
    const [tableDataDefinition, setTableDataDefinition] = useState<ShipmentMatrixTableStructure.ResponseOfColumn<ShipmentMatrixTableStructure.ShipmentMatrix>>();
    const {authState} = useOktaAuth();
    const appContext = useContext<AppContextSpec>(AppContext);

    const wordingsDatatable = strings.datatable;
    const [limit, setLimit] = useState(DEFAULT_PAGINATION_LIMIT);
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>(tableFields[0]);
    const [recordToEdit, setRecordToEdit] = useState<RecordData>();
    const [editModalOpened, setEditModalOpened] = useState(false);
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [internalNotification, setInternalNotification] = useState<
        { title: string; message: string } | undefined
    >();
    const [modalOpen, setModalOpen] = useState(false);

    const headCells: HeadCell<RecordRepresentation>[] = tableFields.map((field) => ({
        id: field,
        label: field,
        displayName: field,
        type: "",
        required: true,
        information: "",
        position: 1,
        visible: true,
        editable: true,
        massEditable: false,
        reference: "",
        referenceColumnLabel: "",
        disablePadding: false,
        searchValue: "",
    }));

    //Get HeadCells to display tab names
    useEffect(() => {
        (async () => {
            try {
                const headCellConfigValues: ShipmentMatrixTableStructure.ResponseOfColumn<ShipmentMatrixTableStructure.ShipmentMatrix> = await getShipmentMatrixColumnInformation<ShipmentMatrixTableStructure.ShipmentMatrix>(authState!);
                headCells.forEach((cell) => {
                    const config = headCellConfigValues[cell.id];
                    cell.id = config.name;
                    cell.label = config.name;
                    cell.type = config.type;
                    cell.required = config.required;
                    cell.information = config.information;
                    cell.position = config.position;
                    cell.visible = config.visible;
                    cell.editable = config.editable;
                    cell.massEditable = config.massEditable;
                    cell.displayName = (config.displayName.length > 0) ? String(config.displayName) : String(config.name);
                });
                setHeadCellsConfig(headCells);
                setTableDataDefinition(headCellConfigValues);
            } catch (error: any) {
                console.log("ERROR: ", error);
            }
        })();
        // eslint-disable-next-line
    }, []);

    const getAndRefreshRecords = (newList ?: HeadCell<RecordRepresentation>[]) => {

        let headCellsConfigForGetAndRefresh = (newList) ? newList : headCellsConfig;

        let formattedSearchValues: ShipmentMatrixTableStructure.FormattedSearchValue[] = headCellsConfigForGetAndRefresh
            .filter(cell => cell.label && cell.searchValue)
            .map(cell => {
                return {
                    key: cell.label,
                    value: cell.searchValue
                };
            });

        const updateState = (shipmentMatrixValues: ShipmentMatrixTableStructure.ResponseOf<ShipmentMatrixTableStructure.ShipmentMatrix>) => {
            setTableData(shipmentMatrixValues);
            setRowData(shipmentMatrixValues.values);

            if (page !== shipmentMatrixValues.pagination.page) {
                setPage(shipmentMatrixValues.pagination.page);
            }
        }

        (async () => {
            setLoading(true);
            try {
                const shipmentMatrixValues: ShipmentMatrixTableStructure.ResponseOf<ShipmentMatrixTableStructure.ShipmentMatrix> =
                    await searchShipmentMatrix<Record>(authState!, formattedSearchValues, page, limit, order, orderBy);

                if (page !== shipmentMatrixValues.pagination.page) {
                    setPage(shipmentMatrixValues.pagination.page);
                }
                updateState(shipmentMatrixValues);
            } catch (error: any) {
                console.log("ERROR: ", error);
            } finally {
                setLoading(false);
            }
        })();
    }

    useAsyncEffect(async () => {
            await getAndRefreshRecords();
        }, [page, limit, order, orderBy],
    );

    const handlePaginationChange = (page: number, limit: number) => {
        setPage(page);
        setLimit(limit);
    };

    const handleSortChange = async (order: Order, orderBy: string) => {
        setOrder(order);
        setOrderBy(orderBy);
    };

    const handleMultiCellsSearch = (newList: HeadCell<RecordRepresentation>[]) => {
        setPage(1);
        headCellsConfig.forEach((cell: HeadCell<RecordRepresentation>) => {
            cell.searchValue = newList.filter(newListCell => newListCell.id === cell.id)[0].searchValue;
        });

        getAndRefreshRecords(headCellsConfig);
    };

    const onChangeVisibilityColumnDialog = (newList: HeadCell<RecordRepresentation>[]) => {
        const newHeadCells = newList.slice();
        setHeadCellsConfig(newHeadCells);
    };

    const onReorderDialogueAction = (newList: HeadCell<RecordRepresentation>[]) => {
        const newHeadCells = newList.slice();
        setHeadCellsConfig(newHeadCells);
    };

    const computeExportOption = () => {
        return {
            buttonLabel: wordingShipmentMatrixPage.datatable.action.export.title,
            onClick: downloadCSV,
        }
    };
    const downloadCSV = async () => {
        setDownloading(true);
        try {
            const response = await exportDataToCSV(authState!);
            const blob = new Blob([response], {type: 'text/csv'});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'shipment_matrix.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error: any) {
            console.log("ERROR: ", error);
        } finally {
            setDownloading(false);
        }
    };

    const computeImportOption = () => {
        return {
            buttonLabel: wordingShipmentMatrixPage.datatable.action.import.title,
            popupTitle: wordingShipmentMatrixPage.datatable.action.import.confirmTitle,
            popupText: wordingShipmentMatrixPage.datatable.action.import.text,
            confirm: wordingShipmentMatrixPage.datatable.action.import.confirm,
            cancel: wordingShipmentMatrixPage.datatable.action.import.cancel,
            onClick: uploadCSV,
        }
    };
    const uploadCSV = async () => {
        setUploading(true);
        try {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = '.csv';
            input.onchange = async (event) => {
                const target = event.target as HTMLInputElement;
                if (target.files && target.files.length > 0) {
                    const file = target.files[0];
                    const formData = new FormData();
                    formData.append('file', file);
                    try {
                        setUploading(true);
                        await importDataFromCSV(authState!, formData);
                        getAndRefreshRecords();
                        setInternalNotification({
                            title: wordingShipmentMatrixPage.modal.successTitle,
                            message: wordingShipmentMatrixPage.modal.successMessage
                        });
                    } catch (error: any) {
                        const errorMessage: string = error.message;
                        setInternalNotification({
                            title: wordingShipmentMatrixPage.modal.errorTitle,
                            message: errorMessage
                        });
                    } finally {
                        setModalOpen(true)
                        setUploading(false);
                    }
                }
            };
            input.click();
        } catch (error: any) {
            console.log('ERROR: ', error);
        } finally {
            setUploading(false);
        }
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };
    /*const fillAndOpenEditForm = (record?: RecordData) => {
        setRecordToEdit(record);
        setEditModalOpened(true);
    };

    const computeAddOption = () => {
        return {
            buttonLabel: wordingShipmentMatrixPage.datatable.action.add.buttonLabel,
            onClick: fillAndOpenEditForm,
        }
    };*/

    /**
     * Computed options
     */
    /*const handleEditOptionClick = (option: EditOptionType, record: RecordRepresentation) => {
        switch (option) {
            case EditOptionType.Edit:
                fillAndOpenEditForm({ record: record, type: EditMode.Edit });
                break;
            case EditOptionType.Duplicate:
                fillAndOpenEditForm({ record: record, type: EditMode.Duplicate });
                break;
            case EditOptionType.Delete:
                appContext.prompt(
                    wordingShipmentMatrixPage.datatable.action.remove.confirmationPromptMessage,
                    (isConfirmed) => {
                        if (isConfirmed) {
                            appContext.displayLoader(true);
                            deleteShipmentMatrix(authState!, record.id)
                                .then(() => {
                                    getAndRefreshRecords();
                                    appContext.notify({ message: wordingShipmentMatrixPage.datatable.action.remove.confirmedFeedbackMessage });
                                })
                                .catch(({ message }) => message && appContext.notifyError(message))
                                .finally(() => appContext.displayLoader(false));
                        }
                    },
                    { confirmButtonColor: 'secondary' }
                );
                break;
        }
    };*/

    /*const computeEditOptions = () => {
        return [
            { id: EditOptionType.Edit, name: wordingShipmentMatrixPage.datatable.action.edit.menuItemLabel, onClick: handleEditOptionClick },
            {
                id: EditOptionType.Duplicate,
                name: wordingShipmentMatrixPage.datatable.action.duplicate.menuItemLabel,
                onClick: handleEditOptionClick,
            },
            { id: EditOptionType.Delete, name: wordingShipmentMatrixPage.datatable.action.remove.menuItemLabel, onClick: handleEditOptionClick },
        ];
    };*/
    /**
     * Modal row add
     */
    /*const handleEditModalClose = () => {
        setEditModalOpened(false);
        setRecordToEdit(undefined);
    }

    const handleEditModalOk = (shouldReset: boolean) => {
        const feedbackWordings = recordToEdit ? (recordToEdit.type === EditMode.Edit ? wordingShipmentMatrixPage.datatable.action.edit : wordingShipmentMatrixPage.datatable.action.duplicate) : wordingShipmentMatrixPage.datatable.action.add;
        !shouldReset && setEditModalOpened(false);

        (async () => {
            try {
                getAndRefreshRecords();
                appContext.notifySuccess(feedbackWordings.confirmationFeedbackMessage);
            } catch (error: any) {
                appContext.notifyError(error);
            }
        })();
    };*/


    /*const computeEditModalOptions = (): EditFormModalOptions => {

        const modalWordings = recordToEdit
            ? (recordToEdit?.type === EditMode.Duplicate ? wordingShipmentMatrixPage.datatable.action.duplicate.modal : wordingShipmentMatrixPage.datatable.action.edit.modal)
            : wordingShipmentMatrixPage.datatable.action.add.modal;

        return {
            title: modalWordings.title,
            applyLabel: modalWordings.applyButtonLabel,
            cancelLabel: modalWordings.cancelButtonLabel,
            applyAndResetLabel: recordToEdit ? (recordToEdit.type === EditMode.Duplicate ? wordingShipmentMatrixPage.datatable.action.duplicate.modal.applyAndResetLabel : undefined) : wordingShipmentMatrixPage.datatable.action.add.modal.applyAndResetLabel,
            open: editModalOpened,
            onClose: handleEditModalClose,
            onSuccess: async (shouldReset: boolean) => {
                await handleEditModalOk(shouldReset);
            }
        };
    };*/
    const buildModalActions = () => {
        return [
            <Button key="modal-cancel" onClick={handleModalClose}>
                {wordingShipmentMatrixPage.modal.close}
            </Button>,
        ];
    };
    return (
        <Page
            title={wordingShipmentMatrixPage.title}
            descriptionLine1={wordingShipmentMatrixPage.description.line1}
            descriptionLine2={wordingShipmentMatrixPage.description.line2}
        >
            {(
                <>
                    <DynamicDatatable
                        stickyHeader={true}
                        headCells={headCellsConfig}
                        data={rowData}
                        initialOrderBy={tableFields[0]}
                        showPadding={false}
                        isLoading={loading}
                        /*editOptions={computeEditOptions()}
                        allowAdding={computeAddOption()}*/
                        allowExporting={computeExportOption()}
                        isDownloading={downloading}
                        allowImporting={computeImportOption()}
                        isUploading={uploading}
                        onChangeVisibilityColumnDialog={onChangeVisibilityColumnDialog}
                        onReorderDialogueAction={onReorderDialogueAction}
                        allowReordering={{
                            modal: {
                                title: wordingsDatatable.reorderColumns.modal.title,
                                applyButtonLabel: wordingsDatatable.reorderColumns.modal.applyButtonLabel,
                            },
                            triggerButtonLabel: wordingsDatatable.reorderColumns.modal.title
                        }}
                        allowChangeVisibilityColumn={{
                            modal: {
                                title: wordingsDatatable.changeVisibilityColumns.modal.title,
                                applyButtonLabel: wordingsDatatable.changeVisibilityColumns.modal.applyButtonLabel,
                            },
                            triggerButtonLabel: wordingsDatatable.changeVisibilityColumns.modal.title
                        }}
                        paginationOptions={(() => ({
                            page,
                            limit,
                            order,
                            orderBy,
                            total: tableData?.pagination?.total || 0,
                            rowsPerPageValues: [
                                {nb: DEFAULT_PAGINATION_LIMIT / 2, isDefault: true},
                                {nb: DEFAULT_PAGINATION_LIMIT, isDefault: true},
                                {nb: DEFAULT_PAGINATION_LIMIT * 2, isDefault: false},
                                {nb: DEFAULT_PAGINATION_LIMIT * 4, isDefault: false},
                            ],
                            meetNbRowsPerPage: false,
                            rowsPerPageLabel: wordingsDatatable.pagination.rowsPerPage,
                            onPaginationChange: handlePaginationChange,
                            onHandleSortChange: handleSortChange,
                            onMultiCellsSearch: handleMultiCellsSearch
                        }))()}
                    />
                    {internalNotification ? (
                        <UIDialog
                            open={modalOpen}
                            onClose={handleModalClose}
                            title={internalNotification.title}
                            actions={buildModalActions()}
                        >
                            <DialogContentText>
                                {internalNotification.message && (
                                    <div dangerouslySetInnerHTML={{ __html: internalNotification.message.replace(/\n/g, "<br>") }} />
                                )}
                            </DialogContentText>
                        </UIDialog>
                    ) : null}
                </>
            )}
            {/*{tableDataDefinition && (
                <ShipmentMatrixEditForm
                    key={recordToEdit ? recordToEdit.type : EditMode.Add}
                    record={recordToEdit ? recordToEdit : undefined}
                    constraints={tableDataDefinition}
                    modalOptions={computeEditModalOptions()}
                />
            )}*/}
        </Page>
    );
};
