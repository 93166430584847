import { Order } from "../../../../graphics/tables/operations/sort";
import { ApiDatasource } from "../../adapters/io/datasources/ApiDatasource";
import { unwrap } from "../../adapters/io/network/HttpResponse";
import { formatText } from "../../adapters/io/network/utils";
import { ApiDatasourceSpec } from "../../core/datasource";
import { AuthState } from '@okta/okta-auth-js';
import { buildHeader } from './utils/Utils';
import {ThresholdAuditTableStructure} from "../indexes/ThresholdAuditIndexes";

export const searchThresholdAudit = <T>(
    authState: AuthState,
    search: ThresholdAuditTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<ThresholdAuditTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<ThresholdAuditTableStructure.ResponseOf<T>, ThresholdAuditTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointThresholdAudit.endPoint.concat(EndpointThresholdAudit.searchThresholdAudit), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const getThresholdAuditColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<ThresholdAuditTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<ThresholdAuditTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointThresholdAudit.endPoint.concat(EndpointThresholdAudit.getThresholdAuditColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const EndpointThresholdAudit = {
    endPoint: '/end-point/threshold/audit',
    searchThresholdAudit: '/search',
    getThresholdAuditColumnInformation: '/'
};