import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { UIButton } from '../../../../ui/inputs/UIButton';
import { strings } from '../../i18n/strings';
import { Routes } from '../navigation/AppRouter';
import { history } from '../../App';


const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop : '200px',
            '& *': {
                display: 'flex',
                flexDirection: 'column'
            }
        },
        content: {
            width: '30%',
            maxWidth: '400px',
            minWidth: '300px',
            paddingBottom: '40px'
        },
        circleLogo: {
            height: '100px',
            width: '100px',
            backgroundColor: '#000',
            borderRadius: '50%',
            padding: '10px',
            display: 'inline-block',
            margin: '20px auto'
        }
    });
});

export const PageNotFound= () => {
    const classes = useStyles();
    const REACT_APP_BRAND_NAME = process.env.REACT_APP_BRAND_NAME ?? '';
    const REACT_APP_NAME = process.env.REACT_APP_NAME ?? '';
    const goBackToSite = () => {
        history.push(Routes.loginPage);
    };
   
    return (
        <div className={classes.root}>
            <Paper elevation={3} variant="outlined" className={classes.content}>
                <img src="/logo-algo-white.png" className={classes.circleLogo} alt={REACT_APP_BRAND_NAME}/>
                <h2>{REACT_APP_BRAND_NAME} - {REACT_APP_NAME}</h2>
                <h3>{strings.page.pageNotFound.content}</h3>
                <UIButton
                    text={strings.page.pageNotFound.backToSite}
                    color="primary"
                    onClick={goBackToSite}
                    style={{width: '50%', margin: 'auto'}}
                /> 
            </Paper>
        </div>
    );
}
