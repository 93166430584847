import React, { useContext, useState, useEffect } from 'react';

import { strings } from '../../i18n/strings';
import { AppContext, AppContextSpec, useAsyncEffect} from '../../App';
import { DynamicDatatable, EditOptionType} from 'graphics/tables/DynamicDatatable';
import { Order } from "../../../../graphics/tables/operations/sort";
import { HeadCell } from 'graphics/tables/data/HeadCell';
import { TableRowData } from 'graphics/tables/data/TableRowData';
import { EditForm as SkuThresholdEditForm, EditFormModalOptions} from "../../domain/forms/SkuThresholdEditForm";
import { ThresholdTableStructure } from '../../domain/indexes/ThresholdIndexes';
import {
    createSKUThreshold,
    deleteThreshold,
    editSKUThreshold,
    getSKUThresholdColumnInformation,
    massEditSKUThreshold,
    searchThresholds
} from '../../domain/repository/ThresholdRepository';
import { useOktaAuth } from '../../../../okta-react';
import { massEditSkuThresholdSelectionByCriteria } from '../../domain/repository/TechnicalRepository';

// Interfaces & types
type Record = ThresholdTableStructure.RecordOf<ThresholdTableStructure.Threshold>;
interface RecordRepresentation extends Record, TableRowData { };
interface RecordData { record: RecordRepresentation, type: EditMode }

// Datatable configuration
enum EditMode {
    Add,
    Edit,
    Duplicate
}

const tableFields: Array<string> = [
    'id',
    'channelCode',
    'storeCode',
    'activityAndSubActivity',
    'gnc',
    'skuCode',
    'thresholdLevel',
    'thresholdValue'
];

// Wordings
const wordingsThresholdPage = strings.page.threshold;
const wordingsDatatable = strings.datatable;
const wordingThresholdAuditPage = strings.page.threshold.thresholdAudit;


export const SkuThresholdTable = () => {

    const DEFAULT_PAGINATION_LIMIT : number = (process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT) ? parseInt(process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT) : 20;
    const [headCellsConfig, setHeadCellsConfig] = useState<HeadCell<RecordRepresentation>[]>([]);
    const [rowData, setRowData] = useState<RecordRepresentation[]>([]);
    const [tableData, setTableData] = useState<ThresholdTableStructure.ResponseOf<ThresholdTableStructure.Threshold>>();
    const [tableDataDefinition, setTableDataDefinition] = useState<ThresholdTableStructure.ResponseOfColumn<ThresholdTableStructure.Threshold>>();
    const [limit, setLimit] = useState(DEFAULT_PAGINATION_LIMIT);
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>(tableFields[0]);
    const appContext = useContext<AppContextSpec>(AppContext);
    const [editModalOpened, setEditModalOpened] = useState(false);
    const [recordToEdit, setRecordToEdit] = useState<RecordData>();
    const { authState } = useOktaAuth();
    const [updateSignal, setUpdateSignal] = useState(false);

    const currentPage = wordingThresholdAuditPage.title;


    // Define configuration and try to override by algo configuration
    const headCells: HeadCell<RecordRepresentation>[] = tableFields.map((field) => ({
        id: field,
        label: field,
        displayName: field,
        type: "",
        required: true,
        information: "",
        position: 1,
        visible: true,
        editable: true,
        massEditable: false,
        reference: "",
        referenceColumnLabel: "",
        disablePadding: false,
        searchValue: "",
    }));

    useEffect(() => {
        (async () => {
            try {
                const headCellConfigValues: ThresholdTableStructure.ResponseOfColumn<ThresholdTableStructure.Threshold> = await getSKUThresholdColumnInformation<ThresholdTableStructure.Threshold>(authState!);
                headCells.forEach((cell) => {
                    const config = headCellConfigValues[cell.id];
                    cell.id = config.name;
                    cell.label = config.name;
                    cell.type = config.type;
                    cell.required = config.required;
                    cell.information = config.information;
                    cell.position = config.position;
                    cell.visible = config.visible;
                    cell.editable = config.editable;
                    cell.massEditable = config.massEditable;
                    cell.displayName = (config.displayName.length > 0) ? String(config.displayName) : String(config.name);
                });
            setHeadCellsConfig(headCells);
            setTableDataDefinition(headCellConfigValues);
        } catch (error: any) {
            console.log("ERROR: ", error);
        }
        })();
        // eslint-disable-next-line
    }, []);

    // Get filtered data logic
    const getAndRefreshRecords = (newList ?: HeadCell<RecordRepresentation>[]) => {

        appContext.displayLoader(true);

        let headCellsConfigForGetAndRefresh = (newList) ? newList : headCellsConfig;

        let formattedSearchValues: ThresholdTableStructure.FormattedSearchValue[] = headCellsConfigForGetAndRefresh
            .filter(cell => cell.label && cell.searchValue)
            .map(cell  => {
                return {
                    key: cell.label,
                    value : cell.searchValue
                };
            });

        // Search by default all threshold by sku
        let defaultSkuSearchValue : ThresholdTableStructure.FormattedSearchValue = {key: "thresholdLevel", value: "SKU"};
        formattedSearchValues.push(defaultSkuSearchValue);

        const updateState = (thresholdValues: ThresholdTableStructure.ResponseOf<ThresholdTableStructure.Threshold>) => {
            setTableData(thresholdValues);
            setRowData(thresholdValues.values);

            if (page !== thresholdValues.pagination.page) {
                setPage(thresholdValues.pagination.page);
            }
        }

        (async () => {
            try {
                const thresholdValues: ThresholdTableStructure.ResponseOf<ThresholdTableStructure.Threshold> =
                    await searchThresholds<Record>(authState!, formattedSearchValues, page, limit, order, orderBy);

                if (page !== thresholdValues.pagination.page) {
                    setPage(thresholdValues.pagination.page);
                }

                updateState(thresholdValues);
            } catch (error: any) {
                console.log("ERROR: ", error);
            }
        })();

        appContext.displayLoader(false)
    }

    useAsyncEffect(async () => {
        await getAndRefreshRecords();
        }, [page, limit, order, orderBy, updateSignal]
    );

    // Datatable handle actions
    const handlePaginationChange = (page: number, limit: number) => {
        setPage(page);
        setLimit(limit);
    };

    const handleSortChange = async (order: Order, orderBy: string) => {
        setOrder(order);
        setOrderBy(orderBy);
    };

    const handleMultiCellsSearch = ( newList: HeadCell<RecordRepresentation>[]) => {
        setPage(1);
        headCellsConfig.forEach((cell : HeadCell<RecordRepresentation>) => {
            cell.searchValue = newList.filter(newListCell => newListCell.id === cell.id)[0].searchValue;
        });

        getAndRefreshRecords(headCellsConfig);
    }

    /**
     * Row edition
     */
    const handleEditFormSubmit = async (form: Record) => {
        const feedbackWordings = recordToEdit ? (recordToEdit.type === EditMode.Edit ? wordingsThresholdPage.datatable.action.edit : wordingsThresholdPage.datatable.action.duplicate) : wordingsThresholdPage.datatable.action.add;
        await (recordToEdit
            ? (recordToEdit.type === EditMode.Edit ? editSKUThreshold(authState!, recordToEdit.record.id, form) : createSKUThreshold(authState!, form))
            : createSKUThreshold(authState!, form));

        return feedbackWordings.confirmationFeedbackMessage;
    };

    const fillAndOpenEditForm = (record?: RecordData) => {
        setRecordToEdit(record);
        setEditModalOpened(true);
    };

    const computeAddOption = () => {
        return {
            buttonLabel: wordingsThresholdPage.datatable.action.add.buttonLabel,
            onClick: fillAndOpenEditForm,
        }
    };

    /**
     * Computed options
     */
    const handleEditOptionClick = (option: EditOptionType, record: RecordRepresentation) => {
        switch (option) {
            case EditOptionType.Edit:
                fillAndOpenEditForm({ record: record, type: EditMode.Edit });
                break;
            case EditOptionType.Duplicate:
                fillAndOpenEditForm({ record: record, type: EditMode.Duplicate });
                break;
            case EditOptionType.Delete:
                appContext.prompt(
                    wordingsThresholdPage.datatable.action.remove.confirmationPromptMessage,
                    (isConfirmed) => {
                        if (isConfirmed) {
                            appContext.displayLoader(true);
                            deleteThreshold(authState!, record.id)
                                .then(() => {
                                    setRowData((prev) => prev.filter((row) => row.id !== record.id));
                                    appContext.notify({ message: wordingsThresholdPage.datatable.action.remove.confirmedFeedbackMessage });
                                })
                                .catch(({ message }) => message && appContext.notifyError(message))
                                .finally(() => appContext.displayLoader(false));
                        }
                    },
                    { confirmButtonColor: 'secondary' }
                );
                break;
        }
    };

    const computeEditOptions = () => {
        return [
            { id: EditOptionType.Edit, name: wordingsThresholdPage.datatable.action.edit.menuItemLabel, onClick: handleEditOptionClick },
            {
                id: EditOptionType.Duplicate,
                name: wordingsThresholdPage.datatable.action.duplicate.menuItemLabel,
                onClick: handleEditOptionClick,
            },
            { id: EditOptionType.Delete, name: wordingsThresholdPage.datatable.action.remove.menuItemLabel, onClick: handleEditOptionClick },
        ];
    };

    /**
     * Modal row add
     */
    const handleEditModalClose = () => setEditModalOpened(false);

    const handleEditModalOk = (shouldReset: boolean) => {
        const feedbackWordings = recordToEdit ? (recordToEdit.type === EditMode.Edit ? wordingsThresholdPage.datatable.action.edit : wordingsThresholdPage.datatable.action.duplicate) : wordingsThresholdPage.datatable.action.add;
        !shouldReset && setEditModalOpened(false);

        (async () => {
            try {
                getAndRefreshRecords();
                appContext.notifySuccess(feedbackWordings.confirmationFeedbackMessage);
            } catch (error: any) {
                appContext.notifyError(error.message);
            }
        })();
    };

    const computeEditModalOptions = (): EditFormModalOptions => {

        const modalWordings = recordToEdit
            ? (recordToEdit?.type === EditMode.Duplicate ? wordingsThresholdPage.datatable.action.duplicate.modal : wordingsThresholdPage.datatable.action.edit.modal)
            : wordingsThresholdPage.datatable.action.add.modal;

        return {
            title: modalWordings.title,
            applyLabel: modalWordings.applyButtonLabel,
            cancelLabel: modalWordings.cancelButtonLabel,
            applyAndResetLabel: recordToEdit ? (recordToEdit.type === EditMode.Duplicate ? wordingsThresholdPage.datatable.action.duplicate.modal.applyAndResetLabel : undefined) : wordingsThresholdPage.datatable.action.add.modal.applyAndResetLabel,
            open: editModalOpened,
            onClose: handleEditModalClose,
            onSuccess: async (shouldReset: boolean) => {
                await handleEditModalOk(shouldReset);
            }
        };
    };


    /**
     * Mass edit
     */
    const notifyForUpdateData= () => {
        setUpdateSignal(!updateSignal);
    }

    const handleMassEdit = async (selectedIds: string[], fieldToEdit: string, newValueToApply: string) => {
        try  {
            await massEditSKUThreshold(authState!, {selectedIds, fieldToEdit, newValueToApply});
            notifyForUpdateData();
        } catch (error:any) {
            appContext.notifyError(error.message);
        }
    }

    const handleSelectAllClick =  async () => {

        let formattedSearchValues: ThresholdTableStructure.FormattedSearchValue[] = headCellsConfig
            .filter(cell => cell.label && cell.searchValue)
            .map(cell => {
                return {
                    key: cell.label,
                    value: cell.searchValue
                };
            });

        let defaultSkuSearchValue: ThresholdTableStructure.FormattedSearchValue = {key: "thresholdLevel", value: "SKU"};
        formattedSearchValues.push(defaultSkuSearchValue);

        // Search by default all threshold by sku
        let selectedRows:number[] = [];

        try {
            selectedRows =  await massEditSkuThresholdSelectionByCriteria(authState!, formattedSearchValues, order, orderBy);
            return selectedRows;
        } catch (error: any) {
            appContext.notifyError("ERROR : Something wrong happen while trying to get all row selection")
        }
    }


    return (
        <div>
            <DynamicDatatable
                    stickyHeader={true}
                    headCells={headCellsConfig}
                    data={rowData}
                    initialOrderBy={orderBy}
                    showPadding={false}
                    editOptions={computeEditOptions()}
                    allowAdding={computeAddOption()}
                    paginationOptions={(() => ({
                        page,
                        limit,
                        order,
                        orderBy,
                        total: tableData?.pagination?.total || 0,
                        rowsPerPageValues: [
                            { nb: DEFAULT_PAGINATION_LIMIT / 2, isDefault: true },
                            { nb: DEFAULT_PAGINATION_LIMIT, isDefault: true },
                            { nb: DEFAULT_PAGINATION_LIMIT * 2, isDefault: false },
                            { nb: DEFAULT_PAGINATION_LIMIT * 4, isDefault: false },
                        ],
                        meetNbRowsPerPage: false,
                        rowsPerPageLabel: wordingsDatatable.pagination.rowsPerPage,
                        onPaginationChange: handlePaginationChange,
                        onHandleSortChange: handleSortChange,
                        onMultiCellsSearch: handleMultiCellsSearch
                    }))()}
                    massEditOptions={{
                        onMassEdit: handleMassEdit,
                        onSelectAllClick: handleSelectAllClick,
                        toolbar: {
                            action : {
                                massEdit: wordingsDatatable.massEdit.toolbar.action.massEdit,
                                edit: wordingsDatatable.massEdit.toolbar.action.edit,
                                closeMassEdit: wordingsDatatable.massEdit.toolbar.action.closeMassEdit
                            }
                        },
                        dialog: {
                            title: wordingsDatatable.massEdit.dialog.title,
                            warningMessage:  wordingsDatatable.massEdit.dialog.warningMessage,
                            confirmMessage: wordingsDatatable.massEdit.dialog.confirmMessage,
                            cancelButtonLabel: wordingsDatatable.massEdit.dialog.cancelButtonLabel,
                            applyButtonLabel: wordingsDatatable.massEdit.dialog.applyButtonLabel,
                            cancelConfirmButtonLabel: wordingsDatatable.massEdit.dialog.cancelConfirmButtonLabel,
                            applyConfirmButtonLabel: wordingsDatatable.massEdit.dialog.applyConfirmButtonLabel
                        },
                        form : {
                            field: {
                                label: {
                                    selectProperty: wordingsDatatable.massEdit.form.field.label.selectProperty
                                }
                            }
                        }
                    }}
                    showHistory={[{
                        showHistoryButton: true,
                        urlToViewHistory: '/threshold-audit',
                        linkToParentPage: false,
                        icon: 'HistoryIcon'
                    }]}
                    pageTitles={[currentPage]}
                />
            {tableDataDefinition && (
                <SkuThresholdEditForm
                    key={recordToEdit ? recordToEdit.type : EditMode.Add}
                    record={recordToEdit ? recordToEdit.record : undefined}
                    constraints={tableDataDefinition}
                    onSubmit={handleEditFormSubmit}
                    modalOptions={computeEditModalOptions()}
                />
            )}
        </div>
    );
};


