import React, { useState } from 'react';
import { useStyles } from '../styles/useStyles';
import { UIDialog } from 'ui/feedback/UIDialog';
import { UIButton } from 'ui/inputs/UIButton';
import List from '@material-ui/core/List';
import { TableRowData } from '../data/TableRowData';
import { HeadCell } from '../data/HeadCell';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { MainTheme } from 'ui/theme/CelinePalette';
import { ThemeProvider } from '@material-ui/styles';

export type ChangeVisibilityColumnWordingsType = {
    modal: {
        title: string;
        applyButtonLabel: string;
    };
    triggerButtonLabel: string;
};

export interface ChangeVisibilityColumnDialogProps<T extends TableRowData> {
    changeVisibilityColumnDialog: () => void;
    headCells: HeadCell<T>[];
    onChangeVisibilityColumnDialog: (newList: HeadCell<T>[]) => void;
    wordings: ChangeVisibilityColumnWordingsType;
}


export const ChangeVisibilityColumnDialog = <T extends TableRowData>({
    wordings,
    changeVisibilityColumnDialog,
    onChangeVisibilityColumnDialog,
    headCells
}: ChangeVisibilityColumnDialogProps<T>) => {
    const [list, setList] = useState(headCells);

    const handleToggle = (value: string) => () => {
        const newList = list.map((item) => {

            if(item.label === value) {
                const updatedItem = {
                    ...item,
                    visible: !item.visible,
                };
                return updatedItem;
            }
            return item;
        });

        setList(newList);
    };

    function onValidate() {
        onChangeVisibilityColumnDialog(list);
    }

    return (
        <UIDialog open={true} onClose={changeVisibilityColumnDialog} title={wordings.modal.title}>
            <List>
                    {list.map(({ id, displayName, visible }) => (
                        <ListItem key={id}>
                            <ListItemText primary={displayName} />
                            <ThemeProvider theme={MainTheme}>
                                <Checkbox
                                    edge="end"
                                    onChange={handleToggle(id)}
                                    checked={visible}
                                    inputProps={{ 'aria-labelledby': displayName }}
                                />
                            </ThemeProvider>
                        </ListItem>

                    ))}
                    <UIButton
                        text={wordings.modal.applyButtonLabel}
                        onClick={onValidate}
                        style={{ float: 'right', marginTop: '50px' }}
                    />
            </List>
        </UIDialog>
    );
};
