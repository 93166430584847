import { Button, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Color } from '@material-ui/lab';
import React, { useRef, useState } from 'react';
import { UIDialog } from '../../../../ui/feedback/UIDialog';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AsyncAutocompleteSelectComponent, DropDownValue } from '../components/AsyncAutocompleteSelect';
import {ThresholdTableStructure} from "../indexes/ThresholdIndexes";
import { Compellable } from "../indexes/Indexes";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            width: '550px'
        },
        tooltip: {
            marginLeft: '15px'
        },
        sliderRoot: {
            display: 'flex',
            alignItems: 'center',
            width: '550px',
            margin: '10px 0 10px 0'
        },
        sliderTooltip: {
            marginLeft: 'auto'
        },
        displayFlex: {
            display: 'flex',
            alignItems: 'center',
            width: '550px'
        }
    })
});

export type EditFormModalOptions = {
    title: string;
    open: boolean;
    applyLabel: string;
    cancelLabel: string;
    applyAndResetLabel?: string;
    onClose: () => void;
    onSuccess: (shouldReset: boolean) => Promise<void>;
};

type EditFormProps<T> = {
    constraints: Compellable;
    record?: ThresholdTableStructure.RecordOf<Compellable>;
    modalOptions: EditFormModalOptions;
    onSubmit: (form: T) => Promise<string>;
};

type ValidationFeedback = {
    [key in keyof Compellable]: boolean;
};

export const EditForm = function <RecordType>({
    constraints,
    record,
    onSubmit,
    modalOptions,
}: EditFormProps<RecordType>) {

    const initImmediateValidationFeedback = (resetOnSubmitAndContinue: boolean) => {
        const result: any = {};
        tableFields.map((field) => (
            result[field] = !!record || (!constraints[field].required || !constraints[field].editable || constraints[field].type === 'boolean' || resetOnSubmitAndContinue))
        );
        return result as ValidationFeedback;
    };

    const initFieldChangeState = () => {
        const isPristine = true;
        const result: any = {};
        tableFields.map((field) => (result[field] = record ? !isPristine : isPristine));
        return result as ValidationFeedback;
    };

    const dynamicSort = (property: string) => {
        let sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a: any, b: any) {
            let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const tableFields = Object.values(constraints).sort(dynamicSort("position")).map( (element) => element.name).filter((key) => key !== 'id');

    const classes = useStyles();

    const [isOk, setIsOk] = useState(initImmediateValidationFeedback(false));
    const $form = useRef<HTMLFormElement>(null);
    const [isPristine, setIsPristine] = useState(true);
    const [isFieldPristine, setIsFieldPristine] = useState(initFieldChangeState());
    const [internalNotification, setInternalNotification] = useState<
        { severity: Color; message: string } | undefined
    >();

    const resetStates = (resetOnSubmitAndContinue: boolean) => {
        setIsPristine(true);
        setIsOk(initImmediateValidationFeedback(resetOnSubmitAndContinue));
        setIsFieldPristine(initFieldChangeState());
        setInternalNotification(undefined);
    };

    const handleModalClose = () => {
        modalOptions.onClose()
        resetStates(false);
    };

    const handleFormSubmit = (shouldReset: boolean) => {
        const form: any = {};
        let inputs = $form.current!.getElementsByTagName("input");

        for (let i = 0; i < inputs.length; i++) {
            form[inputs[i].id.replace("edit-","")] = inputs[i].value;
        }

        setInternalNotification(undefined);

        onSubmit(form)
            .then(async (message) => {
                setInternalNotification({ severity: 'success', message });

                await modalOptions.onSuccess(shouldReset)
                
                if(shouldReset) {
                    resetStates(true);
                } else {
                    resetStates(false);
                }
                
            })
            .catch(({ message }) => { 
                setInternalNotification({ severity: 'error', message })
            });
    };

    const handleImmediateValidation = (
        field: keyof Compellable,
        e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>
    ) => {

        const isRequired = constraints[field].required;
        const isEditable = constraints[field].editable;
        const type = constraints[field].type;
        const value = e.target.value.trim();


        setIsOk((prev) => ({
            ...prev,
            [field]: (isRequired && isEditable && type !== "boolean") ? '' !== value : true,
        }));

        setIsFieldPristine((prev) => ({
            ...prev,
            [field]: false,
        }));

        isPristine && setIsPristine(false);
        setInternalNotification(undefined);
    };


    const getDefaultValue = (field: string | number) => {

        if(record) {
            let key: string = constraints[field].reference;
            let splitedReferenceColumnLabel: string[] = constraints[field].referenceColumnLabel.split("_");
            
            let compositeValue = "";
            for(let position = 0; position < splitedReferenceColumnLabel.length; position++) {
                let columnLabel = splitedReferenceColumnLabel[position];
                let recordFieldName = key + columnLabel.charAt(0).toUpperCase() + columnLabel.slice(1)
                compositeValue += (compositeValue.length === 0) ?  record?.[recordFieldName] : ' - ' + record?.[recordFieldName] ;
            }

            let defaultValue : DropDownValue = {                                
                key: String(record?.[field]),
                value: String(compositeValue)
            }
            return defaultValue;
        }
        return undefined;
    }

    const buildModalActions = () => {
        const isButtonDisabled = isPristine || tableFields.some((field) => !isOk[field]);
        return [
            <Button key="modal-cancel" onClick={handleModalClose}>
                {modalOptions.cancelLabel}
            </Button>,
            modalOptions.applyAndResetLabel ? (
                <Button key="modal-apply-reset" onClick={handleFormSubmit.bind(null, true)} disabled={isButtonDisabled}>
                    {modalOptions.applyAndResetLabel}
                </Button>
            ) : null,
            <Button
                key="modal-validate"
                onClick={handleFormSubmit.bind(null, false)}
                variant="contained"
                color="primary"
                disabled={isButtonDisabled}
                disableElevation
            >
                {modalOptions.applyLabel}
            </Button>,
        ];
    };

    const [activityValue, setActivityValue] = useState<string>((record && record?.["activityCode"] !== "") ? String(record?.["activityCode"]) : "");
    const onParentChangeValue = (parentId: string | number, parentValue : DropDownValue | null) => {
        if(parentId === 'edit-activityCode' && parentValue) {
            setActivityValue(parentValue?.key);
        }
    }

    return (
        
        <UIDialog
            open={modalOptions.open}
            onClose={handleModalClose}
            title={modalOptions.title}
            actions={buildModalActions()}
        >
            {internalNotification && (
                <Alert severity={internalNotification.severity}>{internalNotification.message}</Alert>
            )}
            <form ref={$form} autoComplete={`off`}>
                {tableFields.map((field: keyof Compellable) => {
                    const isRequired = constraints[field].required;
                    const information = constraints[field].information;
                    const isEditable = constraints[field].editable;
                    const reference = constraints[field].reference;
                    const displayName = constraints[field].displayName;

                    if(reference && reference !== "") {
                        return (
                            <div className={classes.displayFlex}>
                                <AsyncAutocompleteSelectComponent
                                    id={`edit-${field}`}
                                    key={field}
                                    autocompleteContainerStyle={{
                                        'flexGrow': 1
                                    }}
                                    reference={reference}
                                    required={isRequired}
                                    onParentChangeValue={onParentChangeValue}
                                    parentValue={activityValue}
                                    information={information}
                                    onSelect={handleImmediateValidation.bind(null, field)}
                                    label={displayName}
                                    defaultValue={getDefaultValue(field)}
                                />

                                {information && (
                                    <Tooltip title={information} className={classes.tooltip}>
                                        <HelpIcon />
                                    </Tooltip>
                                )}
                            </div>
                        );
                    }
                    if(reference && reference !== "") {
                        return (
                            <div className={classes.displayFlex}>
                                <AsyncAutocompleteSelectComponent
                                    id={`edit-${field}`}
                                    key={field}
                                    autocompleteContainerStyle={{
                                        'flexGrow': 1
                                    }}
                                    reference={reference}
                                    onParentChangeValue={onParentChangeValue}
                                    required={isRequired}
                                    information={information}
                                    onSelect={handleImmediateValidation.bind(null, field)}
                                    label={displayName}
                                    defaultValue={getDefaultValue(field)}
                                />

                                {information && (
                                    <Tooltip title={information} className={classes.tooltip}>
                                        <HelpIcon />
                                    </Tooltip>
                                )}
                            </div>
                          );
                    }
                    else {
                        return (
                            (isEditable && (
                                <div className={classes.root}>
                                    <TextField
                                        key={field}
                                        required={isRequired}
                                        fullWidth
                                        error={!isFieldPristine[field] ? !isOk[field] : false}
                                        margin="normal"
                                        size="small"
                                        onInput={handleImmediateValidation.bind(null, field)}
                                        id={`edit-${field}`}
                                        label={displayName}
                                        variant="outlined"
                                        defaultValue={record ? record[field] : undefined}
                                    />

                                    {information && (
                                        <Tooltip title={information} className={classes.tooltip}>
                                            <HelpIcon />
                                        </Tooltip>
                                    )}
                                </div>
                            )));
                    }}
                )}
            </form>
        </UIDialog>
    );
};

