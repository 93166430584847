import React, {useEffect, useRef, useState} from "react";
import {Button, TextField, Tooltip} from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import {createStyles, makeStyles, Theme, useTheme} from "@material-ui/core/styles";
import {Alert, Color} from "@material-ui/lab";
import { UIDialog } from "../../../../ui/feedback/UIDialog";
import { useOktaAuth } from '../../../../okta-react';
import { strings } from '../../i18n/strings';
import { Compellable } from "../indexes/Indexes";
import {EditMode, RecordData} from "../../presenters/pages/TransporterDaysOffWeekPage";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import {createTransporterDaysOffWeek, editTransporterDaysOffWeek} from "../repository/TransporterRespository";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            width: '550px'
        },
        tooltip: {
            marginLeft: '15px'
        },
        sliderRoot: {
            display: 'flex',
            alignItems: 'center',
            width: '550px',
            margin: '10px 0 10px 0'
        },
        sliderTooltip: {
            marginLeft: 'auto'
        },
        displayFlex: {
            display: 'flex',
            alignItems: 'center',
            width: '550px'
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 300,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        }
    })
});

const wordingTransporterDaysOffWeek = strings.page.transporterDaysOffWeek;

export type EditFormModalOptions = {
    title: string;
    open: boolean;
    applyLabel: string;
    cancelLabel: string;
    applyAndResetLabel?: string;
    onClose: () => void;
    onSuccess: (shouldReset: boolean) => Promise<void>;
};

type EditFormProps = {
    constraints: Compellable;
    record?: RecordData | undefined;
    modalOptions: EditFormModalOptions;
};

type ValidationFeedback = {
    [key in keyof Compellable]: boolean;
};

const eligibleDays = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY'
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const EditForm = function ({
                                      constraints,
                                      record,
                                      modalOptions
                                  }: EditFormProps) {

    const [isRecordChanged, setIsRecordChanged] = useState(false);

    const initImmediateValidationFeedback = (resetOnSubmitAndContinue: boolean) => {
        const result: any = {};
        tableFields.map((field) => (result[field] = !!record || (!constraints[field].required || !constraints[field].editable || resetOnSubmitAndContinue)));
        return result as ValidationFeedback;
    };

    const initFieldChangeState = () => {
        const isPristine = true;
        const result: any = {};
        tableFields.map((field) => (result[field] = record?.record ? !isPristine : isPristine));
        return result as ValidationFeedback;
    };

    const tableFields = Object.keys(constraints).filter((key) => key !== 'id');
    const [isFieldPristine, setIsFieldPristine] = useState(initFieldChangeState());
    const classes = useStyles();
    const [isOk, setIsOk] = useState<{ [key: string]: any }>(initImmediateValidationFeedback(false));
    const $form = useRef<HTMLFormElement>(null);
    const [internalNotification, setInternalNotification] = useState<
        { severity: Color; message: string } | undefined
        >();
    const { authState } = useOktaAuth();
    const [isPristine, setIsPristine] = useState(true);
    const [code, setCode] = useState<string | null>();
    const [daysOffWeek, setDaysOffWeek] = React.useState<string[]>([]);

    const loadDaysOffWeek = (recordData?: RecordData | undefined) =>  {

        var allDaysOff: Array<string> = [];

        if(recordData && recordData.record) {
            allDaysOff = recordData.record.daysOffWeek ? recordData.record.daysOffWeek.toString().split(';') : [];
        }
        setDaysOffWeek(allDaysOff);
    };

    useEffect(() => {
        loadDaysOffWeek(record);
    }, [record]);

    const theme = useTheme();
    const getStyles = (name: string, personName: string[], theme: Theme) => {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    };

    const resetStates = (resetOnSubmitAndContinue: boolean) => {
        if(resetOnSubmitAndContinue === false) {
            setIsOk(initImmediateValidationFeedback(!resetOnSubmitAndContinue));
            setIsFieldPristine(initFieldChangeState());
        }
        setInternalNotification(undefined);
    };

    const handleModalClose = () => {
        modalOptions.onClose();
        resetStates(false);
    }

    /**
     * Row edition
     */
    const handleEditFormSubmit = async (form: any) => {
        const feedbackWordings = record ?
            (record.type === EditMode.Edit ?
                wordingTransporterDaysOffWeek.datatable.action.edit
                : wordingTransporterDaysOffWeek.datatable.action.duplicate)
            : wordingTransporterDaysOffWeek.datatable.action.add;
        await (record
            ? (record.type === EditMode.Edit ? editTransporterDaysOffWeek(authState!, record.record.code, form) : createTransporterDaysOffWeek(authState!, form))
            : createTransporterDaysOffWeek(authState!, form));

        return feedbackWordings.confirmationFeedbackMessage;
    };

    const handleFormSubmit = (shouldReset: boolean) => {
        const form: any = {};
        let inputs = $form.current!.getElementsByTagName("input");

        for (let i = 0; i < inputs.length; i++) {
            form[inputs[i].id.replace("edit-","")] = inputs[i].value;
        }
        delete form["disable-close-on-select"];

        setInternalNotification(undefined);

        (async () => {
            try {
                const message = await handleEditFormSubmit(form);
                await setInternalNotification({ severity: 'success', message });
                modalOptions.onSuccess(shouldReset);
                setInternalNotification(undefined);
            } catch( error:any ) {
                const errorMessage:string = error.message;
                setInternalNotification({ severity: 'error', message: errorMessage });
            }
        })();
    };

    //To validate the transporter code
    const handleImmediateValidation = (
        field: keyof Compellable,
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
            const isRequired = constraints[field].required;
            const isEditable = constraints[field].editable;
            const type = constraints[field].type;
            const value = e.target.value.trim();

            setIsOk((prev) => ({
                ...prev,
                [field]: fieldValidation(type, isRequired, isEditable, value),
            }));

            setIsFieldPristine((prev: any) => ({
                ...prev,
                [field]: false,
            }));
            setIsRecordChanged(true);
            setInternalNotification(undefined);
    };

    const handleImmediateValidationDaysOffWeek = (
        field: keyof Compellable,
        e: React.ChangeEvent<{}>
    ) => {
        const isRequired = constraints[field].required;
        const isEditable = constraints[field].editable;
        const type = constraints[field].type;

        setIsOk((prev) => ({
            ...prev,
            [field]: fieldValidation(type, isRequired, isEditable, daysOffWeek.join(',')),
        }));

        setIsFieldPristine((prev: any) => ({
            ...prev,
            [field]: false,
        }));
        setIsRecordChanged(true);
        setInternalNotification(undefined);
    };

    const fieldValidation = (type: String, isRequired: boolean, isEditable: boolean, value: any) => {
        if(isRequired) { //only for mandatory fields
            //validation ok if value not null, not undefined nor an empty string
            return ![null, undefined, ""].includes(value);
        }
        if(!isEditable) {
            return false
        }
        return true
    };

    const cancelButtonOnClick = () => {
        modalOptions.onClose();
        setInternalNotification(undefined);
        resetStates(false);
        setCode(null);
        setDaysOffWeek([]);
    }

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDaysOffWeek(event.target.value as string[]);
    };

    const buildModalActions = () => {
        let isPristine = tableFields.every((field) => {
            return isFieldPristine[field] === true;
        })
        const isButtonDisabled = (isPristine || !isRecordChanged) || tableFields.some((field) => !isOk?.[field]);

        return [
            <Button key="modal-cancel" onClick={cancelButtonOnClick}>
                {modalOptions.cancelLabel}
            </Button>,
            modalOptions.applyAndResetLabel ? (
                <Button key="modal-apply-reset" onClick={() => handleFormSubmit(true)} disabled={isButtonDisabled}>
                    {modalOptions.applyAndResetLabel}
                </Button>
            ) : null,
            <Button
                key="modal-validate"
                onClick={() => handleFormSubmit(false)}
                variant="contained"
                color="primary"
                disabled={isButtonDisabled}
                disableElevation
            >
                {modalOptions.applyLabel}
            </Button>,
        ];
    };

    return (
        <UIDialog
            open={modalOptions.open}
            onClose={handleModalClose}
            title={modalOptions.title}
            actions={buildModalActions()}
        >
            {internalNotification && (
                <Alert severity={internalNotification.severity}>{internalNotification.message}</Alert>
            )}
            <form ref={$form} autoComplete={`off`}>
                {tableFields.map((field: keyof Compellable) => {
                    const isRequired = constraints[field].required;
                    const information = constraints[field].information;
                    const isEditable = constraints[field].editable;
                    const reference = constraints[field].reference;
                    const visible = constraints[field].visible
                    const type = constraints[field].type;
                    const displayName = constraints[field].displayName;
                    return (
                        <div>
                            { isEditable && isRequired && (
                                <div className={classes.root}>
                                    <TextField
                                        key={field}
                                        required={isRequired}
                                        fullWidth
                                        error={!isFieldPristine[field] ? !isOk[field] : false}
                                        margin="normal"
                                        size="small"
                                        onChange={(e) => handleImmediateValidation(field, e)}
                                        id={`edit-${field}`}
                                        label={displayName}
                                        variant="outlined"
                                        disabled={field === 'code' && modalOptions.title === wordingTransporterDaysOffWeek.datatable.action.edit.modal.title}
                                        defaultValue={record ? record.record[field] : undefined}
                                    />
                                    {information && (
                                        <Tooltip title={information} className={classes.tooltip}>
                                            <HelpIcon/>
                                        </Tooltip>
                                    )}
                                </div>
                            )}

                            { isEditable && (type === "day") && (
                                <div className={classes.root}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel id={`label-edit-${field}`}>{displayName}</InputLabel>
                                        <Select
                                            labelId={`label-edit-${field}`}
                                            id={`edit-${field}`}
                                            multiple
                                            fullWidth
                                            value={daysOffWeek}
                                            onChange={handleChange}
                                            onClose={(e) => handleImmediateValidationDaysOffWeek(field, e) }
                                            input={<Input id={`edit-${field}`} fullWidth/>}
                                            renderValue={(selected) => (
                                                <div className={classes.chips}>
                                                    {(selected as string[]).map((value) => (
                                                        <Chip key={value} label={value} className={classes.chip}/>
                                                    ))}
                                                </div>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {eligibleDays.map((day) => (
                                                <MenuItem key={day} value={day}
                                                          style={getStyles(day, daysOffWeek, theme)}>
                                                    {day}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    {information && (
                                        <Tooltip title={information} className={classes.tooltip}>
                                            <HelpIcon/>
                                        </Tooltip>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                })}
            </form>
        </UIDialog>
    );
};