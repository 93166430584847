import React, { useState } from 'react';
import { UIDialog } from 'ui/feedback/UIDialog';
import { HeadCell } from '../data/HeadCell';
import { TableRowData } from '../data/TableRowData';
import { UIButton } from 'ui/inputs/UIButton';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {DragAndDrop} from "app/src/domain/components/DragAndDrop";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        flexbox: {
            display: 'flex',
            justifyContent: 'space-between',

            width: '100%',
            maxWidth: '768px',
            height: 'auto',
            overflow: 'hidden',

            margin: '0 auto',
        },
        li: {
            cursor: 'pointer',
            marginBottom: '5px'
        }
    })
});

export type ReorderingWordingsType = {
    modal: {
        title: string;
        applyButtonLabel: string;
    };
    triggerButtonLabel: string;
};

interface ReorderDialogProps<T extends TableRowData> {
    closeReorderDialog: () => void;
    headCells: HeadCell<T>[];
    onReorderDialog: (newList: HeadCell<T>[]) => void;
    wordings: ReorderingWordingsType;
}

export const ReorderDialog = <T extends TableRowData>({
    wordings,
    closeReorderDialog,
    onReorderDialog,
    headCells,
}: ReorderDialogProps<T>) => {
    const classes = useStyles();
    const [list, setList] = useState<HeadCell<T>[]>(headCells);

    function onValidate() {
        onReorderDialog(list);
    }

    return (
        <UIDialog open={true} onClose={closeReorderDialog} title={wordings.modal.title}>
                <main className={classes.flexbox}>
                    <div>
                        <DragAndDrop
                            children={list}
                            setList={setList}
                        />
                        <UIButton
                            text={wordings.modal.applyButtonLabel}
                            onClick={onValidate}
                            style={{ float: 'right', marginTop: '10px', marginBottom: '5px' }}
                        />
                    </div>
                </main>
        </UIDialog>
    );
};
