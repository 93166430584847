import { createTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

export const MainTheme = createTheme({

  palette: {
    primary: {
      main: grey[900],
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      contrastText: '#FFF'
    },
    secondary: {
      main: grey[900],
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      contrastText: '#FFF'
    }
  }

})

