import React, {useEffect, useRef, useState} from "react";
import {Button, TextField, Tooltip} from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Alert, Color} from "@material-ui/lab";
import {UIDialog} from "../../../../ui/feedback/UIDialog";
import {useOktaAuth} from '../../../../okta-react';
import {strings} from '../../i18n/strings';
import {Compellable} from "../indexes/Indexes";
import {RecordData} from "../../presenters/pages/TransferDelayPage";
import {upsertTransferDelay} from "../repository/TransferDelayRepository";
import {AsyncAutocompleteSelectComponent, DropDownValue} from "../components/AsyncAutocompleteSelect";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            width: '550px'
        },
        tooltip: {
            marginLeft: '15px'
        },
        sliderRoot: {
            display: 'flex',
            alignItems: 'center',
            width: '550px',
            margin: '10px 0 10px 0'
        },
        sliderTooltip: {
            marginLeft: 'auto'
        },
        displayFlex: {
            display: 'flex',
            alignItems: 'center',
            width: '550px'
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 300,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        }
    })
});

const wordingTransferDelay = strings.page.transferDelay;

export type EditFormModalOptions = {
    title: string;
    open: boolean;
    applyLabel: string;
    cancelLabel: string;
    applyAndResetLabel?: string;
    onClose: () => void;
    onSuccess: (shouldReset: boolean) => Promise<void>;
};

type EditFormProps = {
    constraints: Compellable;
    record?: RecordData | undefined;
    modalOptions: EditFormModalOptions;
};

type ValidationFeedback = {
    [key in keyof Compellable]: boolean;
};

export const EditForm = function ({
                                      constraints,
                                      record,
                                      modalOptions
                                  }: EditFormProps) {

    const [isRecordChanged, setIsRecordChanged] = useState(false);

    const initImmediateValidationFeedback = (resetOnSubmitAndContinue: boolean) => {
        const result: any = {};
        tableFields.map((field) => (result[field] = !!record || (!constraints[field].required || !constraints[field].editable || resetOnSubmitAndContinue)));
        return result as ValidationFeedback;
    };

    const initFieldChangeState = () => {
        const isPristine = true;
        const result: any = {};
        tableFields.map((field) => (result[field] = record?.record ? !isPristine : isPristine));
        return result as ValidationFeedback;
    };

    const tableFields = Object.keys(constraints).filter((key) => key !== 'id');
    const [isFieldPristine, setIsFieldPristine] = useState(initFieldChangeState());
    const classes = useStyles();
    const [isOk, setIsOk] = useState<{ [key: string]: any }>(initImmediateValidationFeedback(false));
    const $form = useRef<HTMLFormElement>(null);
    const [internalNotification, setInternalNotification] = useState<
        { severity: Color; message: string } | undefined
    >();
    const {authState} = useOktaAuth();
    const [isPristine, setIsPristine] = useState(true);
    const [allocationPointCode, setAllocationPointCode] = React.useState<string | null>();
    const [destinatedStockAllocationCode, setDestinatedStockAllocationCode] = React.useState<string | null>();
    const [transferDelay, setTransferDelay] = React.useState<number>(2);

    const loadData = (recordData?: RecordData | undefined) =>  {
        setAllocationPointCode(record?.record.allocationPointCode);
        setDestinatedStockAllocationCode(record?.record.destinatedStockAllocationCode);
        const field = 'transferDelay';
        const isRequired = constraints[field].required;
        const isEditable = constraints[field].editable;
        const type = constraints[field].type;

        let delay = record? Number(record.record.transferDelay) : 2;
        setIsOk((prev) => ({
            ...prev,
            [field]: fieldValidation(type, isRequired, isEditable, delay),
        }));
        setIsFieldPristine((prev: any) => ({
            ...prev,
            [field]: false,
        }));
        setIsRecordChanged(true);
        isPristine && setIsPristine(false);
        setInternalNotification(undefined);
        setTransferDelay(delay);
    };
    useEffect(() => {
        loadData(record);
    }, [record]);

    const resetStates = (resetOnSubmitAndContinue: boolean) => {
        if (!resetOnSubmitAndContinue) {
            setIsOk(initImmediateValidationFeedback(!resetOnSubmitAndContinue));
            setIsFieldPristine(initFieldChangeState());
        }
        setInternalNotification(undefined);
    };

    const handleModalClose = () => {
        modalOptions.onClose();
        resetStates(false);
    }

    /**
     * Row edition
     */
    const handleEditFormSubmit = async (form: any) => {
        const feedbackWordings = record ?
            wordingTransferDelay.datatable.action.edit
            : wordingTransferDelay.datatable.action.add;
        const requestBody = {
            toUpdate: record?.record,
            updateBy: form
        }
        await upsertTransferDelay(authState!, requestBody);

        return feedbackWordings.confirmationFeedbackMessage;
    };

    const handleFormSubmit = (shouldReset: boolean) => {
        const form = {
            allocationPointCode,
            destinatedStockAllocationCode,
            transferDelay
        };

        setInternalNotification(undefined);
        (async () => {
            try {
                const message = await handleEditFormSubmit(form);
                await setInternalNotification({severity: 'success', message});
                modalOptions.onSuccess(shouldReset);
                setInternalNotification(undefined);
            } catch (error: any) {
                const errorMessage: string = error.message;
                setInternalNotification({severity: 'error', message: errorMessage});
            }
        })();
    };

    const onParentChangeValue = (parentId: string | number, parentValue: DropDownValue | null) => {
        if (parentId === 'edit-allocationPointCode' && parentValue) {
            setAllocationPointCode(parentValue.key);
            handleImmediateValidationDropDownList('allocationPointCode', parentValue)
        }
        if (parentId === 'edit-destinatedStockAllocationCode' && parentValue) {
            setDestinatedStockAllocationCode(parentValue.key);
            handleImmediateValidationDropDownList('destinatedStockAllocationCode', parentValue)
        }
    }

    const handleImmediateValidationDropDownList = (
        field: keyof Compellable,
        dropdownValue?: DropDownValue | null
    ) => {
        const isRequired = constraints[field].required;
        const isEditable = constraints[field].editable;
        const type = constraints[field].type;

        setIsOk((prev) => ({
            ...prev,
            [field]: fieldValidation(type, isRequired, isEditable, dropdownValue),
        }));

        setIsFieldPristine((prev) => ({
            ...prev,
            [field]: false,
        }));
        setIsRecordChanged(true);
        isPristine && setIsPristine(false);
        setInternalNotification(undefined);
    };

    const handleImmediateValidation = (
        field: keyof Compellable,
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const isRequired = constraints[field].required;
        const isEditable = constraints[field].editable;
        const type = constraints[field].type;
        const value = e.target.value.trim();

        setIsOk((prev) => ({
            ...prev,
            [field]: fieldValidation(type, isRequired, isEditable, value),
        }));

        setIsFieldPristine((prev: any) => ({
            ...prev,
            [field]: false,
        }));
        setIsRecordChanged(true);
        isPristine && setIsPristine(false);
        setInternalNotification(undefined);
        setTransferDelay(+value);
    };

    const fieldValidation = (type: String, isRequired: boolean, isEditable: boolean, value: any) => {
        if (isRequired) { //only for mandatory fields
            //validation ok if value not null, not undefined nor an empty string
            return ![null, undefined, ""].includes(value);
        }
        return isEditable;

    };

    const cancelButtonOnClick = () => {
        modalOptions.onClose();
        setInternalNotification(undefined);
        resetStates(false);
        setDestinatedStockAllocationCode(null);
        setAllocationPointCode(null);
        setTransferDelay(2);
    }
    const getDefaultValue = (field: String | number) => {
        if (record) {
            if (field === 'allocationPointCode') {
                return {
                    key: String(record.record[String('allocationPointCode')]),
                    value: String(record.record[String('allocationPointCode')]) + ' - ' + String(record.record[String('allocationPointDescription')])
                };
            }
            if (field === 'destinatedStockAllocationCode') {
                return {
                    key: String(record.record[String('destinatedStockAllocationCode')]),
                    value: String(record.record[String('destinatedStockAllocationCode')]) + ' - ' + String(record.record[String('destinatedStockAllocationDescription')])
                };
            }
        }
        return null;
    }

    const buildModalActions = () => {
        let isPristine = tableFields.every((field) => {
            return isFieldPristine[field];
        })
        const isButtonDisabled = (isPristine || !isRecordChanged) || tableFields.some((field) => !isOk?.[field]);

        return [
            <Button key="modal-cancel" onClick={cancelButtonOnClick}>
                {modalOptions.cancelLabel}
            </Button>,
            <Button
                key="modal-validate"
                onClick={() => handleFormSubmit(false)}
                variant="contained"
                color="primary"
                disabled={isButtonDisabled}
                disableElevation
            >
                {modalOptions.applyLabel}
            </Button>,
        ];
    };

    return (
        <UIDialog
            open={modalOptions.open}
            onClose={handleModalClose}
            title={modalOptions.title}
            actions={buildModalActions()}
        >
            {internalNotification && (
                <Alert severity={internalNotification.severity}>{internalNotification.message}</Alert>
            )}
            <form ref={$form} autoComplete={`off`}>
                {tableFields.map((field: keyof Compellable) => {
                    const isRequired = constraints[field].required;
                    const information = constraints[field].information;
                    const isEditable = constraints[field].editable;
                    const reference = constraints[field].reference;
                    const type = constraints[field].type;
                    const displayName = constraints[field].displayName;
                    return (
                        <div>
                            {isEditable && (type !== "number") && (
                                <div className={classes.displayFlex}>
                                    <AsyncAutocompleteSelectComponent
                                        id={`edit-${field}`}
                                        key={field}
                                        autocompleteContainerStyle={{
                                            'flexGrow': 1
                                        }}
                                        reference={reference}
                                        required={isRequired}
                                        information={information}
                                        label={displayName}
                                        defaultValue={getDefaultValue(field)}
                                        onParentChangeValue={onParentChangeValue}
                                    />

                                    {information && (
                                        <Tooltip title={information} className={classes.tooltip}>
                                            <HelpIcon/>
                                        </Tooltip>
                                    )}
                                </div>
                            )}
                            {isEditable && isRequired && (type === "number") && (
                                <div className={classes.root}>
                                    <TextField
                                        type="number"
                                        key={field}
                                        required={isRequired}
                                        InputProps={{
                                            inputProps: {
                                                min: 0
                                            }
                                        }}
                                        onInput={(e) => {
                                            (e.target as HTMLInputElement).value = Math
                                                .max(0, parseInt((e.target as HTMLInputElement).value))
                                                .toString()
                                                .slice(0, 2)}}
                                        fullWidth
                                        error={!isFieldPristine[field] ? !isOk[field] : false}
                                        margin="normal"
                                        size="small"
                                        onChange={(e) => handleImmediateValidation(field, e)}
                                        id={`edit-${field}`}
                                        label={displayName}
                                        variant="outlined"
                                        defaultValue={record ? record.record[field] : 2}
                                    />
                                    {information && (
                                        <Tooltip title={information} className={classes.tooltip}>
                                            <HelpIcon/>
                                        </Tooltip>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                })}
            </form>
        </UIDialog>
    );
};