import { Typography, createStyles, Theme } from '@material-ui/core';
import React, {ReactElement, ReactNode} from 'react';
import { makeStyles } from '@material-ui/core/styles';

export * from './LoginPage';
export * from './StorePage';

type PageProps = {
    title: string;
    descriptionLine1: string;
    descriptionLine2: string | ReactElement;
    rightAlignedText?: string; // Rename to make the purpose clear
    children: ReactNode;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageTitle: {
            textAlign: 'left',
        },
        pageDecriptionContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        descriptionText: {
            textAlign: 'left',
            fontStyle: 'italic',
        },
        rightAlignedText: {
            textAlign: 'right',
            fontStyle: 'normal', // Optional: You can customize the style
        },
    })
);

export const Page: React.FC<PageProps> = ({ title, descriptionLine1, descriptionLine2, rightAlignedText, children }: PageProps) => {
    const classes = useStyles();
    return (
        <>
            <Typography variant="h6" className={classes.pageTitle}>{title}</Typography>

            <div className={classes.pageDecriptionContainer}>
                <div className={classes.descriptionText}>
                    {descriptionLine1}<br />{descriptionLine2}
                </div>
                {rightAlignedText && (
                    <div className={classes.rightAlignedText}>
                        {rightAlignedText}
                    </div>
                )}
            </div>

            {children}
        </>
    );
};