import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import { ReactNode } from 'react';

export type UIDialogProps = {
    open: boolean;
    onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
    title?: ReactNode | string;
    actions?: ReactNode[];
    children: ReactNode;
    disableEscapeKeyDown?: boolean;
};

export const UIDialog = ({
    onClose,
    open,
    title,
    actions,
    children,
    disableEscapeKeyDown = false,
}: UIDialogProps) => {

    const handleOnClose = () => {
        onClose({}, 'backdropClick');
    };

    return (
        <Dialog
            disableEscapeKeyDown={disableEscapeKeyDown}
            open={open}
            onClose={handleOnClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {!!title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
            <DialogContent>{children}</DialogContent>
            <DialogActions>{actions}</DialogActions>
        </Dialog>
    );
};
