import { ApiDatasource } from '../../adapters/io/datasources/ApiDatasource';
import { unwrap } from '../../adapters/io/network/HttpResponse';
import { formatText } from '../../adapters/io/network/utils';
import { ApiDatasourceSpec } from '../../core/datasource';
import { SubZoneTableStructure } from '../indexes/SubZoneIndexes';
import { AuthState } from '@okta/okta-auth-js';
import { buildHeader } from './utils/Utils';

export const getSubZoneRecords = <T>(
    authState: AuthState,
    page?: number,
    limit?: number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<SubZoneTableStructure.ResponseOf<T>> => {


    const params = new Map();

    if (page) {
        params.set('page', page);
        params.set('limit', limit);
    }

    return datasource.remote
        .getData<SubZoneTableStructure.ResponseOf<T>>(uriFormatter(EndpointSubZone.endPoint.concat(EndpointSubZone.getSubZoneRecords), {}), buildHeader(authState), params)
        .then(unwrap);
};
export const getSubZoneCountryRecords = <T>(
    authState: AuthState,
    page?: number,
    limit?: number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<SubZoneTableStructure.ResponseOf<T>> => {


    const params = new Map();

    if (page) {
        params.set('page', page);
        params.set('limit', limit);
    }

    return datasource.remote
        .getData<SubZoneTableStructure.ResponseOf<T>>(uriFormatter(EndpointSubZone.endPoint.concat(EndpointSubZone.getSubZoneCountryRecords), {}), buildHeader(authState), params)
        .then(unwrap);
};
export const getSubZoneStateRecords = <T>(
    authState: AuthState,
    page?: number,
    limit?: number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<SubZoneTableStructure.ResponseOf<T>> => {


    const params = new Map();

    if (page) {
        params.set('page', page);
        params.set('limit', limit);
    }

    return datasource.remote
        .getData<SubZoneTableStructure.ResponseOf<T>>(uriFormatter(EndpointSubZone.endPoint.concat(EndpointSubZone.getSubZoneStateRecords), {}), buildHeader(authState), params)
        .then(unwrap);
};

export const EndpointSubZone = {
    endPoint: '/end-point',
    getSubZoneRecords: '/sub-zone',
    getSubZoneCountryRecords: '/sub-zone-country',
    getSubZoneStateRecords: '/sub-zone-state'
};
