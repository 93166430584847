import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AlertTitle, Color } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { UIAlert } from './UIAlert';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            zIndex: theme.zIndex.modal + 1,
            maxWidth: '50%',
            position: 'fixed',
            right: theme.spacing(1),
            top: '75px',
        },
    })
);

export type UIPushNotificationSpec = {
    title?: string;
    message: string;
    severity?: Color;
};

export type UIPushNotificationsProps = {
    value: UIPushNotificationSpec | undefined;
    removeAfter: number;
};

let timeoutId: number | undefined = undefined;

export const UIPushNotification = ({value, removeAfter}: UIPushNotificationsProps) => {
    const classes = useStyles();

    if (value) {
        value.severity = value.severity ?? 'info';
    }

    const [current, setCurrent] = useState(value);

    useEffect(() => {
        setCurrent(value);
        timeoutId = window.setTimeout(
            () => setCurrent(undefined),
            removeAfter
        );

        return () => {
            if (timeoutId !== undefined) {
                clearTimeout(timeoutId);
                timeoutId = undefined;
            }
        }
    }, [value]);

    return (
        <>
            {current && (
                <UIAlert style={{display: !!current ? 'flex' : 'none'}} className={classes.root} severity={current.severity}>
                    {!!current.title && (<AlertTitle>{current.title}</AlertTitle>)}
                    {current.message}
                </UIAlert>
            )}
        </>
    );
};