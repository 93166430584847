import { ApiDatasource } from '../../adapters/io/datasources/ApiDatasource';
import { unwrap } from '../../adapters/io/network/HttpResponse';
import { formatText } from '../../adapters/io/network/utils';
import { ApiDatasourceSpec } from '../../core/datasource';
import { ActivityTableStructure } from '../indexes/ActivityIndexes';
import {Order} from "../../../../graphics/tables/operations/sort";
import { AuthState } from '@okta/okta-auth-js';
import { buildHeader } from './utils/Utils';

export const getActivities = <T>(
    authState: AuthState,
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<ActivityTableStructure.ResponseOf<T>> => {
    const params = new Map();

    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .getData<ActivityTableStructure.ResponseOf<T>>(
            uriFormatter(EndpointActivity.endPoint.concat(EndpointActivity.getActivities), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const EndpointActivity = {
    endPoint: '/end-point/activity',
    getActivities: ''
};