import {Button, FormControlLabel, FormGroup, Switch, TextField} from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import { StockLocationTableStructure } from '../indexes/StockLocationIndexes';
import React, { useRef, useState, useEffect } from 'react';
import { UIDialog } from 'ui/feedback/UIDialog';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import DatePicker, { DateObject } from "react-multi-date-picker";
import Toolbar from "react-multi-date-picker/plugins/toolbar"
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import {Compellable} from "../indexes/Indexes";
import gregorian from "react-date-object/calendars/gregorian";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            width: '550px'
        },
        tooltip: {
            marginLeft: '15px'
        },
        sliderRoot: {
            display: 'flex',
            alignItems: 'center',
            width: '550px',
            margin: '10px 0 10px 0'
        },
        sliderTooltip: {
            marginLeft: 'auto'
        },
        timePicker: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },
        formControl: {
            margin: theme.spacing(1)
        },chip: {
            margin: 2,
        },chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },calendar: {
            marginTop: '20px',
        },
        displayFlex: {
            display: 'flex',
            alignItems: 'center',
            width: '550px'
        }
    })
});

export type EditFormModalOptions = {
    title: string;
    open: boolean;
    applyLabel: string;
    cancelLabel: string;
    applyAndResetLabel?: string;
    onClose: () => void;
    onSuccess: (shouldReset: boolean) => Promise<void>;
};

type EditFormProps<T> = {
    constraints: Compellable;
    record?: StockLocationTableStructure.RecordOf<Compellable>;
    modalOptions: EditFormModalOptions;
    onSubmit: (form: T) => Promise<string>;
};

type ValidationFeedback = {
    [key in keyof Compellable]: boolean;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const eligibleDays = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY'
  ];

export const StockLocationEditForm = function <RecordType>({
    constraints,
    record,
    onSubmit,
    modalOptions,
}: EditFormProps<RecordType>) {
    const tableFields = Object.keys(constraints).filter((key) => key !== 'id');
    const classes = useStyles();
    const theme = useTheme();

    const getStyles = (name: string, personName: string[], theme: Theme) => {
        return {
          fontWeight:
            personName.indexOf(name) === -1
              ? theme.typography.fontWeightRegular
              : theme.typography.fontWeightMedium,
        };
      };

    const [state, setState] = useState({
        dangerousGoodCompliant: "N"
    });

    const rec_json = JSON.stringify(record ? record: '')

    useEffect(() => {
        const rec = JSON.parse(rec_json);
        if(rec && rec['dangerousGoodCompliant']){
            setState({dangerousGoodCompliant: rec['dangerousGoodCompliant']})
        }
    }, [rec_json]);

      const [daysOffYear, setDaysOffYear] = useState<DateObject[]>([]);
      const loadDaysOffYear = (recordValue?: StockLocationTableStructure.RecordOf<Compellable>) =>  {
  
          var allDaysOff: Array<DateObject> = [];
  
          if(recordValue) {
            let splittedDates = String(recordValue['daysOffYear']).split(';');
            for(let i = 0; i < splittedDates.length; i++) {
                let year = new Date().getFullYear();
                let month = Number(splittedDates[i].trim().split("/")[1]);
                let day = Number(splittedDates[i].trim().split("/")[0]);

                allDaysOff.push(new DateObject().setCalendar(gregorian).setFormat("DD/MM").setYear(year).setMonth(month).setDay(day));
            }
          }
          setDaysOffYear(allDaysOff);
      };

    const [daysOffWeek, setDaysOffWeek] = React.useState<string[]>([]);
    const loadDaysOffWeek = (recordValue?: StockLocationTableStructure.RecordOf<Compellable>) =>  {
  
        var allDaysOff: Array<string> = [];

        if(recordValue) {
            allDaysOff = recordValue['daysOffWeek'] ? recordValue['daysOffWeek'].toString().split(';') : [];
        }
        setDaysOffWeek(allDaysOff);
    };

    useEffect(() => {
        loadDaysOffYear(record);
        loadDaysOffWeek(record);
    }, [record]);


    const initImmediateValidationFeedback = () => {
        const result: any = {};
        tableFields.map((field) => (result[field] = !!record || (!constraints[field].required || !constraints[field].editable || constraints[field].type === 'boolean')));
        return result as ValidationFeedback;
    };

    const initFieldChangeState = () => {
        const isPristine = true;
        const result: any = {};
        tableFields.map((field) => (result[field] = record ? !isPristine : isPristine));
        return result as ValidationFeedback;
    };

    const [isOk, setIsOk] = useState(initImmediateValidationFeedback());
    const $form = useRef<HTMLFormElement>(null);
    const [isPristine, setIsPristine] = useState(true);
    const [isFieldPristine, setIsFieldPristine] = useState(initFieldChangeState());
    const [internalNotification, setInternalNotification] = useState<
        { severity: Color; message: string } | undefined
    >();

    const resetStates = (resetSliders: boolean) => {
        setIsPristine(true);
        setIsOk(initImmediateValidationFeedback());
        setIsFieldPristine(initFieldChangeState());
        setInternalNotification(undefined);
    };

    const handleModalClose = () => {
        resetStates(true);
        modalOptions.onClose();
    };

    const isChecked = (field: string | number) => {
        let fieldName = String(field);
        if(fieldName === 'dangerousGoodCompliant') {
            return (state.dangerousGoodCompliant === 'Y') ? true : false;
        } else {
            return false;
        }
    }

    const changeCheckedState = (event: React.ChangeEvent<HTMLInputElement>, field: string | number) => {
        setState({ ...state, [event.target.name]: (event.target.checked) ? 'Y' : 'N' });
        handleImmediateValidation(field, event);
    }

    const handleFormSubmit = (shouldReset: boolean) => {
        const form: any = {};
        let inputs = $form.current!.getElementsByTagName("input");

        for (let i = 0; i < inputs.length; i++) {
            form[inputs[i].id.replace("edit-","")] = inputs[i].value;
        }

        setInternalNotification(undefined);

        onSubmit(form)
            .then(async (message) => {
                setInternalNotification({ severity: 'success', message });
                await modalOptions.onSuccess(shouldReset);

                if (shouldReset) {
                    $form.current!.reset();
                    resetStates(false);
                } else {
                    resetStates(true);
                }
            })
            .catch(({ message }) => { 
                setInternalNotification({ severity: 'error', message })
            });
    };

    const handleImmediateValidation = (
        field: keyof Compellable,
        e: any
    ) => {

        setIsOk(initImmediateValidationFeedback());

        const isRequired = constraints[field].required;
        const isEditable = constraints[field].editable;
        const type = constraints[field].type;
        const value = (e.target.value) ? e.target.value.trim() : '';

        setIsOk((prev) => ({
            ...prev,
            [field]: (isRequired && isEditable) ? '' !== value : true,
        }));

        setIsOk((prev) => ({
            ...prev,
            [field]: (isRequired && isEditable && type !== "boolean") ? '' !== value : true,
        }));

        setIsFieldPristine((prev) => ({
            ...prev,
            [field]: false,
        }));

        isPristine && setIsPristine(false);
        setInternalNotification(undefined);
    };

    const handleImmediateValidationChangeEvent = (
        field: keyof Compellable,
        e: React.ChangeEvent<{}>
    ) => {

        setIsOk(initImmediateValidationFeedback());

        const isRequired = constraints[field].required;
        const isEditable = constraints[field].editable;

        setIsOk((prev) => ({
            ...prev,
            [field]: (isRequired && isEditable) ? false : true,
        }));

        setIsFieldPristine((prev) => ({
            ...prev,
            [field]: false,
        }));

        isPristine && setIsPristine(false);
        setInternalNotification(undefined);
    };

    const handleImmediateValidationBoolean = (
        field: keyof Compellable
    ) => {

        setIsOk(initImmediateValidationFeedback());

        const isRequired = constraints[field].required;
        const isEditable = constraints[field].editable;

        setIsOk((prev) => ({
            ...prev,
            [field]: (isRequired && isEditable) ? false : true,
        }));

        setIsFieldPristine((prev) => ({
            ...prev,
            [field]: false,
        }));

        isPristine && setIsPristine(false);
        setInternalNotification(undefined);
    };

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDaysOffWeek(event.target.value as string[]);
    };

    const buildModalActions = () => {
        const isButtonDisabled = isPristine || tableFields.some((field) => !isOk[field]);
        return [
            <Button key="modal-cancel" onClick={handleModalClose}>
                {modalOptions.cancelLabel}
            </Button>,
            modalOptions.applyAndResetLabel ? (
                <Button key="modal-apply-reset" onClick={handleFormSubmit.bind(null, true)} disabled={isButtonDisabled}>
                    {modalOptions.applyAndResetLabel}
                </Button>
            ) : null,
            <Button
                key="modal-validate"
                onClick={handleFormSubmit.bind(null, false)}
                variant="contained"
                color="primary"
                disabled={isButtonDisabled}
                disableElevation
            >
                {modalOptions.applyLabel}
            </Button>,
        ];
    };

    return (
        
        <UIDialog
            open={modalOptions.open}
            onClose={handleModalClose}
            title={modalOptions.title}
            actions={buildModalActions()}
        >
            {internalNotification && (
                <Alert severity={internalNotification.severity}>{internalNotification.message}</Alert>
            )}
            <form ref={$form} autoComplete={`off`}>
                {tableFields.map((field: keyof Compellable) => {
                    const isRequired = constraints[field].required;
                    const information = constraints[field].information;
                    const isEditable = constraints[field].editable;
                    const type = constraints[field].type;
                    const displayName = constraints[field].displayName;

                    return (
                        <div>
                            { isEditable && (type === "number" || type === "string") && (
                                <div className={classes.root}>
                                    <TextField
                                        key={field}
                                        required={isRequired}
                                        fullWidth
                                        error={!isFieldPristine[field] ? !isOk[field] : false}
                                        margin="normal"
                                        size="small"
                                        onInput={handleImmediateValidation.bind(null, field)}
                                        id={`edit-${field}`}
                                        label={displayName}
                                        variant="outlined"
                                        defaultValue={record ? record[field] : undefined}
                                    />

                                    {information && (
                                        <Tooltip title={information} className={classes.tooltip}>
                                            <HelpIcon />
                                        </Tooltip>
                                    )}
                                </div>
                            )}

                            { isEditable && (type === "time") && (
                                <div className={classes.root}>
                                    <TextField
                                        id={`edit-${field}`}
                                        label={displayName}
                                        type="time"
                                        fullWidth
                                        error={!isFieldPristine[field] ? !isOk[field] : false}
                                        margin="normal"
                                        size="small"
                                        onInput={handleImmediateValidation.bind(null, field)}
                                        defaultValue={record ? record[field] : "00:00"}
                                        className={classes.timePicker}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, 
                                        }}
                                    />
        
                                    {information && (
                                        <Tooltip title={information} className={classes.tooltip}>
                                            <HelpIcon />
                                        </Tooltip>
                                    )}
                                </div>
                            )}

                            { isEditable && (type === "day") && (
                                <div className={classes.root}>

                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel id={`label-edit-${field}`}  >{displayName}</InputLabel>
                                        <Select
                                            labelId={`label-edit-${field}`}
                                            id={`edit-${field}`}
                                            multiple
                                            fullWidth
                                            value={daysOffWeek}
                                            onClose={handleImmediateValidationChangeEvent.bind(null, field)}
                                            onChange={handleChange}
                                            input={<Input id={`edit-${field}`} fullWidth />}
                                            renderValue={(selected) => (
                                                <div className={classes.chips}>
                                                    {(selected as string[]).map((value) => (
                                                        <Chip key={value} label={value} className={classes.chip} />
                                                    ))}
                                                </div>
                                        )}
                                        MenuProps={MenuProps}
                                        >
                                        {eligibleDays.map((day) => (
                                            <MenuItem key={day} value={day} style={getStyles(day, daysOffWeek, theme)}>
                                                {day}
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>

                                    {information && (
                                        <Tooltip title={information} className={classes.tooltip}>
                                            <HelpIcon />
                                        </Tooltip>
                                    )}
                                </div>
                            )}

                            
                            { isEditable && (type === "date") && (
                                <div className={classes.root}>

                                    <DatePicker  
                                        placeholder={displayName}
                                        id={`edit-${field}`}
                                        sort={true}
                                        className="bg-dark"
                                        value={daysOffYear ? daysOffYear: []} 
                                        onChange={(day: DateObject[]) => { 
                                            setDaysOffYear(day);
                                        }}
                                        onClose={handleImmediateValidationBoolean.bind(null, field)}
                                        containerStyle={{ width: "100%" }}
                                        multiple={true}
                                        disableYearPicker={true}
                                        hideYear={true}
                                        format="DD/MM"
                                        style={{
                                            width: "100%",
                                            textAlign: "left",
                                            fontSize: "medium",
                                            padding: "6px 6px 6px 12px",
                                            boxSizing: "border-box",
                                            height: "40px",
                                            marginTop: "20px",
                                            direction: "rtl",
                                            color: "rgba(0, 0, 0, 0.87)"
                                        }}
                                        plugins={[
                                            <Toolbar
                                                position="bottom"
                                            />
                                        ]}
                                    />
                                    

                                    {information && (
                                        <Tooltip title={information} className={classes.tooltip} style={{
                                            height: "26px",
                                            marginTop: "20px"
                                          }}>
                                            <HelpIcon />
                                        </Tooltip>
                                    )}
                                </div>
                            )}
                            { isEditable && (type === "boolean") && (
                                <div className={classes.sliderRoot}>
                                    <FormGroup>
                                        <FormControlLabel
                                        name={String(field)}
                                        control={<Switch checked={isChecked(field)} onChange={(e) => {changeCheckedState(e, field)}} id={`edit-${field}`}/>}
                                        label={displayName}
                                        key={field}
                                        value={isChecked(field)}
                                        />
                                    </FormGroup>
                                    {information && (
                                        <Tooltip title={information} className={classes.sliderTooltip}>
                                            <HelpIcon />
                                        </Tooltip>
                                    )}
                                </div>
                             )}
                        </div>
                    );
                })}
            </form>
        </UIDialog>
    );
};
