import { Order } from "../../../../graphics/tables/operations/sort";
import { ApiDatasource } from "../../adapters/io/datasources/ApiDatasource";
import { unwrap } from "../../adapters/io/network/HttpResponse";
import { formatText } from "../../adapters/io/network/utils";
import { ApiDatasourceSpec } from "../../core/datasource";
import { AuthState } from '@okta/okta-auth-js';
import { buildHeader } from './utils/Utils';
import {DefaultPPSTableStructure} from "../indexes/DefaultPPSIndexes";

export const searchDefaultPPS = <T>(
    authState: AuthState,
    search: DefaultPPSTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<DefaultPPSTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<DefaultPPSTableStructure.ResponseOf<T>, DefaultPPSTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointDefaultPPS.endPoint.concat(EndpointDefaultPPS.searchDefaultPPS), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const getDefaultPPSColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<DefaultPPSTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<DefaultPPSTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointDefaultPPS.endPoint.concat(EndpointDefaultPPS.getDefaultPPSColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const createDefaultPPS = <RecordType>(
    authState: AuthState,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .postData<void, RecordType>(uriFormatter(EndpointDefaultPPS.endPoint.concat(EndpointDefaultPPS.createDefaultPPS), {}), record, buildHeader(authState), new Map())
        .then(() => {});
};

export const editDefaultPPS = <RecordType>(
    authState: AuthState,
    id: string,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .putData<void, RecordType>(uriFormatter(EndpointDefaultPPS.endPoint.concat(EndpointDefaultPPS.editDefaultPPS), {}) + `?id=${id}`, record, buildHeader(authState), new Map())
        .then(() => {});
};

export const massEditDefaultPPS = <MassEditRequest>(
    authState: AuthState,
    massEditRequest: MassEditRequest,
    setMessage?: (message: string) => void,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> =>
    datasource.remote
        .putData<void, MassEditRequest>(uriFormatter(EndpointDefaultPPS.endPoint.concat(EndpointDefaultPPS.massEditDefaultPPS), {}), massEditRequest, buildHeader(authState), new Map())
        .then((response) => {
            if(setMessage) {// @ts-ignore
                setMessage(response?.data?.response)
        }});

export const deleteDefaultPPS = (
    authState: AuthState,
    id: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
) => datasource.remote.deleteData(uriFormatter(EndpointDefaultPPS.endPoint.concat(EndpointDefaultPPS.deleteDefaultPPS), {}) + `?id=${id}`, buildHeader(authState), new Map());


export const EndpointDefaultPPS = {
    endPoint: '/end-point/default-pps',
    searchDefaultPPS: '/search',
    getDefaultPPSColumnInformation: '/column-information',
    createDefaultPPS: '',
    editDefaultPPS: '',
    massEditDefaultPPS: '/mass-edit',
    deleteDefaultPPS: ''
};