const parseDate = (dateString: string): Date | null => {
    const parts = dateString.split(/[\/ :]/);
    if (parts.length === 5) {
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // Months are 0-based
        const year = parseInt(parts[2], 10);
        const hour = parseInt(parts[3], 10);
        const minute = parseInt(parts[4], 10);
        return new Date(year, month, day, hour, minute);
    }
    return null;
};

const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    // Check if the orderBy field is a date string in the format 'dd/MM/yyyy HH:mm'
    if (typeof aValue === 'string' && typeof bValue === 'string' && /\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}/.test(aValue) && /\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}/.test(bValue)) {
        const dateA = parseDate(aValue);
        const dateB = parseDate(bValue);

        if (dateA && dateB) {
            if (dateA < dateB) return 1;
            if (dateA > dateB) return -1;
            return 0;
        }
    }

    // Default comparison for other data types
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

export type Order = 'asc' | 'desc';

export const getComparator = <T, Key extends keyof T>(order: Order, orderBy: Key): ((a: T, b: T) => number) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = <T>(array: T[], comparator: (ord: T, by: T) => number) => {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
};