import React, { useContext, useState, useEffect } from 'react';
import { HeadCell } from '../../../../graphics/tables/data/HeadCell';
import { TableRowData } from '../../../../graphics/tables/data/TableRowData';
import { DynamicDatatable, EditOptionType } from '../../../../graphics/tables/DynamicDatatable';
import { Order } from '../../../../graphics/tables/operations/sort';
import { QuotaTableStructure } from '../../domain/indexes/QuotaIndexes';
import {
    getQuotaColumnInformation,
    searchQuotas,
    deleteQuota,
    search } from '../../domain/repository/QuotaRepository';
import { strings } from '../../i18n/strings';
import { Page } from '../../presenters/pages/index';
import { AppContext, AppContextSpec, useAsyncEffect } from '../../App';
import { EditForm as QuotaEditForm, EditFormModalOptions } from '../../domain/forms/QuotaEditForm';
import { DropDownValue } from '../../domain/components/AsyncAutocompleteSelect';
import { useOktaAuth } from '../../../../okta-react';

// Interfaces & types
type Record = QuotaTableStructure.RecordOf<QuotaTableStructure.Quota>;
interface RecordRepresentation extends Record, TableRowData { };
export interface RecordData { record: RecordRepresentation, type: EditMode }

const tableFields: Array<string> = [
    'id',
    'zoneDescription',
    'quotaName',
    'skuCode',
    'maxQuota',
    'quotaConsumption'
];

// Datatable configuration
export enum EditMode {
    Add,
    Edit,
    Duplicate
}

// Wordings
const wordingQuotaPage = strings.page.quota;
const wordingQuotaAuditPage = strings.page.quotaAudit;
const wordingQuotaSkuAuditPage = strings.page.quotaSkuAudit;

export const QuotaPage= () => {

    const DEFAULT_PAGINATION_LIMIT : number = (process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT) ? parseInt(process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT) : 20;
    const [headCellsConfig, setHeadCellsConfig] = useState<HeadCell<RecordRepresentation>[]>([]);
    const [rowData, setRowData] = useState<RecordRepresentation[]>([]);
    const [tableData, setTableData] = useState<QuotaTableStructure.ResponseOf<QuotaTableStructure.Quota>>();
    const [tableDataDefinition, setTableDataDefinition] = useState<QuotaTableStructure.ResponseOfColumn<QuotaTableStructure.Quota>>();
    const { authState } = useOktaAuth();
    const appContext = useContext<AppContextSpec>(AppContext);

    const wordingsDatatable = strings.datatable;
    const [limit, setLimit] = useState(DEFAULT_PAGINATION_LIMIT);
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>(tableFields[0]);
    const [recordToEdit, setRecordToEdit] = useState<RecordData>();
    const [editModalOpened, setEditModalOpened] = useState(false);
    const [gncCodes, setGncCodes] = useState<DropDownValue[]>([]);
    const [zoneCode, setZoneCode] = useState<DropDownValue | null | undefined>();

    const quotaAuditPageTitle = wordingQuotaAuditPage.title;
    const quotaSkuAuditPageTitle = wordingQuotaSkuAuditPage.title;

    const headCells: HeadCell<RecordRepresentation>[] = tableFields.map((field) => ({
        id: field,
        label: field,
        displayName: field,
        type: "",
        required: true,
        information: "",
        position: 1,
        visible: true,
        editable: true,
        massEditable: false,
        reference: "",
        referenceColumnLabel: "",
        disablePadding: false,
        searchValue: "",
    }));

    //Get HeadCells to display tab names
    useEffect(() => {
        (async () => {
            try {
                const headCellConfigValues: QuotaTableStructure.ResponseOfColumn<QuotaTableStructure.Quota> = await getQuotaColumnInformation<QuotaTableStructure.Quota>(authState!);
                headCells.forEach((cell) => {
                    const config = headCellConfigValues[cell.id];
                    cell.id = config.name;
                    cell.label = config.name;
                    cell.type = config.type;
                    cell.required = config.required;
                    cell.information = config.information;
                    cell.position = config.position;
                    cell.visible = config.visible;
                    cell.editable = config.editable;
                    cell.massEditable = config.massEditable;
                    cell.displayName = (config.displayName.length > 0) ? String(config.displayName) : String(config.name);
                });
                setHeadCellsConfig(headCells);
                setTableDataDefinition(headCellConfigValues);
            } catch (error: any) {
                console.log("ERROR: ", error);
            }
        })();
        // eslint-disable-next-line
    }, []);

    const getAndRefreshRecords = (newList ?: HeadCell<RecordRepresentation>[]) => {

        let headCellsConfigForGetAndRefresh = (newList) ? newList : headCellsConfig;

        let formattedSearchValues: QuotaTableStructure.FormattedSearchValue[] = headCellsConfigForGetAndRefresh
            .filter(cell => cell.label && cell.searchValue)
            .map(cell  => {
                return {
                    key: cell.label,
                    value : cell.searchValue
                };
            });

        const updateState = (quotaValues: QuotaTableStructure.ResponseOf<QuotaTableStructure.Quota>) => {
            setTableData(quotaValues);
            setRowData(quotaValues.values);

            if (page !== quotaValues.pagination.page) {
                setPage(quotaValues.pagination.page);
            }
        }

        (async () => {
            try {
                const quotaValues: QuotaTableStructure.ResponseOf<QuotaTableStructure.Quota> =
                    await searchQuotas<Record>(authState!, formattedSearchValues, page, limit, order, orderBy);

                if (page !== quotaValues.pagination.page) {
                    setPage(quotaValues.pagination.page);
                }
                updateState(quotaValues);
            } catch (error: any) {
                console.log("ERROR: ", error);
            }
        })();
    }

    useAsyncEffect(async () => {
            await getAndRefreshRecords();
        }, [page, limit, order, orderBy],
    );

    const handlePaginationChange = (page: number, limit: number) => {
        setPage(page);
        setLimit(limit);
    };

    const handleSortChange = async (order: Order, orderBy: string) => {
        setOrder(order);
        setOrderBy(orderBy);
    };

    const handleMultiCellsSearch = ( newList: HeadCell<RecordRepresentation>[]) => {
        setPage(1);
        headCellsConfig.forEach((cell : HeadCell<RecordRepresentation>) => {
            cell.searchValue = newList.filter(newListCell => newListCell.id === cell.id)[0].searchValue;
        });

        getAndRefreshRecords(headCellsConfig);
    };

    const fillAndOpenEditForm = (record?: RecordData) => {
        setEditModalOpened(true);
        if(record && (record.type === EditMode.Edit || record.type === EditMode.Duplicate)) {
            setRecordToEdit(record);
            const quotaId:string |number = record? record.record?.id: '';
            if(quotaId) {
                (async () => {
                    const quotaIdSearchValue:QuotaTableStructure.FormattedSearchValue[] = [{key: 'id', value: '' + quotaId}];
                    try {
                        const quotaValues: any = await search<Record>(authState!, quotaIdSearchValue);
                        setGncCodes(setOptionsInDropdown(quotaValues.gncCodes));
                    } catch (error: any) {
                        console.log("ERROR: ", error);
                    }
                })();
            }
        } else {
            setRecordToEdit(undefined);
            setGncCodes([]);
            setZoneCode(null);
        }
    };

    const computeAddOption = () => {
        return {
            buttonLabel: wordingQuotaPage.datatable.action.add.buttonLabel,
            onClick: fillAndOpenEditForm,
        }
    };

    /**
     * Computed options
     */
    const handleEditOptionClick = (option: EditOptionType, record: RecordRepresentation) => {
        switch (option) {
            case EditOptionType.Edit:
                fillAndOpenEditForm({ record: record, type: EditMode.Edit });
                break;
            case EditOptionType.Duplicate:
                fillAndOpenEditForm({ record: record, type: EditMode.Duplicate });
                break;
            case EditOptionType.Delete:
                appContext.prompt(
                    wordingQuotaPage.datatable.action.remove.confirmationPromptMessage,
                    (isConfirmed) => {
                        if (isConfirmed) {
                            appContext.displayLoader(true);
                            deleteQuota(authState!, record.id)
                                .then(() => {
                                    getAndRefreshRecords();
                                    appContext.notify({ message: wordingQuotaPage.datatable.action.remove.confirmedFeedbackMessage });
                                })
                                .catch(({ message }) => message && appContext.notifyError(message))
                                .finally(() => appContext.displayLoader(false));
                        }
                    },
                    { confirmButtonColor: 'secondary' }
                );
                break;
        }
    };

    const computeEditOptions = () => {
        return [
            { id: EditOptionType.Edit, name: wordingQuotaPage.datatable.action.edit.menuItemLabel, onClick: handleEditOptionClick },
            {
                id: EditOptionType.Duplicate,
                name: wordingQuotaPage.datatable.action.duplicate.menuItemLabel,
                onClick: handleEditOptionClick,
            },
            { id: EditOptionType.Delete, name: wordingQuotaPage.datatable.action.remove.menuItemLabel, onClick: handleEditOptionClick },
        ];
    };
    /**
     * Modal row add
     */
    const handleEditModalClose = () => {
        setEditModalOpened(false);
        setRecordToEdit(undefined);
        setGncCodes([]);
        setZoneCode(null);
    }

    const handleEditModalOk = (shouldReset: boolean) => {
        const feedbackWordings = recordToEdit ? (recordToEdit.type === EditMode.Edit ? wordingQuotaPage.datatable.action.edit : wordingQuotaPage.datatable.action.duplicate) : wordingQuotaPage.datatable.action.add;
        !shouldReset && setEditModalOpened(false);

        (async () => {
            try {
                getAndRefreshRecords();
                appContext.notifySuccess(feedbackWordings.confirmationFeedbackMessage);
            } catch (error: any) {
                appContext.notifyError(error);
            }
        })();
    };


    const computeEditModalOptions = (): EditFormModalOptions => {

        const modalWordings = recordToEdit
            ? (recordToEdit?.type === EditMode.Duplicate ? wordingQuotaPage.datatable.action.duplicate.modal : wordingQuotaPage.datatable.action.edit.modal)
            : wordingQuotaPage.datatable.action.add.modal;

        return {
            title: modalWordings.title,
            applyLabel: modalWordings.applyButtonLabel,
            cancelLabel: modalWordings.cancelButtonLabel,
            applyAndResetLabel: recordToEdit ? (recordToEdit.type === EditMode.Duplicate ? wordingQuotaPage.datatable.action.duplicate.modal.applyAndResetLabel : undefined) : wordingQuotaPage.datatable.action.add.modal.applyAndResetLabel,
            open: editModalOpened,
            onClose: handleEditModalClose,
            onSuccess: async (shouldReset: boolean) => {
                await handleEditModalOk(shouldReset);
            }
        };
    };

    const setOptionsInDropdown = (values: string[] | number[]): DropDownValue[] => {
        const options: DropDownValue[] = [];
        values.forEach((value: string | number) => {
            options.push({key: (value).toString(), value: (value).toString()});
        });
        return options;
    }

    return (
        <Page title={wordingQuotaPage.title} descriptionLine1={wordingQuotaPage.description.line1} descriptionLine2={wordingQuotaPage.description.line2}>
            {(
                <>
                    <DynamicDatatable
                        stickyHeader={true}
                        headCells={headCellsConfig}
                        data={rowData}
                        initialOrderBy={tableFields[0]}
                        showPadding={false}
                        editOptions={computeEditOptions()}
                        allowAdding={computeAddOption()}
                        paginationOptions={(() => ({
                            page,
                            limit,
                            order,
                            orderBy,
                            total: tableData?.pagination?.total || 0,
                            rowsPerPageValues: [
                                { nb: DEFAULT_PAGINATION_LIMIT / 2, isDefault: true },
                                { nb: DEFAULT_PAGINATION_LIMIT, isDefault: true },
                                { nb: DEFAULT_PAGINATION_LIMIT * 2, isDefault: false },
                                { nb: DEFAULT_PAGINATION_LIMIT * 4, isDefault: false },
                            ],
                            meetNbRowsPerPage: false,
                            rowsPerPageLabel: wordingsDatatable.pagination.rowsPerPage,
                            onPaginationChange: handlePaginationChange,
                            onHandleSortChange: handleSortChange,
                            onMultiCellsSearch: handleMultiCellsSearch
                        }))()}
                        showHistory={[
                            {
                                showHistoryButton: true,
                                urlToViewHistory: '/quota-audit',
                                linkToParentPage: false,
                                icon: 'HistoryIcon'
                            },
                            {
                                showHistoryButton: true,
                                urlToViewHistory: '/quota-sku-audit',
                                linkToParentPage: false,
                                icon: 'SettingsBackupRestoreIcon'
                            }]
                        }
                        pageTitles={[quotaAuditPageTitle, quotaSkuAuditPageTitle]}
                    />
                </>
            )}
            {tableDataDefinition && (
                <QuotaEditForm
                    key={recordToEdit ? recordToEdit.type : EditMode.Add}
                    record={recordToEdit ? recordToEdit : undefined}
                    constraints={tableDataDefinition}
                    modalOptions={computeEditModalOptions()}
                    gncCodesToEdit={gncCodes}
                    zoneCodeToEdit={zoneCode}
                />
            )}
        </Page>
    );
};
