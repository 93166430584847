import { Order } from "../../../../graphics/tables/operations/sort";
import { ApiDatasource } from "../../adapters/io/datasources/ApiDatasource";
import { unwrap } from "../../adapters/io/network/HttpResponse";
import { formatText } from "../../adapters/io/network/utils";
import { ApiDatasourceSpec } from "../../core/datasource";
import { AuthState } from '@okta/okta-auth-js';
import { buildHeader } from './utils/Utils';
import {DefaultPPSAuditTableStructure} from "../indexes/DefaultPPSAuditIndexes";

export const searchDefaultPPSAudit = <T>(
    authState: AuthState,
    search: DefaultPPSAuditTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<DefaultPPSAuditTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<DefaultPPSAuditTableStructure.ResponseOf<T>, DefaultPPSAuditTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointDefaultPPSAudit.endPoint.concat(EndpointDefaultPPSAudit.searchDefaultPPSAudit), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const getDefaultPPSAuditColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<DefaultPPSAuditTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<DefaultPPSAuditTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointDefaultPPSAudit.endPoint.concat(EndpointDefaultPPSAudit.getDefaultPPSAuditColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const EndpointDefaultPPSAudit = {
    endPoint: '/end-point/default-pps',
    searchDefaultPPSAudit: '/audit/search',
    getDefaultPPSAuditColumnInformation: '/audit'
};