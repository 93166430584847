import { ApiDatasource } from '../../adapters/io/datasources/ApiDatasource';
import { unwrap } from '../../adapters/io/network/HttpResponse';
import { formatText } from '../../adapters/io/network/utils';
import { ApiDatasourceSpec } from '../../core/datasource';
import { AuthState } from '@okta/okta-auth-js';
import { Order } from "../../../../graphics/tables/operations/sort";
import { ThresholdTableStructure } from '../indexes/ThresholdIndexes';
import { StockLocationTableStructure } from '../indexes/StockLocationIndexes';
import { StockPoolTableStructure } from '../indexes/StockPoolIndexes';
import { buildHeader } from './utils/Utils';
import {DefaultPPSTableStructure} from "../indexes/DefaultPPSIndexes";

export const massEditSkuThresholdSelectionByCriteria = (
    authState: AuthState,
    search: ThresholdTableStructure.FormattedSearchValue[],
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<number[]> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }

    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<Promise<number[]>, ThresholdTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointTechnical.endPoint.concat(EndpointTechnical.searchMassEditSkuThresholdSelectionByCriteria), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const massEditStockPoolSelectionByCriteria = (
    authState: AuthState,
    search: StockPoolTableStructure.FormattedSearchValue[],
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<number[]> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }

    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<Promise<number[]>, ThresholdTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointTechnical.endPoint.concat(EndpointTechnical.searchMassEditStockPoolSelectionByCriteria), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};


export const massEditStockLocationSelectionByCriteria = (
    authState: AuthState,
    search: StockLocationTableStructure.FormattedSearchValue[],
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<number[]> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }

    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<Promise<number[]>, ThresholdTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointTechnical.endPoint.concat(EndpointTechnical.searchMassEditStockLocationSelectionByCriteria), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const massEditDefaultPPSSelectionByCriteria = (
    authState: AuthState,
    search: DefaultPPSTableStructure.FormattedSearchValue[],
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<number[]> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }

    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<Promise<number[]>, ThresholdTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointTechnical.endPoint.concat(EndpointTechnical.searchMassEditDefaultPPSSelectionByCriteria), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const EndpointTechnical = {
    endPoint: '/end-point/technical',
    searchMassEditSkuThresholdSelectionByCriteria: '/threshold/mass-edit-selection-by-criteria',
    searchMassEditStockPoolSelectionByCriteria: '/stock-pool/mass-edit-selection-by-criteria',
    searchMassEditStockLocationSelectionByCriteria: '/stock-location/mass-edit-selection-by-criteria',
    searchMassEditDefaultPPSSelectionByCriteria: '/default-pps/mass-edit-selection-by-criteria'
};