import { ApiDatasource } from '../../adapters/io/datasources/ApiDatasource';
import { unwrap } from '../../adapters/io/network/HttpResponse';
import { formatText } from '../../adapters/io/network/utils';
import { ApiDatasourceSpec } from '../../core/datasource';
import { AuthState } from '@okta/okta-auth-js';
import { buildHeader } from './utils/Utils';
import { Order } from '../../../../graphics/tables/operations/sort';
import {TransporterTableStructure} from "../indexes/TransporterIndexes";
import {TransporterDaysOffWeekTableStructure} from "../indexes/TransporterDaysOffWeekIndexes";

export const getTransporterRecords = <T>(
    authState: AuthState,
    page?: number,
    limit?: number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<TransporterTableStructure.ResponseOf<T>> => {

    const params = new Map();
    if (page) {
        params.set('page', page);
        params.set('limit', limit);
    }

    return datasource.remote
        .getData<TransporterTableStructure.ResponseOf<T>>(uriFormatter(EndpointTransporter.endPoint.concat(EndpointTransporter.getTransporters), {}), buildHeader(authState), params)
        .then(unwrap);
};

export const searchTransporterDaysOffWeek = <T>(
    authState: AuthState,
    search: TransporterDaysOffWeekTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<TransporterDaysOffWeekTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<TransporterDaysOffWeekTableStructure.ResponseOf<T>, TransporterDaysOffWeekTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointTransporter.endPoint.concat(EndpointTransporter.searchTransporterDaysOffWeek), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const getTransporterDaysOffWeekColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<TransporterDaysOffWeekTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<TransporterDaysOffWeekTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointTransporter.endPoint.concat(EndpointTransporter.getTransporterDaysOffWeekColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const createTransporterDaysOffWeek = <RecordType>(
    authState: AuthState,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .postData<void, RecordType>(uriFormatter(EndpointTransporter.endPoint.concat(EndpointTransporter.createTransporterDaysOffWeek), {}), record, buildHeader(authState), new Map())
        .then(() => {});
};

export const editTransporterDaysOffWeek = <RecordType>(
    authState: AuthState,
    code: string,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .putData<void, RecordType>(uriFormatter(EndpointTransporter.endPoint.concat(EndpointTransporter.editTransporterDaysOffWeek), {}) + `?id=${code}`, record, buildHeader(authState), new Map())
        .then(() => {});
};

export const deleteTransporterDaysOffWeek = (
    authState: AuthState,
    code: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
) : Promise<void> => {

    return datasource.remote
        .deleteData(uriFormatter(EndpointTransporter.endPoint.concat(EndpointTransporter.deleteTransporterDaysOffWeek), {}) + `?id=${code}`, buildHeader(authState), new Map())
        .then(() => {});
};

export const EndpointTransporter = {
    endPoint: '/end-point/transporter',
    getTransporters: '',
    searchTransporterDaysOffWeek: '/search',
    getTransporterDaysOffWeekColumnInformation: '/column-information',
    createTransporterDaysOffWeek: '/create',
    editTransporterDaysOffWeek: '/update',
    deleteTransporterDaysOffWeek: ''
};