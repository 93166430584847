import { Page } from '../../presenters/pages/index';
import { AppContext, AppContextSpec, useAsyncEffect } from '../../App';
import { strings } from '../../i18n/strings'
import { StockPoolTableStructure } from '../../domain/indexes/StockPoolIndexes';
import {
    getStockPoolColumnInformation,
    searchStockPool,
    addStockPoolRecord,
    editStockPoolRecord,
    massEditStockPool,
    removeStockPoolRecord,
    exportDataToExcel } from '../../domain/repository/StockPoolRepository';
import React, {useContext, useEffect, useState} from 'react';
import { DynamicDatatable, EditOptionType } from '../../../../graphics/tables/DynamicDatatable';
import { StockPoolEditForm, EditFormModalOptions } from '../../domain/forms/StockPoolEditForm';
import { HeadCell } from '../../../../graphics/tables/data/HeadCell';
import { TableRowData } from '../../../../graphics/tables/data/TableRowData';
import { Order } from "../../../../graphics/tables/operations/sort";
import { useOktaAuth } from '../../../../okta-react';
import {} from "../../domain/repository/StockPoolRepository";
import { massEditStockPoolSelectionByCriteria } from "../../domain/repository/TechnicalRepository";
import {Alert} from "@material-ui/lab";

// Datatable configuration
enum EditMode {
    Add,
    Edit,
    Duplicate
}
const DEFAULT_PAGINATION_LIMIT : number = (process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT) ? parseInt(process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT) : 20;

// Interfaces & types
type Record = StockPoolTableStructure.RecordOf<StockPoolTableStructure.StockPool>;
interface RecordRepresentation extends Record, TableRowData { };
interface RecordData { record: RecordRepresentation, type: EditMode }


const tableFields: Array<keyof Omit<Record, 'id'> & string> = [
    'id',
    'channelCode',
    'channelDescription',
    'storeCode',
    'storeDescription',
    'stockLocationCode',
    'stockLocationDescription',
    'stockLocationType',
    'subZoneCode',
    'subZoneDescription',
    'pickPackShip',
    'clickAndCollect',
    'transferCompliant'
];

const wordingsDatatable = strings.datatable;
const wordingStockPoolPage = strings.page.stockpool;
const wordingStockPoolAuditPage = strings.page.stockpoolaudit;

export const StockPoolPage= () => {

    const appContext = useContext<AppContextSpec>(AppContext);
    const { authState } = useOktaAuth();
    const [rowData, setRowData] = useState<RecordRepresentation[]>([]);
    const [tableData, setTableData] = useState<StockPoolTableStructure.ResponseOf<StockPoolTableStructure.StockPool>>();
    const [headCellsConfig, setHeadCellsConfig] = useState<HeadCell<RecordRepresentation>[]>([]);
    const [tableDataDefinition, setTableDataDefinition] = useState<StockPoolTableStructure.ResponseOfColumn<StockPoolTableStructure.StockPool>>();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(DEFAULT_PAGINATION_LIMIT);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>(tableFields[0]);
    const [updateSignal, setUpdateSignal] = useState(false);
    const [editModalOpened, setEditModalOpened] = useState(false);
    const [recordToEdit, setRecordToEdit] = useState<RecordData>();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState<string>('');
    const [downloading, setDownloading] = useState(false);

    const currentPage = wordingStockPoolAuditPage.title;

    const handleClose = () => {
        setOpen(false);
        setMessage('');
    };

    useEffect(() => {
        if(message) {
            setOpen(true);
        }
    }, [message]);

    // Define configuration and try to override by algo configuration
    const headCells: HeadCell<RecordRepresentation>[] = tableFields.map((field) => ({
        id: field,
        label: field,
        displayName: field,
        type: field,
        required: true,
        information: field,
        position: 1,
        visible: true,
        editable: true,
        massEditable: false,
        reference: field,
        referenceColumnLabel: field,
        disablePadding: false,
        searchValue: "",
    }));

    //Get HeadCells to display tab names
    useEffect(() => {
        (async () => {
            try {
                const headCellConfigValues: StockPoolTableStructure.ResponseOfColumn<StockPoolTableStructure.StockPool> = await getStockPoolColumnInformation<StockPoolTableStructure.StockPool>(authState!);
                headCells.forEach((cell) => {
                    const config = headCellConfigValues[cell.id];
                    cell.id = config.name;
                    cell.label = config.name;
                    cell.type = config.type;
                    cell.required = config.required;
                    cell.information = config.information;
                    cell.position = config.position;
                    cell.visible = config.visible;
                    cell.editable = config.editable;
                    cell.massEditable = config.massEditable;
                    cell.reference = config.reference;
                    cell.displayName = (config.displayName.length > 0) ? String(config.displayName) : String(config.name);
                });
                setHeadCellsConfig(headCells);
                setTableDataDefinition(headCellConfigValues);
            } catch (error: any) {
                console.log("ERROR: ", error);
            }
        })();
        // eslint-disable-next-line
    }, []);

    const getAndRefreshRecords = (newList ?: HeadCell<RecordRepresentation>[]) => {

        appContext.displayLoader(true);

        let headCellsConfigForGetAndRefresh = (newList) ? newList : headCellsConfig;

        let formattedSearchValues: StockPoolTableStructure.FormattedSearchValue[] = headCellsConfigForGetAndRefresh
            .filter(cell => cell.label && cell.searchValue)
            .map(cell  => {
                return {
                    key: cell.label,
                    value : cell.searchValue
                };
            });

        const updateState = (stockPoolValues: StockPoolTableStructure.ResponseOf<StockPoolTableStructure.StockPool>) => {
            setTableData(stockPoolValues);
            setRowData(stockPoolValues.values);

            if (page !== stockPoolValues.pagination.page) {
                setPage(stockPoolValues.pagination.page);
            }
        }

        (async () => {
            try {
                const stockPoolValues: StockPoolTableStructure.ResponseOf<StockPoolTableStructure.StockPool> =
                    await searchStockPool<Record>(authState!, formattedSearchValues, page, limit, order, orderBy);

                if (page !== stockPoolValues.pagination.page) {
                    setPage(stockPoolValues.pagination.page);
                }

                updateState(stockPoolValues);
            } catch (error: any) {
                console.log("ERROR: ", error);
            }
        })();

        appContext.displayLoader(false)
    }

    useAsyncEffect(async () => {
            await getAndRefreshRecords();
        }, [page, limit, order, orderBy, updateSignal]
    );

    const handlePaginationChange = (page: number, limit: number) => {
        setPage(page);
        setLimit(limit);
    };

    const handleSortChange = async (order: Order, orderBy: string) => {
        setOrder(order);
        setOrderBy(orderBy);
    };

    const handleMultiCellsSearch = ( newList: HeadCell<RecordRepresentation>[]) => {
        setPage(1);
        headCellsConfig.forEach((cell : HeadCell<RecordRepresentation>) => {
            cell.searchValue = newList.filter(newListCell => newListCell.id === cell.id)[0].searchValue;
        });

        getAndRefreshRecords(headCellsConfig);
    }

    const onChangeVisibilityColumnDialog = (newList: HeadCell<RecordRepresentation>[]) => {
        const newHeadCells = newList.slice();
        setHeadCellsConfig(newHeadCells);
    }

    const onReorderDialogueAction = (newList: HeadCell<RecordRepresentation>[]) => {
        const newHeadCells = newList.slice();
        setHeadCellsConfig(newHeadCells);
    }

    /**
     * Row edition 
     */
    const handleEditFormSubmit = async (form: Record) => {
        const feedbackWordings = recordToEdit ? (recordToEdit.type === EditMode.Edit ? wordingStockPoolPage.datatable.action.edit : wordingStockPoolPage.datatable.action.duplicate) : wordingStockPoolPage.datatable.action.add;
        await (recordToEdit
            ? (recordToEdit.type === EditMode.Edit ? editStockPoolRecord(authState!, recordToEdit.record.id, form, setMessage) : addStockPoolRecord(authState!, form, setMessage))
            : addStockPoolRecord(authState!, form, setMessage));

        return feedbackWordings.confirmationFeedbackMessage;
    };

    const fillAndOpenEditForm = (record?: RecordData) => {
        setRecordToEdit(record);
        setEditModalOpened(true);
    };

    const computeAddOption = () => {
        return {
            buttonLabel: wordingStockPoolPage.datatable.action.add.buttonLabel,
            onClick: fillAndOpenEditForm,
        }
    };

    /**
     * Computed options
     */    
    const handleEditOptionClick = (option: EditOptionType, record: RecordRepresentation) => {
        switch (option) {
            case EditOptionType.Edit:
                fillAndOpenEditForm({ record: record, type: EditMode.Edit });
                break;
            case EditOptionType.Duplicate:
                fillAndOpenEditForm({ record: record, type: EditMode.Duplicate });
                break;
            case EditOptionType.Delete:
                appContext.prompt(
                    wordingStockPoolPage.datatable.action.remove.confirmationPromptMessage,
                    (isConfirmed) => {
                        if (isConfirmed) {
                            appContext.displayLoader(true);
                            removeStockPoolRecord(authState!, record.id)
                                .then(() => {
                                    setRowData((prev) => prev.filter((row) => row.id !== record.id));
                                    appContext.notify({ message: wordingStockPoolPage.datatable.action.remove.confirmedFeedbackMessage });
                                })
                                .catch(({ message }) => message && appContext.notifyError(message))
                                .finally(() => appContext.displayLoader(false));
                        }
                    },
                    { confirmButtonColor: 'secondary' }
                );
                break;
        }
    };

    const computeEditOptions = () => {
        return [
                { id: EditOptionType.Edit, name: wordingStockPoolPage.datatable.action.edit.menuItemLabel, onClick: handleEditOptionClick },
                {
                    id: EditOptionType.Duplicate,
                    name: wordingStockPoolPage.datatable.action.duplicate.menuItemLabel,
                    onClick: handleEditOptionClick,
                },
                { id: EditOptionType.Delete, name: wordingStockPoolPage.datatable.action.remove.menuItemLabel, onClick: handleEditOptionClick },
            ];
    };


    /**
     * Modal row add
     */
    const handleEditModalClose = () => setEditModalOpened(false);

    const handleEditModalOk = (shouldReset: boolean) => {
        const feedbackWordings = recordToEdit ? (recordToEdit.type === EditMode.Edit ? wordingStockPoolPage.datatable.action.edit : wordingStockPoolPage.datatable.action.duplicate) : wordingStockPoolPage.datatable.action.add;
        !shouldReset && setEditModalOpened(false);

        (async () => {
            try {
                getAndRefreshRecords();
                appContext.notifySuccess(feedbackWordings.confirmationFeedbackMessage);
            } catch (error: any) {
                appContext.notifyError("ERROR : Something wrong happen while trying to edit / create Stock Pool")
            }
        })();
    };

     const computeEditModalOptions = (): EditFormModalOptions => {

        const modalWordings = recordToEdit
            ? (recordToEdit?.type === EditMode.Duplicate ?wordingStockPoolPage.datatable.action.duplicate.modal : wordingStockPoolPage.datatable.action.edit.modal)
            : wordingStockPoolPage.datatable.action.add.modal;

        return {
            title: modalWordings.title,
            applyLabel: modalWordings.applyButtonLabel,
            cancelLabel: modalWordings.cancelButtonLabel,
            applyAndResetLabel: recordToEdit ? (recordToEdit.type === EditMode.Duplicate ? wordingStockPoolPage.datatable.action.duplicate.modal.applyAndResetLabel : undefined) : wordingStockPoolPage.datatable.action.add.modal.applyAndResetLabel,
            open: editModalOpened,
            onClose: handleEditModalClose,
            onSuccess: async (shouldReset: boolean) => {
                await handleEditModalOk(shouldReset);
            }
        };
    };

    /**
     * Mass edit
     */
    const notifyForUpdateData= () => {
        setUpdateSignal(!updateSignal);
    }

    const handleMassEdit = async (selectedIds: string[], fieldToEdit: string, newValueToApply: string) => {
        try {
            await massEditStockPool(authState!, {selectedIds, fieldToEdit, newValueToApply}, setMessage);
            notifyForUpdateData();
        } catch (error: any) {
            appContext.notifyError(error.message);
        }
    }

    const handleSelectAllClick =  async () => {

        let formattedSearchValues: StockPoolTableStructure.FormattedSearchValue[] = headCellsConfig
            .filter(cell => cell.label && cell.searchValue)
            .map(cell => {
                return {
                    key: cell.label,
                    value: cell.searchValue
                };
            });

        // Search by default all threshold by sku
        let selectedRows:number[] = [];

        try {
            selectedRows =  await massEditStockPoolSelectionByCriteria(authState!, formattedSearchValues, order, orderBy);
            return selectedRows;
        } catch (error: any) {
            appContext.notifyError("ERROR : Something wrong happen while trying to get all row selection")
        }
    }

    const computeExportOption = () => {
        return {
            buttonLabel: wordingStockPoolPage.datatable.action.export.title,
            onClick: downloadExcel,
        }
    };

    const downloadExcel = async () => {
        setDownloading(true);
        let formattedSearchValues: StockPoolTableStructure.FormattedSearchValue[] = headCellsConfig
            .filter(cell => cell.label && cell.searchValue)
            .map(cell => {
                return {
                    key: cell.label,
                    value: cell.searchValue
                };
            });

        try {
            const response = await exportDataToExcel(authState!, formattedSearchValues, order, orderBy);

            // Check if the response is an ArrayBuffer
            if (response instanceof ArrayBuffer) {
                const blob = new Blob([response], { type: 'application/octet-stream' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'stock_pool.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                console.error("Response is not an ArrayBuffer:", response);
            }
        } catch (error: any) {
            console.log("ERROR: ", error);
        } finally {
            setDownloading(false);
        }
    };

    return (
        <Page title={wordingStockPoolPage.title} descriptionLine1={wordingStockPoolPage.description.line1} descriptionLine2={wordingStockPoolPage.description.line2}>
            {(
                <>
                    {message && (
                        <Alert severity="warning" onClose={() => {setMessage('')}}>{message}</Alert>
                    )}
                    <DynamicDatatable
                        stickyHeader={true}
                        headCells={headCellsConfig}
                        data={rowData}
                        initialOrderBy={tableFields[0]}
                        showPadding={false}
                        editOptions={computeEditOptions()}
                        allowAdding={computeAddOption()}
                        allowExporting={computeExportOption()}
                        isDownloading={downloading}
                        onChangeVisibilityColumnDialog={onChangeVisibilityColumnDialog}
                        onReorderDialogueAction={onReorderDialogueAction}
                        allowReordering={{
                            modal: {
                                title: wordingsDatatable.reorderColumns.modal.title,
                                applyButtonLabel: wordingsDatatable.reorderColumns.modal.applyButtonLabel,
                            },
                            triggerButtonLabel: wordingsDatatable.reorderColumns.modal.title}}
                        allowChangeVisibilityColumn={{
                            modal: {
                                title: wordingsDatatable.changeVisibilityColumns.modal.title,
                                applyButtonLabel: wordingsDatatable.changeVisibilityColumns.modal.applyButtonLabel,
                            },
                            triggerButtonLabel: wordingsDatatable.changeVisibilityColumns.modal.title}}
                        paginationOptions={(() => ({
                            page,
                            limit,
                            order,
                            orderBy,
                            total: tableData?.pagination?.total || 0,
                            rowsPerPageValues: [
                                { nb: DEFAULT_PAGINATION_LIMIT / 2, isDefault: false },
                                { nb: DEFAULT_PAGINATION_LIMIT, isDefault: true },
                                { nb: DEFAULT_PAGINATION_LIMIT * 2, isDefault: false },
                                { nb: DEFAULT_PAGINATION_LIMIT * 4, isDefault: false },
                            ],
                            meetNbRowsPerPage: false,
                            rowsPerPageLabel: wordingsDatatable.pagination.rowsPerPage,
                            onPaginationChange: handlePaginationChange,
                            onHandleSortChange: handleSortChange,
                            onMultiCellsSearch: handleMultiCellsSearch
                        }))()}
                        massEditOptions={{
                        onMassEdit: handleMassEdit,
                        onSelectAllClick: handleSelectAllClick,
                        toolbar: {
                            action : {
                                massEdit: wordingsDatatable.massEdit.toolbar.action.massEdit,
                                edit: wordingsDatatable.massEdit.toolbar.action.edit,
                                closeMassEdit: wordingsDatatable.massEdit.toolbar.action.closeMassEdit
                            }
                        },
                        dialog: {
                            title: wordingsDatatable.massEdit.dialog.title,
                            warningMessage:  wordingsDatatable.massEdit.dialog.warningMessage,
                            confirmMessage: wordingsDatatable.massEdit.dialog.confirmMessage,
                            cancelButtonLabel: wordingsDatatable.massEdit.dialog.cancelButtonLabel,
                            applyButtonLabel: wordingsDatatable.massEdit.dialog.applyButtonLabel,
                            cancelConfirmButtonLabel: wordingsDatatable.massEdit.dialog.cancelConfirmButtonLabel,
                            applyConfirmButtonLabel: wordingsDatatable.massEdit.dialog.applyConfirmButtonLabel
                        },
                        form : {
                            field: {
                                label: {
                                    selectProperty: wordingsDatatable.massEdit.form.field.label.selectProperty
                                }
                            }
                        }
                    }}
                    showHistory={[{
                        showHistoryButton: true,
                        urlToViewHistory: '/stock-pool-audit',
                        linkToParentPage: false,
                        icon: 'HistoryIcon'
                    }]}
                    pageTitles={[currentPage]}
                    />
                </>
            ) }
            {tableDataDefinition && (
                <StockPoolEditForm
                    key={recordToEdit ? recordToEdit.type : EditMode.Add}
                    record={recordToEdit ? recordToEdit.record : undefined}
                    constraints={tableDataDefinition}
                    onSubmit={handleEditFormSubmit}
                    modalOptions={computeEditModalOptions()}
                />
            )}
        </Page>
    );
};
