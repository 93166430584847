import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { UIButton } from 'ui/inputs/UIButton';
import { strings } from '../../i18n/strings';
import { useOktaAuth } from '../../../../okta-react';
import {SignoutOptions, UserClaims} from '@okta/okta-auth-js';
import {Redirect} from "react-router-dom";
import {Routes} from "../navigation/AppRouter";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',

            alignItems: 'center',
            '& > *': {
                marginBottom: '2em'
            }
        }
    })
);


export const UserProfilePage = () => {
    const classes = useStyles();
    const { oktaAuth } = useOktaAuth();
    const [user, setUser] = useState<UserClaims | undefined>();


    const logoutOptions:SignoutOptions = {
            "revokeAccessToken": true,
            "revokeRefreshToken": true,
            "clearTokensBeforeRedirect": true,
            "postLogoutRedirectUri": Routes.loginPage
    };

    const logout = async () => {
        await(oktaAuth.signOut(logoutOptions));
        await(oktaAuth.closeSession());
        await(oktaAuth.tokenManager.clear());
        await(oktaAuth.clearStorage());
        await(oktaAuth.stop());
        fetch('https://lvmhfashion.okta.com/login/signout',  {mode:'no-cors'})
            .then((response) => {
                <Redirect to={Routes.loginPage} />
            });
        <Redirect to={Routes.loginPage} />
        await(oktaAuth.signOut(logoutOptions));
    }

    useEffect(() => {
        (async () => { 
            try {
                const response = await oktaAuth.getUser();
                setUser(response);
            } catch(err) {
                return err;
            }
        })();
        
    }, [oktaAuth]);
    
    const userWelcome = `${strings.profile.welcome} ${user? user.name : ''}`;

    return (
        <Box className={classes.root}>
            <Typography variant="h3" component="h2">
                {userWelcome}
            </Typography>
            <UIButton text="Sign out" onClick={logout} color="primary"/>
        </Box>
    );
};
