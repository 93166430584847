import React from 'react';
import { Redirect } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { UIButton } from '../../../../ui/inputs/UIButton';
import { UILoader } from '../../../../ui/feedback/UILoader';
import { strings } from '../../i18n/strings';
import { useOktaAuth } from '../../../../okta-react';
import { SigninWithRedirectOptions } from '@okta/okta-auth-js';
import { Routes } from '../navigation/AppRouter';

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop : '200px',
            '& *': {
                display: 'flex',
                flexDirection: 'column'
            }
        },
        content: {
            width: '30%',
            maxWidth: '400px',
            minWidth: '300px',
            paddingBottom: '40px'
        },
        circleLogo: {
            height: '100px',
            width: '100px',
            backgroundColor: '#000',
            borderRadius: '50%',
            padding: '10px',
            display: 'inline-block',
            margin: '20px auto'
        }
    });
});

export const LoginPage = () => {

    console.log(process.env.REACT_APP_VERSION);

    const REACT_APP_BRAND_NAME = process.env.REACT_APP_BRAND_NAME ?? '';
    const REACT_APP_NAME = process.env.REACT_APP_NAME ?? '';

    const classes = useStyles();

    const {oktaAuth, authState} = useOktaAuth();
    const opts: SigninWithRedirectOptions = {originalUri: Routes.stockPoolPage};
    const login = async () => oktaAuth.signInWithRedirect(opts);
    if (!authState) {
        return (
            <UILoader/>
        );
    }
    if (!authState.isAuthenticated) {
        return (
            <div className={classes.root}>
                <Paper elevation={3} variant="outlined" className={classes.content}>
                    <img src="/logo-algo-white.png" className={classes.circleLogo} alt={REACT_APP_BRAND_NAME}/>
                    <h2>{REACT_APP_BRAND_NAME} - {REACT_APP_NAME}</h2>
                    <UIButton
                        text={strings.login.actions.signIn}
                        onClick={login}
                        color="primary"
                        style={{width: '50%', margin: 'auto'}}
                    />
                </Paper>
            </div>
        );
    }

    return (<Redirect to={Routes.stockPoolPage}/>);

};

