import React, { useContext, useState, useEffect } from 'react';
import { HeadCell } from '../../../../graphics/tables/data/HeadCell';
import { TableRowData } from '../../../../graphics/tables/data/TableRowData';
import { DynamicDatatable, EditOptionType } from '../../../../graphics/tables/DynamicDatatable';
import { Order } from '../../../../graphics/tables/operations/sort';
import { strings } from '../../i18n/strings';
import { Page } from '../../presenters/pages/index';
import { AppContext, AppContextSpec, useAsyncEffect } from '../../App';
import { EditForm as DefaultPPSEditForm, EditFormModalOptions } from '../../domain/forms/DefaultPPSEditForm';
import { DropDownValue } from '../../domain/components/AsyncAutocompleteSelect';
import { useOktaAuth } from '../../../../okta-react';
import { DefaultPPSTableStructure } from "../../domain/indexes/DefaultPPSIndexes";
import {
    deleteDefaultPPS,
    getDefaultPPSColumnInformation,
    massEditDefaultPPS,
    searchDefaultPPS
} from "../../domain/repository/DefaultPPSRepository";
import { massEditDefaultPPSSelectionByCriteria } from "../../domain/repository/TechnicalRepository";
import {Alert} from "@material-ui/lab";

// Interfaces & types
type Record = DefaultPPSTableStructure.RecordOf<DefaultPPSTableStructure.DefaultPPS>;
interface RecordRepresentation extends Record, TableRowData { };
export interface RecordData { record: RecordRepresentation, type: EditMode }

const tableFields: Array<string> = [
    'id',
    'clientCountry',
    'stockLocation',
    'defaultPickPackShipCode'
];

// Datatable configuration
export enum EditMode {
    Add,
    Edit,
    Duplicate
}

// Wordings
const wordingDefaultPPSPage = strings.page.defaultPPS;
const wordingDefaultPPSAuditPage = strings.page.defaultPPSAudit;

export const DefaultPPSPage= () => {

    const DEFAULT_PAGINATION_LIMIT : number = (process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT) ? parseInt(process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT) : 20;
    const [headCellsConfig, setHeadCellsConfig] = useState<HeadCell<RecordRepresentation>[]>([]);
    const [rowData, setRowData] = useState<RecordRepresentation[]>([]);
    const [tableData, setTableData] = useState<DefaultPPSTableStructure.ResponseOf<DefaultPPSTableStructure.DefaultPPS>>();
    const [tableDataDefinition, setTableDataDefinition] = useState<DefaultPPSTableStructure.ResponseOfColumn<DefaultPPSTableStructure.DefaultPPS>>();
    const { authState } = useOktaAuth();
    const appContext = useContext<AppContextSpec>(AppContext);

    const wordingsDatatable = strings.datatable;
    const [limit, setLimit] = useState(DEFAULT_PAGINATION_LIMIT);
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>(tableFields[0]);
    const [updateSignal, setUpdateSignal] = useState(false);
    const [recordToEdit, setRecordToEdit] = useState<RecordData>();
    const [editModalOpened, setEditModalOpened] = useState(false);
    const [message, setMessage] = useState<string>('');

    const currentPage = wordingDefaultPPSAuditPage.title;

    const headCells: HeadCell<RecordRepresentation>[] = tableFields.map((field) => ({
        id: field,
        label: field,
        displayName: field,
        type: "",
        required: true,
        information: "",
        position: 1,
        visible: true,
        editable: true,
        massEditable: false,
        reference: "",
        referenceColumnLabel: "",
        disablePadding: false,
        searchValue: "",
    }));

    //Get HeadCells to display tab names
    useEffect(() => {
        (async () => {
            try {
                const headCellConfigValues: DefaultPPSTableStructure.ResponseOfColumn<DefaultPPSTableStructure.DefaultPPS> = await getDefaultPPSColumnInformation<DefaultPPSTableStructure.DefaultPPS>(authState!);
                headCells.forEach((cell) => {
                    const config = headCellConfigValues[cell.id];
                    cell.id = config.name;
                    cell.label = config.name;
                    cell.type = config.type;
                    cell.required = config.required;
                    cell.information = config.information;
                    cell.position = config.position;
                    cell.visible = config.visible;
                    cell.editable = config.editable;
                    cell.reference = config.reference;
                    cell.massEditable = config.massEditable;
                    cell.displayName = (config.displayName.length > 0) ? String(config.displayName) : String(config.name);
                });
                setHeadCellsConfig(headCells);
                setTableDataDefinition(headCellConfigValues);
            } catch (error: any) {
                console.log("ERROR: ", error);
            }
        })();
        // eslint-disable-next-line
    }, []);

    const getAndRefreshRecords = (newList ?: HeadCell<RecordRepresentation>[]) => {

        let headCellsConfigForGetAndRefresh = (newList) ? newList : headCellsConfig;

        let formattedSearchValues: DefaultPPSTableStructure.FormattedSearchValue[] = headCellsConfigForGetAndRefresh
            .filter(cell => cell.label && cell.searchValue)
            .map(cell  => {
                return {
                    key: cell.label,
                    value : cell.searchValue
                };
            });

        const updateState = (defaultPPSValues: DefaultPPSTableStructure.ResponseOf<DefaultPPSTableStructure.DefaultPPS>) => {
            setTableData(defaultPPSValues);
            setRowData(defaultPPSValues.values);

            if (page !== defaultPPSValues.pagination.page) {
                setPage(defaultPPSValues.pagination.page);
            }
        }

        (async () => {
            try {
                const defaultPPSValues: DefaultPPSTableStructure.ResponseOf<DefaultPPSTableStructure.DefaultPPS> =
                    await searchDefaultPPS<Record>(authState!, formattedSearchValues, page, limit, order, orderBy);

                if (page !== defaultPPSValues.pagination.page) {
                    setPage(defaultPPSValues.pagination.page);
                }
                updateState(defaultPPSValues);
            } catch (error: any) {
                console.log("ERROR: ", error);
            }
        })();
    }

    useAsyncEffect(async () => {
            await getAndRefreshRecords();
        }, [page, limit, order, orderBy, updateSignal],
    );

    const handlePaginationChange = (page: number, limit: number) => {
        setPage(page);
        setLimit(limit);
    };

    const handleSortChange = async (order: Order, orderBy: string) => {
        setOrder(order);
        setOrderBy(orderBy);
    };

    const handleMultiCellsSearch = ( newList: HeadCell<RecordRepresentation>[]) => {
        setPage(1);
        headCellsConfig.forEach((cell : HeadCell<RecordRepresentation>) => {
            cell.searchValue = newList.filter(newListCell => newListCell.id === cell.id)[0].searchValue;
        });

        getAndRefreshRecords(headCellsConfig);
    };

    const onChangeVisibilityColumnDialog = (newList: HeadCell<RecordRepresentation>[]) => {
        const newHeadCells = newList.slice();
        setHeadCellsConfig(newHeadCells);
    };

    const onReorderDialogueAction = (newList: HeadCell<RecordRepresentation>[]) => {
        const newHeadCells = newList.slice();
        setHeadCellsConfig(newHeadCells);
    };

    const fillAndOpenEditForm = (record?: RecordData) => {
        setRecordToEdit(record);
        setEditModalOpened(true);
    };

    const computeAddOption = () => {
        return {
            buttonLabel: wordingDefaultPPSPage.datatable.action.add.buttonLabel,
            onClick: fillAndOpenEditForm,
        }
    };

    /**
     * Computed options
     */
    const handleEditOptionClick = (option: EditOptionType, record: RecordRepresentation) => {
        switch (option) {
            case EditOptionType.Edit:
                fillAndOpenEditForm({ record: record, type: EditMode.Edit });
                break;
            case EditOptionType.Duplicate:
                fillAndOpenEditForm({ record: record, type: EditMode.Duplicate });
                break;
            case EditOptionType.Delete:
                appContext.prompt(
                    wordingDefaultPPSPage.datatable.action.remove.confirmationPromptMessage,
                    (isConfirmed) => {
                        if (isConfirmed) {
                            appContext.displayLoader(true);
                            deleteDefaultPPS(authState!, record.id)
                                .then(() => {
                                    getAndRefreshRecords();
                                    appContext.notify({ message: wordingDefaultPPSPage.datatable.action.remove.confirmedFeedbackMessage });
                                })
                                .catch(({ message }) => message && appContext.notifyError(message))
                                .finally(() => appContext.displayLoader(false));
                        }
                    },
                    { confirmButtonColor: 'secondary' }
                );
                break;
        }
    };

    const computeEditOptions = () => {
        return [
            { id: EditOptionType.Edit, name: wordingDefaultPPSPage.datatable.action.edit.menuItemLabel, onClick: handleEditOptionClick },
            {
                id: EditOptionType.Duplicate,
                name: wordingDefaultPPSPage.datatable.action.duplicate.menuItemLabel,
                onClick: handleEditOptionClick,
            },
            { id: EditOptionType.Delete, name: wordingDefaultPPSPage.datatable.action.remove.menuItemLabel, onClick: handleEditOptionClick },
        ];
    };
    /**
     * Modal row add
     */
    const handleEditModalClose = () => {
        setEditModalOpened(false);
        setRecordToEdit(undefined);
    }

    const handleEditModalOk = (shouldReset: boolean) => {
        const feedbackWordings = recordToEdit ? (recordToEdit.type === EditMode.Edit ? wordingDefaultPPSPage.datatable.action.edit : wordingDefaultPPSPage.datatable.action.duplicate) : wordingDefaultPPSPage.datatable.action.add;
        !shouldReset && setEditModalOpened(false);

        (async () => {
            try {
                getAndRefreshRecords();
                appContext.notifySuccess(feedbackWordings.confirmationFeedbackMessage);
            } catch (error: any) {
                appContext.notifyError(error);
            }
        })();
    };


    const computeEditModalOptions = (): EditFormModalOptions => {

        const modalWordings = recordToEdit
            ? (recordToEdit?.type === EditMode.Duplicate ? wordingDefaultPPSPage.datatable.action.duplicate.modal : wordingDefaultPPSPage.datatable.action.edit.modal)
            : wordingDefaultPPSPage.datatable.action.add.modal;

        return {
            title: modalWordings.title,
            applyLabel: modalWordings.applyButtonLabel,
            cancelLabel: modalWordings.cancelButtonLabel,
            applyAndResetLabel: recordToEdit ? (recordToEdit.type === EditMode.Duplicate ? wordingDefaultPPSPage.datatable.action.duplicate.modal.applyAndResetLabel : undefined) : wordingDefaultPPSPage.datatable.action.add.modal.applyAndResetLabel,
            open: editModalOpened,
            onClose: handleEditModalClose,
            onSuccess: async (shouldReset: boolean) => {
                await handleEditModalOk(shouldReset);
            }
        };
    };

    /**
     * Mass edit
     */
    const notifyForUpdateData= () => {
        setUpdateSignal(!updateSignal);
    }

    const handleMassEdit = async (selectedIds: string[], fieldToEdit: string, newValueToApply: string, newValuesToApply: string[]) => {
        try  {
            await massEditDefaultPPS(authState!, {selectedIds, fieldToEdit, newValueToApply, newValuesToApply}, setMessage);
            notifyForUpdateData();
        } catch (error: any) {
            appContext.notifyError(error.message);
        }

    }

    const handleSelectAllClick =  async () => {

        let formattedSearchValues: DefaultPPSTableStructure.FormattedSearchValue[] = headCellsConfig
            .filter(cell => cell.label && cell.searchValue)
            .map(cell => {
                return {
                    key: cell.label,
                    value: cell.searchValue
                };
            });

        // Search by default all threshold by sku
        let selectedRows:number[] = [];

        try {
            selectedRows =  await massEditDefaultPPSSelectionByCriteria(authState!, formattedSearchValues, order, orderBy);
            return selectedRows;
        } catch (error: any) {
            appContext.notifyError("ERROR : Something wrong happen while trying to get all row selection");
        }
    }

    const setOptionsInDropdown = (values: string[] | number[]): DropDownValue[] => {
        const options: DropDownValue[] = [];
        values.forEach((value: string | number) => {
            options.push({key: (value).toString(), value: (value).toString()});
        });
        return options;
    }

    return (
        <Page title={wordingDefaultPPSPage.title} descriptionLine1={wordingDefaultPPSPage.description.line1} descriptionLine2={wordingDefaultPPSPage.description.line2}>
            {(
                <>
                    {message && (
                        <Alert severity="warning" onClose={() => {setMessage('')}}>{message}</Alert>
                    )}
                    <DynamicDatatable
                        stickyHeader={true}
                        headCells={headCellsConfig}
                        data={rowData}
                        initialOrderBy={tableFields[0]}
                        showPadding={false}
                        editOptions={computeEditOptions()}
                        onChangeVisibilityColumnDialog={onChangeVisibilityColumnDialog}
                        onReorderDialogueAction={onReorderDialogueAction}
                        allowReordering={{    modal: {
                                title: wordingsDatatable.reorderColumns.modal.title,
                                applyButtonLabel: wordingsDatatable.reorderColumns.modal.applyButtonLabel,
                            },
                            triggerButtonLabel: wordingsDatatable.reorderColumns.modal.title}}
                        allowChangeVisibilityColumn={{    modal: {
                                title: wordingsDatatable.changeVisibilityColumns.modal.title,
                                applyButtonLabel: wordingsDatatable.changeVisibilityColumns.modal.applyButtonLabel,
                            },
                            triggerButtonLabel: wordingsDatatable.changeVisibilityColumns.modal.title}}
                        allowAdding={computeAddOption()}
                        paginationOptions={(() => ({
                            page,
                            limit,
                            order,
                            orderBy,
                            total: tableData?.pagination?.total || 0,
                            rowsPerPageValues: [
                                { nb: DEFAULT_PAGINATION_LIMIT / 2, isDefault: true },
                                { nb: DEFAULT_PAGINATION_LIMIT, isDefault: true },
                                { nb: DEFAULT_PAGINATION_LIMIT * 2, isDefault: false },
                                { nb: DEFAULT_PAGINATION_LIMIT * 4, isDefault: false },
                            ],
                            meetNbRowsPerPage: false,
                            rowsPerPageLabel: wordingsDatatable.pagination.rowsPerPage,
                            onPaginationChange: handlePaginationChange,
                            onHandleSortChange: handleSortChange,
                            onMultiCellsSearch: handleMultiCellsSearch
                        }))()}
                        massEditOptions={{
                            onMassEdit: handleMassEdit,
                            onSelectAllClick: handleSelectAllClick,
                            toolbar: {
                                action : {
                                    massEdit: wordingsDatatable.massEdit.toolbar.action.massEdit,
                                    edit: wordingsDatatable.massEdit.toolbar.action.edit,
                                    closeMassEdit: wordingsDatatable.massEdit.toolbar.action.closeMassEdit
                                }
                            },
                            dialog: {
                                title: wordingsDatatable.massEdit.dialog.title,
                                warningMessage:  wordingsDatatable.massEdit.dialog.warningMessage,
                                confirmMessage: wordingsDatatable.massEdit.dialog.confirmMessage,
                                cancelButtonLabel: wordingsDatatable.massEdit.dialog.cancelButtonLabel,
                                applyButtonLabel: wordingsDatatable.massEdit.dialog.applyButtonLabel,
                                cancelConfirmButtonLabel: wordingsDatatable.massEdit.dialog.cancelConfirmButtonLabel,
                                applyConfirmButtonLabel: wordingsDatatable.massEdit.dialog.applyConfirmButtonLabel
                            },
                            form : {
                                field: {
                                    label: {
                                        selectProperty: wordingsDatatable.massEdit.form.field.label.selectProperty
                                    }
                                }
                            }
                        }}
                        showHistory={[{
                            showHistoryButton: true,
                            urlToViewHistory: '/default-pps-audit',
                            linkToParentPage: false,
                            icon: 'HistoryIcon'
                        }]}
                        pageTitles={[currentPage]}
                    />
                </>
            )}
            {tableDataDefinition && (
                <DefaultPPSEditForm
                    key={recordToEdit ? recordToEdit.type : EditMode.Add}
                    record={recordToEdit ? recordToEdit : undefined}
                    constraints={tableDataDefinition}
                    modalOptions={computeEditModalOptions()}
                />
            )}
        </Page>
    );
};
