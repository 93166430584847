import React, {useState} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {HeadCell} from "../../../../graphics/tables/data/HeadCell";
import {TableRowData} from "../../../../graphics/tables/data/TableRowData";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        ul: {
            listStyleType: 'none',
            padding: '0px'
        },
        li: {
            cursor: 'pointer',
            margin: '10px'
        }
    })
});

export interface DragAndDropProps<T extends TableRowData>{
    children: HeadCell<T>[],
    setList: (headCellConfig: HeadCell<T>[]) => void;
}

export const DragAndDrop = <T extends TableRowData>({
    children,
    setList
}: DragAndDropProps<T>) => {

    const classes = useStyles();
    const [dragged, setDragged] = useState<HTMLElement>();
    const list = children.filter((headCell) => headCell.id !== "id");

    const isBefore = (dragged: HTMLElement, elOver: HTMLElement) => {
        let cur;
        if (elOver.parentNode === dragged.parentNode) {
            for (cur = dragged.previousSibling; cur && cur?.nodeType !== 9; cur = cur?.previousSibling) {
                if (cur === elOver) {
                    return true;
                }
            }
        }
        return false;
    }

    const dragStart = (e: any) => {
        e.dataTransfer.effectAllowed = "copyMove";
        e.dataTransfer.setData("text/plain", null);
        setDragged(e.target);
    }

    const dragOver = (e: any) => {
        if (dragged !== undefined && dragged !== null) {
            if (isBefore(dragged, e.target)) {
                e.target?.parentNode?.insertBefore(dragged, e.target);
                let arr:any = [];
                Array.prototype.slice.call(e.target?.parentNode?.children).forEach((el:any) => {
                    arr.push({'id': el.id});
                });

                let result:HeadCell<T>[] = [];
                arr.forEach((id:any) => {
                    list.forEach((el:any) => {
                        if(el.id === id) {
                            result.push(el);
                        }
                    });
                });

                if(result.length + 1 === children.length || result.length === children.length) {
                    setList(result);
                }
            } else {
                e.target?.parentNode?.insertBefore(dragged, e.target.nextSibling);
                let arr:any = [];
                Array.prototype.slice.call(e.target?.parentNode?.children).forEach((el:any) => {
                    arr.push({'id': el.id});
                });

                let result:HeadCell<T>[] = [];
                arr.forEach((id:any) => {
                    list.forEach((el:any) => {
                        if(el.id === id.id) {
                            result.push(el);
                        }
                    });
                });

                if(result.length + 1 === children.length || result.length === children.length) {
                    setList(result);
                }
            }
        }
    }

    const dragEnd = () => {
        setDragged(undefined);
    }

    return (
        <ul className={classes.ul}>
            {list.map((el: HeadCell<T>) => (
                <li
                    id={el.id}
                    className={classes.li}
                    key={el.id}
                    draggable="true"
                    onDragStart={dragStart}
                    onDragOver={dragOver}
                    onDragEnd={dragEnd}
                >
                    = {el.displayName}
                </li>
            ))}
        </ul>
    )

}