import { Order } from "../../../../graphics/tables/operations/sort";
import { ApiDatasource } from "../../adapters/io/datasources/ApiDatasource";
import { unwrap } from "../../adapters/io/network/HttpResponse";
import { formatText } from "../../adapters/io/network/utils";
import { ApiDatasourceSpec } from "../../core/datasource";
import { AuthState } from '@okta/okta-auth-js';
import { buildHeader } from './utils/Utils';
import {ShipmentMethodTableStructure} from "../indexes/ShipmentMethodIndexes";

export const getShipmentMethods = <T>(
    authState: AuthState,
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<ShipmentMethodTableStructure.ResponseOf<T>> => {

    const params = new Map();

    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .getData<ShipmentMethodTableStructure.ResponseOf<T>>(uriFormatter(EndpointShipmentMethod.endPoint.concat(EndpointShipmentMethod.getShipmentMethod), {}), buildHeader(authState), params)
        .then(unwrap);
};

export const EndpointShipmentMethod = {
    endPoint: '/end-point',
    getShipmentMethod: '/shipment-method'
};