import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Page } from '../../presenters/pages/index';
import { strings } from '../../i18n/strings';
import { SubzoneTableStructure } from '../../domain/indexes/SubzoneLeadTimeIndexes';
import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import { SubzoneTable } from '../tables/SubzoneTable';
import { SubzoneCountryTable } from "../tables/SubzoneCountryTable";
import { SubzoneStateTable } from "../tables/SubzoneStateTable";
import { useOktaAuth } from '../../../../okta-react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',

            alignItems: 'center',
            '& > *': {
                marginBottom: '-0.5em'
            }
        }, 
        globalSubzone: {
            textAlign: 'left',
            marginBottom: '2em'
        },
        panel: {
            width: '100%'
        }
    })
);

type SubzoneBar = SubzoneTableStructure.SubzoneBar;
const subzoneBar: SubzoneBar = {
    'barItems': ["SUBZONE", "COUNTRIES BY SUBZONE", "STATES BY SUBZONE"]
}

const wordingSubzonePage = strings.page.subzone;

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    className: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, className, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            className={className}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other} >

        {value === index && (
            <Box p={3}>
                <Typography>{children}</Typography>
            </Box>
        )}
    
      </div>
    );
}

function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const SubzonePage = () => {

    const classes = useStyles();
    const [activeTab, setActiveTab] = React.useState<number>(0);

    const handleTabChange = (event: React.ChangeEvent<{}>, indexTab: number) => {
        setActiveTab(indexTab);
    };

    return (
        <Page
            title={wordingSubzonePage.title}
            descriptionLine1={wordingSubzonePage.description.line1}
            descriptionLine2={
            <div>
                <div>{wordingSubzonePage.description.line2}</div>
                <div>{wordingSubzonePage.description.line3}</div>
            </div>}>

            <div className={classes.root}>
                <AppBar position="static">
                    <Tabs value={activeTab} onChange={handleTabChange} aria-label="simple tabs example"
                          variant="fullWidth">
                        {subzoneBar.barItems.map((item: string, idx: number) => {
                            return <Tab label={item} key={idx} {...a11yProps(idx)}/>
                        })}
                    </Tabs>
                </AppBar>
                <TabPanel value={activeTab} index={0} className={classes.panel}>
                    <SubzoneTable/>
                </TabPanel>
                <TabPanel value={activeTab} index={1} className={classes.panel}>
                    <SubzoneCountryTable/>
                </TabPanel>
                <TabPanel value={activeTab} index={2} className={classes.panel}>
                    <SubzoneStateTable/>
                </TabPanel>
            </div>
        </Page>
    )
};
