import { ApiDatasource } from '../../adapters/io/datasources/ApiDatasource';
import { unwrap } from '../../adapters/io/network/HttpResponse';
import { formatText } from '../../adapters/io/network/utils';
import { ApiDatasourceSpec } from '../../core/datasource';
import { StockPoolAuditTableStructure } from '../indexes/StockPoolAuditIndexes';
import { Order } from "../../../../graphics/tables/operations/sort";
import { AuthState } from '@okta/okta-auth-js';
import { buildHeader } from './utils/Utils';

export const searchStockPoolAudit = <T>(
    authState: AuthState,
    search: StockPoolAuditTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StockPoolAuditTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<StockPoolAuditTableStructure.ResponseOf<T>, StockPoolAuditTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointStockPoolAudit.endPoint.concat(EndpointStockPoolAudit.searchStockPoolAudit), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const getStockPoolAuditColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StockPoolAuditTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<StockPoolAuditTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointStockPoolAudit.endPoint.concat(EndpointStockPoolAudit.getStockPoolAuditColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const EndpointStockPoolAudit = {
    endPoint: '/end-point',
    getStockPoolAudit: '/stock-pool/audit',
    searchStockPoolAudit: '/stock-pool/audit/search',
    getStockPoolAuditColumnInformation : '/stock-pool/audit/column-information'
};