import { Order } from "../../../../graphics/tables/operations/sort";
import { ApiDatasource } from "../../adapters/io/datasources/ApiDatasource";
import { unwrap } from "../../adapters/io/network/HttpResponse";
import { formatText } from "../../adapters/io/network/utils";
import { ApiDatasourceSpec } from "../../core/datasource";
import { AuthState } from '@okta/okta-auth-js';
import { buildHeader } from './utils/Utils';
import {QuotaSkuAuditTableStructure} from "../indexes/QuotaSkuAuditIndexes";

export const searchQuotaSkuAudit = <T>(
    authState: AuthState,
    search: QuotaSkuAuditTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<QuotaSkuAuditTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<QuotaSkuAuditTableStructure.ResponseOf<T>, QuotaSkuAuditTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointQuotaSkuAudit.endPoint.concat(EndpointQuotaSkuAudit.searchQuotaSkuAudit), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const getQuotaSkuAuditColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<QuotaSkuAuditTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<QuotaSkuAuditTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointQuotaSkuAudit.endPoint.concat(EndpointQuotaSkuAudit.getQuotaSkuAuditColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const EndpointQuotaSkuAudit = {
    endPoint: '/end-point/quota',
    searchQuotaSkuAudit: '/audit-quota-sku/search',
    getQuotaSkuAuditColumnInformation: '/audit-quota-sku'
};