import React, {useState} from 'react';

import {UIDialog} from "../../../ui/feedback/UIDialog";
import {
    createStyles,
    FormControl,
    FormControlLabel,
    FormGroup,
    List,
    MenuItem,
    Switch,
    Theme
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {HeadCell} from "../data/HeadCell";
import {TableRowData} from "../data/TableRowData";
import InputLabel from "@material-ui/core/InputLabel";
import {UIButton} from "../../../ui/inputs/UIButton";
import TextField from "@material-ui/core/TextField";
import {AsyncAutocompleteSelectComponent} from "app/src/domain/components/AsyncAutocompleteSelect";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import {useTheme} from "@material-ui/core/styles";
import DatePicker, { DateObject } from "react-multi-date-picker";
import Toolbar from "react-multi-date-picker/plugins/toolbar"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 500,
            minHeight: "auto"
        },
        row: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "10px",
            alignItems: "center"
        },
        flexGrowPriorityLow: {
            flexGrow: 1,
            width: "100%"
        },
        flexGrowPriorityHigh: {
            flexGrow: 2,
            width: "100%"
        },
        field: {
            marginTop: '20px', width: "100%"
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
            width: "100%"
        },
        timePicker: {
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(0),
            marginTop: '20px', width: "100%"
        },
        chip: {
            margin: 2,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        calendar: {
            marginTop: '20px',
        }
    }),
);


export type MassEditOption = {
    onMassEdit: (selectedIds: string[], fieldToEdit: string, newValueToApply: string, newValuesToApply: string[]) => void;
    onSelectAllClick: () => Promise<number[] | undefined>;
    toolbar: {
        action : {
            massEdit: string;
            edit: string;
            closeMassEdit: string;
        }
    },
    dialog: {
        title: string;
        warningMessage: string;
        confirmMessage: string;
        cancelButtonLabel: string;
        applyButtonLabel: string;
        cancelConfirmButtonLabel: string;
        applyConfirmButtonLabel: string;
    },
    form: {
        field: {
            label: {
                selectProperty: string;
            }
        },

    }
};

export interface MassEditBehavior {
    isEditing: boolean
}

export interface MassEditProps <T> {
    closeDialog: () => void;
    objectDefinition:  HeadCell<T>[];
    massEditOptions: MassEditOption;
    selected: string[];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const eligibleDays = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY'
];

export const MassEditDialog = <T extends TableRowData>({objectDefinition, closeDialog, massEditOptions, selected} : MassEditProps<T>) => {

    const classes = useStyles();
    function getStyles(name: string, personName: string[], theme: Theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    };

    const [selectedProp, setSelectedProp] = React.useState('');
    const [newValueToApply, setNewValueToApply] = React.useState('');
    const [newValuesToApply, setNewValuesToApply] = React.useState<string[]>([]);
    const [showMassEditDialog, setShowMassEditDialog] = React.useState(true);
    const [showMassEditConfirmationDialog, setShowMassEditConfirmationDialog] = React.useState(false);
    const [daysOfWeek, setDaysOfWeek] = React.useState<string[]>([]);
    const [daysOffYear, setDaysOffYear] = useState<DateObject[]>([]);
    const theme = useTheme();

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setNewValueToApply("");
        setNewValuesToApply([]);
        setDaysOfWeek([]);
        setDaysOffYear([]);
        setSelectedProp(event.target.value as string);
    };

    const handleInput = (event: React.ChangeEvent<{ value: unknown }>) => {
        setNewValueToApply(event.target.value as string);
    };

    const showMassEditConfirmation = () => {
        setShowMassEditConfirmationDialog(true);
    };

    const closeMassEditAndApply = () => {
        massEditOptions.onMassEdit(selected, selectedProp, newValueToApply, newValuesToApply);
        setShowMassEditDialog(false);
        setShowMassEditConfirmationDialog(false);
        closeDialog();
    };

    const changeCheckedState = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewValueToApply( (event.target.checked) ? 'true' : 'false' );
    }

    const isChecked = () => {
        return (newValueToApply === 'true') ? true : false;
    }

    const handleChangeDays = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDaysOfWeek(event.target.value as string[]);
        setNewValuesToApply(event.target.value as string[]);
    };

    const isMassEditButtonApplyDisabled = () => {
        if (selectedProp === "daysOffYear" || selectedProp === "daysOffWeek") return false;
        return (newValueToApply.length < 1);
    }

    const setValueDayOffYear = (day: DateObject[]) => {
        setDaysOffYear(day);
        setNewValueToApply(day.toLocaleString());
    }

    return (

        <UIDialog open={showMassEditDialog} onClose={closeDialog} title={massEditOptions.dialog.title} >
            {!showMassEditConfirmationDialog && (
                <FormControl className={classes.formControl}>
                    <div className={classes.row}>
                        <div className={classes.flexGrowPriorityLow}>
                            <InputLabel shrink id="propertyToMassEditLabel">
                                { !selectedProp && (massEditOptions.form.field.label.selectProperty)}
                            </InputLabel>
                            <Select
                                value={selectedProp}
                                labelId="propertyToMassEditLabel"
                                id="propertyToMassEdit"
                                onChange={handleChange}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >

                                {objectDefinition.filter(definition => definition.massEditable).map(({id, displayName, type}) => (
                                    <MenuItem value={id}>{displayName}</MenuItem>
                                ))}
                            </Select>
                        </div>
                        {selectedProp && (
                            <div className={classes.flexGrowPriorityHigh}>
                                { objectDefinition.filter(definition => definition.id === selectedProp && (definition.type === 'string') && (definition.reference === '')).map(({type}) => (
                                    <TextField id="propertyToMassEditValue" value={newValueToApply} type="text" onChange={handleInput} className={classes.field} />
                                ))}

                                { objectDefinition.filter(definition => definition.id === selectedProp && (definition.type === 'number')&& (definition.reference === '')).map(({type}) => (
                                    <TextField id="propertyToMassEditValue" value={newValueToApply} type="text" onChange={handleInput} className={classes.field} />
                                ))}

                                { objectDefinition.filter(definition => definition.id === selectedProp && (definition.type === 'boolean')).map(({type, id , displayName}) => (
                                    <FormGroup>
                                        <FormControlLabel
                                            name={String(id)}
                                            style={{margin: "20px 0 0 65px"}}
                                            control={<Switch checked={isChecked()}  onChange={(e) => {changeCheckedState(e)}} id={`edit-${id}`}/>}
                                            label={""}
                                            key={id}
                                            value={isChecked()}
                                        />
                                    </FormGroup>
                                ))}

                                { objectDefinition.filter(definition => definition.id === selectedProp && (definition.reference !== '')).map((
                                    {type, id , displayName, reference, information}) => (
                                    <AsyncAutocompleteSelectComponent
                                        id={`edit-${id}`}
                                        key={id}
                                        autocompleteContainerStyle={{
                                            'flexGrow': 1
                                        }}
                                        required={true}
                                        reference={reference}
                                        information={information}
                                        label={displayName}
                                        onSelect={handleInput}
                                    />
                                ))}

                                { objectDefinition.filter(definition => definition.id === selectedProp && (definition.type === 'time')).map(({id }) => (
                                    <TextField
                                        id={`edit-${id}`}
                                        label={""}
                                        type="time"
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                        defaultValue={"00:00"}
                                        className={classes.timePicker}
                                        onChange={handleInput}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300,
                                        }}
                                    />
                                ))}

                                { objectDefinition.filter(definition => definition.id === selectedProp && (definition.type === 'day')).map(({id }) => (
                                    <div>
                                        <InputLabel id={`label-edit-${id}`}  >{""}</InputLabel>
                                        <Select
                                            labelId={`label-edit-${id}`}
                                            id={`edit-${id}`}
                                            multiple
                                            fullWidth
                                            value={daysOfWeek}
                                            onChange={handleChangeDays}
                                            input={<Input id={`edit-${id}`} fullWidth />}
                                            renderValue={(selected) => (
                                                <div className={classes.chips}>
                                                    {(selected as string[]).map((value) => (
                                                        <Chip key={value} label={value} className={classes.chip} />
                                                    ))}
                                                </div>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {eligibleDays.map((day) => (
                                                <MenuItem key={day} value={day} style={getStyles(day, daysOfWeek, theme)}>
                                                    {day}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                ))}

                                { objectDefinition.filter(definition => definition.id === selectedProp && (definition.type === 'date')).map(({id }) => (
                                    <DatePicker
                                        placeholder={""}
                                        id={`edit-${id}`}
                                        sort={true}
                                        className="bg-dark"
                                        value={daysOffYear ? daysOffYear: []}
                                        onChange={(day: DateObject[]) => {
                                            setValueDayOffYear(day);
                                        }}
                                        calendarPosition={"left-center"}
                                        containerStyle={{ width: "100%", height: "300px"}}
                                        multiple={true}
                                        disableYearPicker={true}
                                        hideYear={true}
                                        format="DD/MM"
                                        style={{
                                            width: "100%",
                                            textAlign: "left",
                                            fontSize: "medium",
                                            padding: "6px 6px 6px 12px",
                                            boxSizing: "border-box",
                                            height: "40px",
                                            marginTop: "20px",
                                            direction: "rtl",
                                            color: "rgba(0, 0, 0, 0.87)"
                                        }}
                                        plugins={[
                                            <Toolbar
                                                position="bottom"
                                            />
                                        ]}
                                    />

                                ))}
                            </div>
                        )}
                    </div>
                    <div >
                        <UIButton
                            disabled={isMassEditButtonApplyDisabled()}
                            text={massEditOptions.dialog.applyButtonLabel}
                            onClick={showMassEditConfirmation}
                            style={{ float: 'right', marginTop: '50px' }}
                        />

                        <UIButton
                            text={massEditOptions.dialog.cancelButtonLabel}
                            onClick={closeDialog}
                            style={{ float: 'right', margin: '50px 20px 0px 20px' }}
                        />
                    </div>
                </FormControl>
            )}

            {showMassEditConfirmationDialog && (
                <FormControl className={classes.formControl}>
                    <div>

                        {selected.length} {massEditOptions.dialog.warningMessage}<br/>
                        {massEditOptions.dialog.confirmMessage}

                        <UIButton
                            text={massEditOptions.dialog.applyConfirmButtonLabel}
                            onClick={closeMassEditAndApply}
                            style={{ float: 'right', marginTop: '50px' }}
                        />

                        <UIButton
                            text={massEditOptions.dialog.cancelConfirmButtonLabel}
                            onClick={closeDialog}
                            style={{ float: 'right', margin: '50px 20px 0px 20px' }}
                        />
                    </div>
                </FormControl>
            )}
        </UIDialog>
    );
};