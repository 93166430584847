import { createTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

export const MainTheme = createTheme({

  palette: {
    primary: {
      main: grey[900],
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        color: grey[900],
        '&$checked': {
          color: grey[900],
        },
      },
    },
    MuiTableRow: {
      root: {   backgroundColor: '#fff',
        "&$selected, &$selected:hover": { backgroundColor: grey[200] }
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: '#fff'
      }
    },
    MuiDialog: {
      paper: {
        overflowY: 'unset'
      }
    }
  }
})

