import { AuthProviderConfigSpec } from "../core/config";

export interface ApiConfigSpec extends AuthProviderConfigSpec {
    appName: string;
    pushNotificationTTL: number;
}

export const apiConfig: ApiConfigSpec = {
    appName: process.env.REACT_APP_NAME ?? 'ALGO',
    pushNotificationTTL: 3000,
};
