import React, {useState} from 'react';
import clsx from 'clsx';
import {createStyles, lighten, makeStyles, Theme} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import {UIButton} from '../../ui/inputs/UIButton';
import {ReorderingWordingsType} from './dialog/ReorderDialog';
import {ChangeVisibilityColumnWordingsType} from './dialog/ChangeVisibilityColumnDialog';
import {MassEditOption} from "./dialog/MassEditDialog";
import HistoryIcon from '@material-ui/icons/History';
import {useHistory, useLocation} from 'react-router-dom';
import {ShowHistoryOption} from "./DynamicDatatable";
import {Badge, Button, DialogContentText} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import CircularProgress from '@material-ui/core/CircularProgress';
import {UIDialog} from "../../ui/feedback/UIDialog";

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.primary.main,
                    backgroundColor: lighten(theme.palette.primary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        actionContainer: {
            flex: '1 1 100%',
            alignItems: 'flex-start',
            display: 'flex'
        },
        actionContainerText: {
            textAlign: 'left',
        },
        actionContainerBehavior: {
            marginLeft: 'auto',
        },
        algoIcon: {
            margin: '0 8px 0 8px',
        },
        element: {
            backgroundColor: '#8a8a8a8a',
        },
        auditPage: {
            backgroundColor: '#000000',
        },
        iconButton: {
            '&:hover': {
                backgroundColor: 'transparent',
            }
        },
        iconButtonRoot: {},
        rotatedIcon: {
            transform: 'rotate(180deg)',
            marginTop: '-4px'
        },
    })
);

export enum ToolbarAction {
    Delete,
    Add,
    Export,
    Import,
    ShowHistory,
    ChangeVisibilityCols,
    ReorderCols,
    OpenMassEditMode,
    CloseMassEditMode,
    MassEdit
}

export type AddButtonType = {
    buttonLabel: string;
    onClick: () => void;
};

export type ExportButtonType = {
    buttonLabel: string;
    onClick: () => void;
};

export type ImportButtonType = {
    buttonLabel: string;
    popupTitle: string;
    popupText: string;
    confirm: string;
    cancel: string;
    onClick: () => void;
};

export type SearchFeatureType = {
    inputPlaceholder: string;
    searchBy: {
        label: string;
        list: Array<{ id: string; label: string }>;
    };
    onSearch: (keyword: string, by: string) => void;
};

interface EnhancedTableToolbarProps {
    title?: string;
    numSelected: number;
    enableAddButton?: AddButtonType;
    enableExportButton?: ExportButtonType;
    enableImportButton?: ImportButtonType;
    onAction: (action: ToolbarAction) => void;
    enableChangeVisibilityColumn?: ChangeVisibilityColumnWordingsType;
    enableReordering?: ReorderingWordingsType;
    allowMassEdit?: MassEditOption;
    isMassEditActivated: boolean;
    isDownloading: boolean;
    isUploading: boolean;
    showHistory?: ShowHistoryOption[] | undefined;
    pageTitles?: string[];
}

export const DatatableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const location = useLocation();
    const [modalOpen, setModalOpen] = useState(false);

    // Check if the current location pathname includes "audit", used for the background of the history button
    const isAuditPage = location.pathname.includes('audit');

    // Apply different class based on the condition
    const elementClassName = isAuditPage ? `${classes.element} ${classes.auditPage}` : classes.element;

    const {
        enableAddButton, enableExportButton, isDownloading, enableImportButton, isUploading, numSelected, onAction, enableChangeVisibilityColumn, enableReordering, allowMassEdit, isMassEditActivated, showHistory, pageTitles = {
            showHistoryButton: false,
            urlToViewHistory: ''
        }
    } = props;
    const history = useHistory();

    const onActionHandler = (action: ToolbarAction) => () => {
        onAction(action);
        handleModalClose()
    };

    const handleHistoryClick = (urlToViewHistory: string) => {
        history.push(urlToViewHistory);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const buildModalActions = (enableImportButton: ImportButtonType) => {
        return [
            <Button key="modal-cancel" onClick={handleModalClose}>
                {enableImportButton.cancel}
            </Button>,
            <Button
                key="modal-validate"
                onClick={onActionHandler(ToolbarAction.Import)}
                variant="contained"
                color="primary"
                disableElevation
            >
                {enableImportButton.confirm}
            </Button>,
        ];
    };
    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {isMassEditActivated && allowMassEdit ? (
                <div className={classes.actionContainer}>
                    <Typography className={classes.actionContainerText} color="inherit" variant="subtitle1"
                                component="div">
                        {numSelected} selected
                    </Typography>
                    <UIButton
                        className={classes.actionContainerBehavior}
                        aria-label="close"
                        onClick={onActionHandler(ToolbarAction.CloseMassEditMode)}
                        size="small"
                        variant={undefined}
                        color="primary"
                        text={allowMassEdit.toolbar.action.closeMassEdit}
                    />
                    <UIButton
                        aria-label="massEdit"
                        onClick={onActionHandler(ToolbarAction.MassEdit)}
                        size="small"
                        variant={undefined}
                        color="primary"
                        disabled={numSelected <= 0}
                        text={allowMassEdit.toolbar.action.edit}
                    />
                </div>
            ) : (
                <div className={classes.actionContainer}>
                    {enableAddButton && !isMassEditActivated ? (
                        <UIButton
                            aria-label="add"
                            onClick={onActionHandler(ToolbarAction.Add)}
                            startIcon={<AddIcon/>}
                            size="small"
                            variant={undefined}
                            color="primary"
                            text={enableAddButton.buttonLabel}
                        />
                    ) : null}
                    {allowMassEdit && !isMassEditActivated ? (
                        <UIButton
                            aria-label="massEditMode"
                            onClick={onActionHandler(ToolbarAction.OpenMassEditMode)}
                            startIcon={<EditIcon/>}
                            size="small"
                            variant={undefined}
                            color="primary"
                            text={allowMassEdit.toolbar.action.massEdit}
                        />

                    ) : null}
                    {enableExportButton ? (
                        <UIButton
                            aria-label="add"
                            onClick={onActionHandler(ToolbarAction.Export)}
                            startIcon={
                                isDownloading ? (
                                    <CircularProgress size={16} />
                                ) : (
                                    <GetAppIcon />
                                )
                            }
                            size="small"
                            variant={undefined}
                            color="primary"
                            text={enableExportButton.buttonLabel}
                        />
                    ) : null}
                    {enableImportButton ? (
                        <>
                            <UIButton
                                aria-label="add"
                                /* onClick={onActionHandler(ToolbarAction.Import)}*/
                                onClick={handleModalOpen}
                                startIcon={
                                    isUploading ? (
                                        <CircularProgress size={16} />
                                    ) : (
                                        <GetAppIcon className={classes.rotatedIcon}/>
                                    )
                                }
                                size="small"
                                variant={undefined}
                                color="primary"
                                text={enableImportButton.buttonLabel}
                            />
                            <UIDialog
                                open={modalOpen}
                                onClose={handleModalClose}
                                title={enableImportButton.popupTitle}
                                actions={buildModalActions(enableImportButton)}
                            >
                                <DialogContentText>{enableImportButton.popupText}</DialogContentText>
                            </UIDialog>
                        </>
                    ) : null}
                </div>
            )
            }
            {props.showHistory?.map((historyOption, index) => {
                if (!historyOption.showHistoryButton) {
                    return null;
                }

                const iconButton = (
                    <IconButton
                        onClick={() => handleHistoryClick(historyOption.urlToViewHistory)}
                        className={`${classes.iconButton}`}
                    >
                        <Badge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <Avatar className={elementClassName}>
                                {historyOption.icon === 'SettingsBackupRestoreIcon' ? (
                                    <SettingsBackupRestoreIcon />
                                ) : (
                                    <HistoryIcon />
                                )}
                            </Avatar>
                        </Badge>
                    </IconButton>
                );

                const tooltipTitle = props.pageTitles ? props.pageTitles[index] + ` page` : '';

                return (
                    <Tooltip key={index} title={tooltipTitle}>
                        {historyOption.linkToParentPage ? iconButton : (
                            <IconButton
                                onClick={() => handleHistoryClick(historyOption.urlToViewHistory)}
                                className={`${classes.iconButton}`}
                            >
                                {historyOption.icon === 'SettingsBackupRestoreIcon' ? (
                                    <SettingsBackupRestoreIcon />
                                ) : (
                                    <HistoryIcon />
                                )}
                            </IconButton>
                        )}
                    </Tooltip>
                );
            })}
            {enableChangeVisibilityColumn ? (
                <Tooltip title={enableChangeVisibilityColumn.triggerButtonLabel}>
                    <IconButton className={classes.algoIcon}
                                onClick={onActionHandler(ToolbarAction.ChangeVisibilityCols)}>
                        <VisibilityOff/>
                    </IconButton>
                </Tooltip>
            ) : null}
            {enableReordering ? (
                <Tooltip title={enableReordering.triggerButtonLabel}>
                    <IconButton onClick={onActionHandler(ToolbarAction.ReorderCols)}>
                        <SwapHorizIcon/>
                    </IconButton>
                </Tooltip>
            ) : null}
        </Toolbar>
    );
};
