import { Tooltip, Popover } from '@material-ui/core';

// @ts-ignore
import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { grey } from '@material-ui/core/colors';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        toolbar: {
            background: grey[900],
            display: 'flex',
            justifyContent: 'flex-start',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar
        },
        contentToolbar: {
            background: grey[50],
            display: 'flex',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar
        },
        celineToolbar: {
            minHeight: '64px',
            maxHeight : '64px',
            textAlign: 'left',
        },
        celineBrand: {
            margin: 'auto',
            color: '#fff'
        },
        algoIcon: {
            height: '50px',
            margin: '8px 0 8px 0',
            float: 'left',
        },
        algoBrand: {
            color: '#FFF',
            display: 'inline-block',
            margin: '12px 0 0 5px',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: grey[900],
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 10,
        },
        primaryMenuItems: {
            flex: 1,
        },
        secondaryMenuItems: {
            backgroundColor: grey[900],
            color: '#FFF'
        },
        primaryIconItems: {
            color: grey[900],
        },
        secondaryIconItems:{
            color: '#FFF',
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            overflowX: 'hidden',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },

        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
    })
);

type ItemProps = {
    id: string;
    label: string;
    icon?: any;
    onSelect?: (arg0: string) => void;
    submenuItems?: ItemProps[];
};

interface MiniDrawerProps {
    content: React.ReactNode;
    headerTitle: string;
    menuItems: ItemProps[][];
    currentLocation?: string;
}

interface WithTooltipWhenShrinkedProps {
    children: any;
    hint: string;
}

export default function UIDynamicNavBar(props: MiniDrawerProps) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const REACT_APP_NAME = process.env.REACT_APP_NAME ?? '';
    const [openSubMenuIndex, setOpenSubMenuIndex] = React.useState<number|null>(null);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const WithTooltipWhenShrinked = (props: WithTooltipWhenShrinkedProps) =>
        !open ? (
            // there is a known warning bug for the Tooltip component combined with React in strict mode
            // @see https://github.com/mui-org/material-ui/issues/20625
            <Tooltip key={props.hint} title={props.hint} placement="left">
                {props.children}
            </Tooltip>
        ) : (
            props.children
        );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h5" noWrap className={classes.celineBrand}>
                        {props.headerTitle}
                    </Typography>
                </Toolbar>
            </AppBar>

            
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <div className={classes.celineToolbar}>
                        <img src="/logo-algo-white.png" alt={REACT_APP_NAME} className={classes.algoIcon}/>
                        <h1 className={classes.algoBrand}>{REACT_APP_NAME}
                            <IconButton onClick={handleDrawerClose} className={classes.secondaryIconItems}>
                                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </h1>
                    </div>
                </div>
                <Divider />
                {props.menuItems.map((itemGroup, index) => {
                    return (
                        <React.Fragment key={index}>
                            <List className={index === 0 ? classes.primaryMenuItems : classes.secondaryMenuItems}>
                                {itemGroup.map(({ id, label, icon, onSelect, submenuItems }) => {
                                    if(submenuItems){
                                        return (
                                            <WithTooltipWhenShrinked key={'ttip-' + id} hint={label}>
                                                <PopupState variant="popover" popupId="submenu-popover">
                                                    {(popupState) => (
                                                        <>
                                                            <ListItem button key={id}  {...bindTrigger(popupState)}>
                                                                <ListItemIcon
                                                                    className={index === 0 ? classes.primaryIconItems : classes.secondaryIconItems}>{icon}</ListItemIcon>
                                                                <ListItemText primary={label} />
                                                            </ListItem>
                                                            <Popover
                                                                id={String(index)}
                                                                {...bindPopover(popupState)}
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >
                                                                {submenuItems.map(({ id, label, icon, onSelect }) => {
                                                                        return (
                                                                            <WithTooltipWhenShrinked key={'ttip-' + id} hint={label}>
                                                                                <ListItem button key={id} onClick={() => {
                                                                                    setOpenSubMenuIndex(null)
                                                                                    if(onSelect)onSelect(id);
                                                                                }}
                                                                                >
                                                                                    {false && (<ListItemIcon
                                                                                        className={index === 0 ? classes.primaryIconItems : classes.secondaryIconItems}>{icon}</ListItemIcon>)}
                                                                                    <ListItemText primary={label}/>
                                                                                </ListItem>
                                                                            </WithTooltipWhenShrinked>
                                                                        )
                                                                    }
                                                                )}
                                                            </Popover>
                                                        </>
                                                    )}
                                                </PopupState>
                                            </WithTooltipWhenShrinked>
                                        )
                                    }
                                    return (
                                        <WithTooltipWhenShrinked key={'ttip-' + id} hint={label}>
                                            <ListItem button key={id} onClick={() => onSelect && onSelect(id)}>
                                                <ListItemIcon
                                                    className={index === 0 ? classes.primaryIconItems : classes.secondaryIconItems}>{icon}</ListItemIcon>
                                                <ListItemText primary={label}/>
                                            </ListItem>
                                        </WithTooltipWhenShrinked>
                                    )
                                })
                                }
                            </List>
                            {index < props.menuItems.length - 1 && <Divider />}
                        </React.Fragment>
                    )
                })
                }
            </Drawer>
            <main className={classes.content}>
                <div className={classes.contentToolbar} />
                {props.content}
            </main>
        </div>
    );
}
