import {ResponseNok} from '../../../adapters/io/network/HttpResponse';
import { HttpClientSpec } from '../../../core/network';

export class HttpClient implements HttpClientSpec {
    constructor(public readonly baseUrl: string) {}

    private buildUrl(path: string, params: Map<string, string> = new Map()) {
        let reducedParams = '?';
        params.forEach((value, key) => {
            reducedParams += `${key}=${value}&`;
        });
        return `${this.baseUrl}${path}${reducedParams.slice(0, -1)}`;
    }

    getData<T>(path: string, headers: any, params?: Map<string, string>) {
        return fetch(this.buildUrl(path, params), {method: 'GET', headers: headers}).then(async (result) => {
            const payload: T | ResponseNok | undefined = await result.json();

            if (result.ok) {
                return { data: payload as T };
            }

            throw new Error((payload as ResponseNok)?.errorMessages ?? result.statusText);
        });
    }
    getDataText<T>(path: string, headers: any, params?: Map<string, string>) {
        return fetch(this.buildUrl(path, params), { method: 'GET', headers: headers })
            .then(async (result) => {
                const text = await result.text();

                if (result.ok) {
                    // If you're expecting plain text, return the text directly.
                    return { data: text as unknown as T };
                }

                throw new Error(result.statusText);
            });
    }
    getDataCSV(path: string, headers: any, params?: Map<string, string>) {
        return fetch(this.buildUrl(path, params), { method: 'GET', headers: headers }).then(async (result) => {
            if (result.ok) {
                // Check if response content type is 'text/csv'
                const contentType = result.headers.get('content-type');
                if (contentType && contentType.includes('text/plain')) {
                    const data = await result.text(); // Get raw text data
                    return { data: data };
                } else {
                    // If response is not OK, handle error
                    const payload: ResponseNok | undefined = await result.json();
                    throw new Error((payload as ResponseNok)?.errorMessages ?? result.statusText);
                }
            } else {
                // If response is not OK, handle error
                const payload: ResponseNok | undefined = await result.json();
                throw new Error((payload as ResponseNok)?.errorMessages ?? result.statusText);
            }
        });
    }

    getDataExcel<T>(path: string, body?: T, headers?: any, params?: Map<string, string>): Promise<ArrayBuffer | Error> {
        return fetch(this.buildUrl(path, params), { method: 'POST', headers: headers, body: JSON.stringify(body) })
            .then(async (result) => {
                const contentType = result.headers.get('content-type');

                if (result.ok) {
                    if (contentType && contentType.includes('application/octet-stream')) {
                        return await result.arrayBuffer(); // Correctly handle binary data
                    } else {
                        throw new Error("Unexpected content type: " + contentType);
                    }
                } else {
                    if (contentType && contentType.includes('application/json')) {
                        const payload = await result.json();
                        throw new Error((payload as ResponseNok)?.errorMessages ?? result.statusText);
                    } else {
                        throw new Error(result.statusText);
                    }
                }
            })
            .catch(error => {
                return error as Error;
            });
    }

    async postDataCSV(path: string, headers: any, data: FormData) {
        return fetch(this.buildUrl(path), {
            method: 'POST',
            headers: {
                ...headers,
            },
            body: data,
        }).then(async (result) => {
            if (result.ok) {
                // Handle successful response
                return { success: true };
            } else {
                // If response is not OK, handle error
                const payload: ResponseNok | undefined = await result.json();
                throw new Error((payload as ResponseNok)?.errorMessages ?? result.statusText);
            }
        });
    }

    deleteData(path: string, headers?: any, params?: Map<string, string>) {
        return fetch(this.buildUrl(path, params), { method: 'DELETE', headers: headers }).then(async (result) => {
            if (result.ok) {
                return undefined;
            }

            const errorPayload: ResponseNok | undefined = await result.json();
            throw new Error(errorPayload?.errorMessages ?? result.statusText);
        });
    }

    postData<T, B>(path: string, body?: B, headers?: any, params?: Map<string, string>) {


        return fetch(this.buildUrl(path, params), { method: 'POST', headers: headers, body: JSON.stringify(body)}).then(
            async (result) => {
                if (result.ok) {
                    return result.status === 204 ? undefined : { data: (await result.json()) as T };
                }

                const errorPayload: ResponseNok | undefined = await result.json();
                throw new Error(errorPayload?.errorMessages ?? result.statusText);
            }
        );
    }

    putData<T, B>(path: string, body: B, headers?: any, params?: Map<string, string>) {
        return fetch(this.buildUrl(path, params), { method: 'PUT', headers: headers, body: JSON.stringify(body) }).then(
            async (result) => {
                if (result.ok) {
                    return result.status === 204 ? undefined : { data: (await result.json()) as T };
                }

                const errorPayload: ResponseNok | undefined = await result.json();
                throw new Error(errorPayload?.errorMessages ?? result.statusText);
            }
        );
    }
}
