export const formatText = (uri: string, ...params: unknown[]) => {
    let str = uri;
    if (params.length) {
        const argType = typeof params[0];
        const isPrimitiveType = 'string' === argType || 'number' === argType;
        const kvPair = (isPrimitiveType ? Array.prototype.slice.call(params) : params[0]) as Iterable<string>;

        for (const key in kvPair) {
            // @ts-ignore
            str = str.replace(new RegExp(`\\{${key}\\}`, 'gi'), kvPair[key]);
        }
    }

    return str;
};
