import React from 'react';
import { Typography } from '@material-ui/core';

export const LeadtimeMatrixPage = () => (
    <>
        <Typography variant="h3" component="h2">
            Leadtime matrix
        </Typography>
    </>
);
