import {ApiDatasource} from '../../adapters/io/datasources/ApiDatasource';
import {unwrap} from '../../adapters/io/network/HttpResponse';
import {formatText} from '../../adapters/io/network/utils';
import {ApiDatasourceSpec} from '../../core/datasource';
import {AuthState} from '@okta/okta-auth-js';
import {buildHeader} from './utils/Utils';
import {PromiseDateMarginTableStructure} from "../indexes/PromiseDateMarginIndexes";
import {Order} from "../../../../graphics/tables/operations/sort";

export const searchPromiseDateMargin = <T>(
    authState: AuthState,
    search: PromiseDateMarginTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<PromiseDateMarginTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches: search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<PromiseDateMarginTableStructure.ResponseOf<T>, PromiseDateMarginTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointPromiseDateMargin.endPoint.concat(EndpointPromiseDateMargin.searchPromiseDateMargin), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};
export const getPromiseDateMarginColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<PromiseDateMarginTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<PromiseDateMarginTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointPromiseDateMargin.endPoint.concat(EndpointPromiseDateMargin.getPromiseDateMarginColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const upsertPromiseDateMargin = <RecordType>(
    authState: AuthState,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .postData<void, RecordType>(uriFormatter(EndpointPromiseDateMargin.endPoint.concat(EndpointPromiseDateMargin.upsertPromiseDateMargin), {}), record, buildHeader(authState), new Map())
        .then(() => {});
};

export const deletePromiseDateMargin = (
    authState: AuthState,
    id: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
) : Promise<void> => {

    return datasource.remote
        .deleteData(uriFormatter(EndpointPromiseDateMargin.endPoint.concat(EndpointPromiseDateMargin.deletePromiseDateMargin), {}) + `?id=${id}`, buildHeader(authState), new Map())
        .then(() => {});
};

export const EndpointPromiseDateMargin = {
    endPoint: '/end-point/promise-date-margin',
    getPromiseDateMargins: '',
    searchPromiseDateMargin: '/search',
    getPromiseDateMarginColumnInformation: '/column-information',
    upsertPromiseDateMargin: '/upsert',
    deletePromiseDateMargin:''
};