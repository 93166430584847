import { ApiDatasource } from '../../adapters/io/datasources/ApiDatasource';
import { unwrap } from '../../adapters/io/network/HttpResponse';
import { formatText } from '../../adapters/io/network/utils';
import { ApiDatasourceSpec } from '../../core/datasource';
import { StoreTableStructure } from '../indexes/StoreIndexes';
import { AuthState } from '@okta/okta-auth-js';
import { buildHeader } from './utils/Utils';
import { Order } from "../../../../graphics/tables/operations/sort";

export const getStores = <T>(
    authState : AuthState,
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StoreTableStructure.ResponseOf<T>> => {
    const params = new Map();

    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .getData<StoreTableStructure.ResponseOf<T>>(
            uriFormatter(EndpointStore.endPoint.concat(EndpointStore.getStores), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const searchStores = <T>(
    authState : AuthState,
    search: StoreTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StoreTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<StoreTableStructure.ResponseOf<T>, StoreTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointStore.endPoint.concat(EndpointStore.searchStores), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const addStoreRecord = <RecordType>(
    authState : AuthState,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .postData<void, RecordType>(uriFormatter(EndpointStore.endPoint.concat(EndpointStore.addStore), {}), record, buildHeader(authState), new Map())
        .then(() => {});
};

export const editStoreRecord = <RecordType>(
    authState : AuthState,
    storeId: string,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => 
    datasource.remote
        .putData<void, RecordType>(uriFormatter(EndpointStore.endPoint.concat(EndpointStore.editStore), {}) + `?storeId=${storeId}`, record, buildHeader(authState), new Map())
        .then(() => {});


export const removeStoreRecord = (
    authState : AuthState,
    storeId: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
) => datasource.remote.deleteData(uriFormatter(EndpointStore.endPoint.concat(EndpointStore.removeStore), {}) + `?storeId=${storeId}`, buildHeader(authState), new Map());


export const getStoreColumnInformation = <T>(
    authState : AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StoreTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<StoreTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointStore.endPoint.concat(EndpointStore.getStoreColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const EndpointStore = {
    endPoint: '/end-point',
    getStores: '/store',
    searchStores: '/store/search',
    addStore: '/store',
    editStore: '/store',
    removeStore : '/store',
    getStoreColumnInformation : '/store/column-information'
};