import { Order } from "../../../../graphics/tables/operations/sort";
import { ApiDatasource } from "../../adapters/io/datasources/ApiDatasource";
import { unwrap } from "../../adapters/io/network/HttpResponse";
import { formatText } from "../../adapters/io/network/utils";
import { ApiDatasourceSpec } from "../../core/datasource";
import { AuthState } from '@okta/okta-auth-js';
import { buildHeader } from './utils/Utils';
import {StockLocationsAuditTableStructure} from "../indexes/StockLocationsAuditIndexes";

export const searchStockLocationsAudit = <T>(
    authState: AuthState,
    search: StockLocationsAuditTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StockLocationsAuditTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<StockLocationsAuditTableStructure.ResponseOf<T>, StockLocationsAuditTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointStockLocationsAudit.endPoint.concat(EndpointStockLocationsAudit.searchStockLocationsAudit), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const getStockLocationsAuditColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StockLocationsAuditTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<StockLocationsAuditTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointStockLocationsAudit.endPoint.concat(EndpointStockLocationsAudit.getStockLocationsAuditColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const EndpointStockLocationsAudit = {
    endPoint: '/end-point/stock-location',
    searchStockLocationsAudit: '/audit/search',
    getStockLocationsAuditColumnInformation: '/audit'
};