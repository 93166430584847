import {ApiDatasource} from '../../adapters/io/datasources/ApiDatasource';
import {unwrap} from '../../adapters/io/network/HttpResponse';
import {formatText} from '../../adapters/io/network/utils';
import {ApiDatasourceSpec} from '../../core/datasource';
import {AuthState} from '@okta/okta-auth-js';
import {buildHeader} from './utils/Utils';
import {TransferDelayTableStructure} from "../indexes/TransferDelayIndexes";
import {Order} from "../../../../graphics/tables/operations/sort";

export const getTranferDelayRecords = <T>(
    authState: AuthState,
    page?: number,
    limit?: number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<TransferDelayTableStructure.ResponseOf<T>> => {

    const params = new Map();
    if (page) {
        params.set('page', page);
        params.set('limit', limit);
    }

    return datasource.remote
        .getData<TransferDelayTableStructure.ResponseOf<T>>(uriFormatter(EndpointTransferDelay.endPoint.concat(EndpointTransferDelay.getTransferDelays), {}), buildHeader(authState), params)
        .then(unwrap);
};

export const searchTransferDelay = <T>(
    authState: AuthState,
    search: TransferDelayTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<TransferDelayTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches: search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<TransferDelayTableStructure.ResponseOf<T>, TransferDelayTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointTransferDelay.endPoint.concat(EndpointTransferDelay.searchTransferDelay), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};
export const getTransferDelayColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<TransferDelayTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<TransferDelayTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointTransferDelay.endPoint.concat(EndpointTransferDelay.getTransferDelayColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const upsertTransferDelay = <RecordType>(
    authState: AuthState,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .postData<void, RecordType>(uriFormatter(EndpointTransferDelay.endPoint.concat(EndpointTransferDelay.upsertTransferDelay), {}), record, buildHeader(authState), new Map())
        .then(() => {});
};

export const deleteTransferDelay = (
    authState: AuthState,
    id: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
) : Promise<void> => {

    return datasource.remote
        .deleteData(uriFormatter(EndpointTransferDelay.endPoint.concat(EndpointTransferDelay.deleteTransferDelay), {}) + `?id=${id}`, buildHeader(authState), new Map())
        .then(() => {});
};

export const EndpointTransferDelay = {
    endPoint: '/end-point/transfer-delay',
    getTransferDelays: '',
    searchTransferDelay: '/search',
    getTransferDelayColumnInformation: '/column-information',
    upsertTransferDelay: '/upsert',
    deleteTransferDelay:''
};