import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Page } from '../../presenters/pages/index';
import { strings } from '../../i18n/strings';
import { ThresholdTableStructure } from '../../domain/indexes/ThresholdIndexes';
import { searchThresholds } from '../../domain/repository/ThresholdRepository';
import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import { SkuThresholdTable } from '../tables/SkuThresholdTable';
import { GncThresholdTable } from "../tables/GncThresholdTable";
import { SubActivityThresholdTable } from "../tables/SubActivityThresholdTable";
import { StockPoolThresholdTable } from "../tables/StockPoolThresholdTable";
import { useOktaAuth } from '../../../../okta-react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',

            alignItems: 'center',
            '& > *': {
                marginBottom: '-0.5em'
            }
        }, 
        globalThreshold: {
            textAlign: 'left',
            marginBottom: '2em'
        },
        panel: {
            width: '100%'
        }
    })
);

type ThresholdBar = ThresholdTableStructure.ThresholdBar;
const thresholdBar: ThresholdBar = {
    'barItems': ["Stock Pool Threshold", "Sub-Activity Threshold", "GNC Threshold", "SKU Threshold"]
}

type TableThreshold = ThresholdTableStructure.Threshold;
type RecordThreshold = ThresholdTableStructure.RecordOf<TableThreshold>;

const wordingThresholdPage = strings.page.threshold;

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    className: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, className, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            className={className}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other} >

        {value === index && (
            <Box p={3}>
                <Typography>{children}</Typography>
            </Box>
        )}
    
      </div>
    );
}

function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const ThresholdPage = () => {

    const classes = useStyles();
    const { authState } = useOktaAuth();
    const [activeTab, setActiveTab] = React.useState<number>(0);
    const [globalThresholdValue, setGlobalThresholdValue] = useState<string | number>("");

    //Getting Global Threshold
    useEffect(() => {
        (async () => {
            try {
                const globalThresholdResponse: ThresholdTableStructure.ResponseOf<TableThreshold> = await searchThresholds<RecordThreshold>(authState!, [{
                    key: "thresholdLevelCode",
                    value: "GLOBAL"
                }], 1, 1);
                for (let i = 0; i < globalThresholdResponse.values.length; i++) {
                    const item = globalThresholdResponse.values[i];
                    if (item.thresholdLevelCode === "GLOBAL") {
                        return setGlobalThresholdValue(item.thresholdValue);
                    }
                }
            } catch (error: any) {
                console.log("ERROR: ", error);
            }
        })();
    }, [authState]);

    const handleTabChange = (event: React.ChangeEvent<{}>, indexTab: number) => {
        setActiveTab(indexTab);
    };

    return (
        <Page 
            title={wordingThresholdPage.title} 
            descriptionLine1={wordingThresholdPage.description.line1} 
            descriptionLine2={wordingThresholdPage.description.line2}>

            {globalThresholdValue && (
                <div className={classes.globalThreshold}>
                    {`${wordingThresholdPage.globalThresholdTitle} ${globalThresholdValue}`}
                </div>
            )}

            <div className={classes.root}>
                <AppBar position="static">
                    <Tabs value={activeTab} onChange={handleTabChange} aria-label="simple tabs example" variant="fullWidth">
                        {thresholdBar.barItems.map((item: string, idx:number) => {
                            return <Tab label={item} key={idx} {...a11yProps(idx)}/>
                        })}
                    </Tabs>
                </AppBar>
                <TabPanel value={activeTab} index={0}  className={classes.panel}>
                    <StockPoolThresholdTable />
                </TabPanel>
                <TabPanel value={activeTab} index={1}  className={classes.panel}>
                    <SubActivityThresholdTable />
                </TabPanel>
                <TabPanel value={activeTab} index={2}  className={classes.panel}>
                    <GncThresholdTable />
                </TabPanel>
                <TabPanel value={activeTab} index={3} className={classes.panel}>
                    <SkuThresholdTable />
                </TabPanel>
            </div>   
        </Page>  
    )
};
