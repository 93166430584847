import React, { useContext, useEffect, useState } from 'react';
import { Page } from '../../presenters/pages/index';
import { AppContext, AppContextSpec, useAsyncEffect } from '../../App';
import { strings } from '../../i18n/strings'
import { DynamicDatatable, EditOptionType } from '../../../../graphics/tables/DynamicDatatable';
import { StockLocationTableStructure } from '../../domain/indexes/StockLocationIndexes';
import {
    getStockLocationColumnInformation,
    searchStockLocation,
    editStockLocationRecord,
    massEditStockLocation } from '../../domain/repository/StockLocationRepository';
import { StockLocationEditForm, EditFormModalOptions } from '../../domain/forms/StockLocationEditForm';
import { HeadCell } from '../../../../graphics/tables/data/HeadCell';
import { TableRowData } from '../../../../graphics/tables/data/TableRowData';
import { Order } from "../../../../graphics/tables/operations/sort";
import { useOktaAuth } from '../../../../okta-react';
import { massEditStockLocationSelectionByCriteria } from "../../domain/repository/TechnicalRepository";

type TableStockLocation = StockLocationTableStructure.StockLocation;
type Record = StockLocationTableStructure.RecordOf<TableStockLocation>;

const DEFAULT_PAGINATION_LIMIT = 20;

const tableFields: Array<keyof Omit<Record, 'id'> & string> = [
    'code',
    'name',
    'status',
    'type',
    'latitude',
    'longitude',
    'addressLine1',
    'addressLine2',
    'city',
    'postalCode',
    'regionState',
    'countryCode',
    'openHoursMonday',
    'openHoursTuesday',
    'openHoursWednesday',
    'openHoursThursday',
    'openHoursFriday',
    'openHoursSaturday',
    'openHoursSunday',
    'cutOffCustomerOrder',
    'cutOffTransfer',
    'numberOfDaysBeforeCutOff',
    'numberOfDaysAfterCutOff',
    'numberOfDaysBeforeCutOffTransfer',
    'numberOfDaysAfterCutOffTransfer',
    'numberOfDaysBeforeCutOffClickAndCollect',
    'numberOfDaysAfterCutOffClickAndCollect',
    'daysOffWeek',
    'daysOffYear',
    'creationDate',
    'creationUser',
    'lastUpdateDate',
    'lastUpdateUser',
    'dangerousGoodCompliant',
    'hierarchyRegion',
    'reopenDate'
];

const wordingsDatatable = strings.datatable;
const wordingStockLocationPage = strings.page.stocklocation;
const wordingStockLocationsAuditPage = strings.page.stocklocationAudit;

interface RecordRepresentation extends Record, TableRowData { };
interface RecordData { record: RecordRepresentation, type: EditMode }

enum EditMode {
    Edit
}

export const StockLocationPage = () => {

    const { authState } = useOktaAuth();
    const [rowData, setRowData] = useState<RecordRepresentation[]>([]);
    const [tableData, setTableData] = useState<StockLocationTableStructure.ResponseOf<TableStockLocation>>();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(DEFAULT_PAGINATION_LIMIT);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>(tableFields[0]);
    const [updateSignal, setUpdateSignal] = useState(false);
    const [recordToEdit, setRecordToEdit] = useState<RecordData>();
    const [editModalOpened, setEditModalOpened] = useState(false);
    const [headCellsConfig, setHeadCellConfig] = useState<HeadCell<RecordRepresentation>[]>([]);
    const appContext = useContext<AppContextSpec>(AppContext);
    const [tableDataDefinition, setTableDataDefinition] = useState<StockLocationTableStructure.ResponseOfColumn<StockLocationTableStructure.StockLocation>>();

    const currentPage = wordingStockLocationsAuditPage.title;

    const headCells: HeadCell<RecordRepresentation>[] = tableFields.map((field) => ({
        id: field,
        label: field,
        displayName: field,
        type: field,
        required: true,
        information: field,
        position: 1,
        visible: true,
        editable: true,
        massEditable: false,
        reference: field,
        referenceColumnLabel: field,
        disablePadding: false,
        searchValue: "",
    }));

    useEffect(() => {
        (async () => {
            try {
                const headCellConfigValues: StockLocationTableStructure.ResponseOfColumn<StockLocationTableStructure.StockLocation> =
                await getStockLocationColumnInformation<StockLocationTableStructure.StockLocation>(authState!);

                headCells.forEach((cell) => {
                    const config = headCellConfigValues[cell.id];
                    cell.id = config.name;
                    cell.label = config.name;
                    cell.type = config.type;
                    cell.required = config.required;
                    cell.information = config.information;
                    cell.position = config.position;
                    cell.visible = config.visible;
                    cell.editable = config.editable;
                    cell.massEditable = config.massEditable;
                    cell.reference = config.reference;
                    cell.displayName = (config.displayName.length > 0) ? String(config.displayName) : String(config.name);
                });

                setHeadCellConfig(headCells);
                setTableDataDefinition(headCellConfigValues);
            } catch (error: any) {
                console.log("ERROR: ", error);
            }
        })();
    // eslint-disable-next-line
    }, []);

    const getAndRefreshRecords = (newList ?: HeadCell<RecordRepresentation>[]) => {

        appContext.displayLoader(true);

        let headCellsConfigForGetAndRefresh = (newList) ? newList : headCellsConfig;

        let formattedSearchValues: StockLocationTableStructure.FormattedSearchValue[] = headCellsConfigForGetAndRefresh
        .filter(cell => cell.label && cell.searchValue)
        .map(cell  => {
            return {
                key: cell.label,
                value : cell.searchValue
            } ;

        });

        const updateState = (stockLocationValues: StockLocationTableStructure.ResponseOf<StockLocationTableStructure.StockLocation>) => {
            setTableData(stockLocationValues);
            setRowData(stockLocationValues.values);

            if (page !== stockLocationValues.pagination.page) {
                setPage(stockLocationValues.pagination.page);
            }
        }

         (async () => {
            try {
                const stockLocationValues: StockLocationTableStructure.ResponseOf<StockLocationTableStructure.StockLocation> =
                    await searchStockLocation<Record>(authState!, formattedSearchValues, page, limit, order, orderBy);

                if (page !== stockLocationValues.pagination.page) {
                    setPage(stockLocationValues.pagination.page);
                }

                updateState(stockLocationValues);
            } catch (error: any) {
                console.log("ERROR: ", error);
            }
        })();

        appContext.displayLoader(false);
    };

    useAsyncEffect(async () => {
            await getAndRefreshRecords();
        }, [page, limit, order, orderBy, updateSignal]
    );

    const handlePaginationChange = (page: number, limit: number) => {
        setPage(page);
        setLimit(limit);
    };

    const handleSortChange = async (order: Order, orderBy: string) => {
        setOrder(order);
        setOrderBy(orderBy);
    };

    const handleMultiCellsSearch = ( newList: HeadCell<RecordRepresentation>[]) => {
        setPage(1);
        headCellsConfig.forEach((cell : HeadCell<RecordRepresentation>) => {
            cell.searchValue = newList.filter(newListCell => newListCell.id === cell.id)[0].searchValue;
        });

        getAndRefreshRecords(headCellsConfig);
    }

    const onChangeVisibilityColumnDialog = (newList: HeadCell<RecordRepresentation>[]) => {
        const newHeadCells = newList.slice();
        setHeadCellConfig(newHeadCells);
    }

    const onReorderDialogueAction = (newList: HeadCell<RecordRepresentation>[]) => {
        const newHeadCells = newList.slice();
        setHeadCellConfig(newHeadCells);
    }

    /**
     * Row edition 
     */
     const handleEditFormSubmit = async (form: Record) => {
        const feedbackWordings = recordToEdit ? (recordToEdit.type === EditMode.Edit ? wordingStockLocationPage.datatable.action.edit : wordingStockLocationPage.datatable.action.duplicate) : wordingStockLocationPage.datatable.action.add;
        await (recordToEdit
            ? (recordToEdit.type === EditMode.Edit ? editStockLocationRecord(authState!, recordToEdit.record.id, form) : '') : '');

        return feedbackWordings.confirmationFeedbackMessage;
    };

    const fillAndOpenEditForm = (record?: RecordData) => {
        setRecordToEdit(record);
        setEditModalOpened(true);
    };

    /**
     * Computed options
     */    
     const handleEditOptionClick = (option: EditOptionType, record: RecordRepresentation) => {
        switch (option) {
            case EditOptionType.Edit:
                fillAndOpenEditForm({ record: record, type: EditMode.Edit });
                break;
        }
    };

    const computeEditOptions = () => {
        return [
                { id: EditOptionType.Edit, name: wordingStockLocationPage.datatable.action.edit.menuItemLabel, onClick: handleEditOptionClick }
            ];
    };

    /**
     * Modal row add
     */
     const handleEditModalClose = () => setEditModalOpened(false);

    const handleEditModalOk = (shouldReset: boolean) => {
        const feedbackWordings = recordToEdit ? (recordToEdit.type === EditMode.Edit ? wordingStockLocationPage.datatable.action.edit : wordingStockLocationPage.datatable.action.duplicate) : wordingStockLocationPage.datatable.action.add;
        !shouldReset && setEditModalOpened(false);

        (async () => {
            try {
                getAndRefreshRecords();
                appContext.notifySuccess(feedbackWordings.confirmationFeedbackMessage);
            } catch (error: any) {
                appContext.notifyError("ERROR : Something wrong happen while trying to edit Stock Location")
            }
        })();
    };

     const computeEditModalOptions = (): EditFormModalOptions => {

        const modalWordings = wordingStockLocationPage.datatable.action.edit.modal;

        return {
            title: modalWordings.title,
            applyLabel: modalWordings.applyButtonLabel,
            cancelLabel: modalWordings.cancelButtonLabel,
            applyAndResetLabel: undefined,
            open: editModalOpened,
            onClose: handleEditModalClose,
            onSuccess: async (shouldReset: boolean) => {
                await handleEditModalOk(shouldReset);
            }
        };
    };

    /**
     * Mass edit
     */
    const notifyForUpdateData= () => {
        setUpdateSignal(!updateSignal);
    }

    const handleMassEdit = async (selectedIds: string[], fieldToEdit: string, newValueToApply: string, newValuesToApply: string[]) => {
        try  {
            await massEditStockLocation(authState!, {selectedIds, fieldToEdit, newValueToApply, newValuesToApply});
            notifyForUpdateData();
        } catch (error: any) {
            appContext.notifyError(error.message);
        }

    }

    const handleSelectAllClick =  async () => {

        let formattedSearchValues: StockLocationTableStructure.FormattedSearchValue[] = headCellsConfig
            .filter(cell => cell.label && cell.searchValue)
            .map(cell => {
                return {
                    key: cell.label,
                    value: cell.searchValue
                };
            });

        // Search by default all threshold by sku
        let selectedRows:number[] = [];

        try {
            selectedRows =  await massEditStockLocationSelectionByCriteria(authState!, formattedSearchValues, order, orderBy);
            return selectedRows;
        } catch (error: any) {
            appContext.notifyError("ERROR : Something wrong happen while trying to get all row selection");
        }
    }

    return (
        <Page title={wordingStockLocationPage.title} descriptionLine1={wordingStockLocationPage.description.line1} descriptionLine2={wordingStockLocationPage.description.line2}>
            {(
                <>
                    <DynamicDatatable
                        stickyHeader={true}
                        headCells={headCellsConfig}
                        data={rowData}
                        initialOrderBy={tableFields[0]}
                        showPadding={false}
                        editOptions={computeEditOptions()}
                        onChangeVisibilityColumnDialog={onChangeVisibilityColumnDialog}
                        onReorderDialogueAction={onReorderDialogueAction}
                        allowReordering={{    modal: {
                            title: wordingsDatatable.reorderColumns.modal.title,
                            applyButtonLabel: wordingsDatatable.reorderColumns.modal.applyButtonLabel,
                        },
                        triggerButtonLabel: wordingsDatatable.reorderColumns.modal.title}}
                        allowChangeVisibilityColumn={{    modal: {
                            title: wordingsDatatable.changeVisibilityColumns.modal.title,
                            applyButtonLabel: wordingsDatatable.changeVisibilityColumns.modal.applyButtonLabel,
                        },
                        triggerButtonLabel: wordingsDatatable.changeVisibilityColumns.modal.title}}
                        paginationOptions={(() => ({
                            page,
                            limit,
                            order,
                            orderBy,
                            total: tableData?.pagination?.total || 0,
                            rowsPerPageValues: [
                                { nb: DEFAULT_PAGINATION_LIMIT / 2, isDefault: true },
                                { nb: DEFAULT_PAGINATION_LIMIT, isDefault: true },
                                { nb: DEFAULT_PAGINATION_LIMIT * 2, isDefault: false },
                                { nb: DEFAULT_PAGINATION_LIMIT * 4, isDefault: false },
                            ],
                            meetNbRowsPerPage: false,
                            rowsPerPageLabel: wordingsDatatable.pagination.rowsPerPage,
                            onPaginationChange: handlePaginationChange,
                            onHandleSortChange: handleSortChange,
                            onMultiCellsSearch: handleMultiCellsSearch
                        }))()}
                        massEditOptions={{
                            onMassEdit: handleMassEdit,
                            onSelectAllClick: handleSelectAllClick,
                            toolbar: {
                                action: {
                                    massEdit: wordingsDatatable.massEdit.toolbar.action.massEdit,
                                    edit: wordingsDatatable.massEdit.toolbar.action.edit,
                                    closeMassEdit: wordingsDatatable.massEdit.toolbar.action.closeMassEdit
                                }
                            },
                            dialog: {
                                title: wordingsDatatable.massEdit.dialog.title,
                                warningMessage: wordingsDatatable.massEdit.dialog.warningMessage,
                                confirmMessage: wordingsDatatable.massEdit.dialog.confirmMessage,
                                cancelButtonLabel: wordingsDatatable.massEdit.dialog.cancelButtonLabel,
                                applyButtonLabel: wordingsDatatable.massEdit.dialog.applyButtonLabel,
                                cancelConfirmButtonLabel: wordingsDatatable.massEdit.dialog.cancelConfirmButtonLabel,
                                applyConfirmButtonLabel: wordingsDatatable.massEdit.dialog.applyConfirmButtonLabel
                            },
                            form: {
                                field: {
                                    label: {
                                        selectProperty: wordingsDatatable.massEdit.form.field.label.selectProperty
                                    }
                                }
                            }
                        }}
                        showHistory={[{
                            showHistoryButton: true,
                            urlToViewHistory: '/stock-locations-audit',
                            linkToParentPage: false,
                            icon: 'HistoryIcon'
                        }]}
                        pageTitles={[currentPage]}
                    />
                </>
            ) }
            {tableDataDefinition && (
                <StockLocationEditForm
                    key={EditMode.Edit}
                    record={recordToEdit ? recordToEdit.record : undefined}
                    constraints={tableDataDefinition}
                    onSubmit={handleEditFormSubmit}
                    modalOptions={computeEditModalOptions()}
                />
            )}
        </Page>
    );

};
